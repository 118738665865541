import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { QueryRef } from 'apollo-angular';
import { BaseComponent } from 'src/app/base/base.component';
import { ReportService } from 'src/app/service/report.service';
import { GmrAdmin, GmrFindAllGQL, GmrFindAllQuery, GmrFindAllQueryVariables, SearchUserSaleTeamGQL, SearchUserSaleTeamQuery, SearchUserSaleTeamQueryVariables, UserResetPasswordGQL, UserSalesTeamInput, UserSaleTeamPage } from 'src/generated/graphql';

@Component({
  selector: 'app-user-sales-team',
  templateUrl: './user-sales-team.component.html',
  styleUrls: ['./user-sales-team.component.scss']
})
export class UserSalesTeamComponent extends BaseComponent implements OnInit {

  currentPage = 1;
  filter: UserSalesTeamInput = new UserSalesTeamInput();
  modelGrm: GmrAdmin[] = [];
  modelUserSalesTeam: UserSaleTeamPage = new UserSaleTeamPage();

  constructor(
    public router: Router,
    public translate: TranslateService,
    private findAllGmrGQL: GmrFindAllGQL,
    private userResetPasswordGQL: UserResetPasswordGQL,
    private searchUserSaleTeamGQL: SearchUserSaleTeamGQL,
    private reportService: ReportService,
  ) { 
    super(router, translate)
  }

  grmQuery: QueryRef<GmrFindAllQuery, GmrFindAllQueryVariables>;
  searchQuery: QueryRef<
  SearchUserSaleTeamQuery,
  SearchUserSaleTeamQueryVariables
>;
  
  ngOnInit() {
    this.findAllGmr();
    this.search();
  }

  getExcel() {
    this.toggleLoader(true);
    this.reportService.getSaleTeamExcel(this.filter).subscribe({
      next: (data) => {
        this.toggleLoader(false);
        this.downloadHandlerFile(data,'Relatório_Equipe_Comercial');
      },
      error: (err) => {
        super.onError(err);
        this.toggleLoader(false);
      },
    });
  }

  private findAllGmr(): void {
    this.grmQuery = this.findAllGmrGQL.watch();
    this.grmQuery.valueChanges.subscribe({
      next: ({ data }) => {
        this.modelGrm = data.gmrFindAll as GmrAdmin[];
      },
    });
  }

  searchUsersSalesTeam(): void {
    this.search();
  }

  onResetPasswordUser(i: string): void {
    this.confirmMessage('Aviso!', 'Tem certeza que deseja resetar a senha?', () => {
      this.userResetPasswordGQL.mutate({id: i}).subscribe({
        next: ({data}) => {
          this.showMessage('Sucesso', 'Senha resetada com sucesso!', 'success');
        }
      });
    });
  }

  pageChanged(event:any): void {
    this.currentPage = event;
    this.search();
  } 

  private search(): void {
    this.filter.page = this.currentPage;
    this.searchQuery = this.searchUserSaleTeamGQL.watch({
      input: this.filter,
    });
    this.searchQuery.valueChanges.subscribe({
      next: ({ data }) => {
        this.modelUserSalesTeam = data.userSalesTeam as UserSaleTeamPage;
      },
    });
  }

}
