import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../service/user.service';
import {environment} from "../../../../environments/environment";
import { GmrAdmin, GmrFindAllGQL, GmrFindAllQuery, GmrFindAllQueryVariables, ReportVideoPage, SearchReportVideoGQL, SearchReportVideoQuery, SearchReportVideoQueryVariables, TechnicalVisitFilterAdminInput } from 'src/generated/graphql';
import { QueryRef } from 'apollo-angular';
import { PassThrough } from 'stream';
import { ReportService } from 'src/app/service/report.service';

@Component({
  selector: 'app-report-training',
  templateUrl: './report-training.component.html',
  styleUrls: ['./report-training.component.scss']
})
export class ReportTrainingComponent extends BaseComponent implements OnInit {

  filter: TechnicalVisitFilterAdminInput = new TechnicalVisitFilterAdminInput();
  modelGrm: Array<GmrAdmin> = [];
  modelResult: ReportVideoPage = new ReportVideoPage();
 
  currentPage = 1;

  constructor(public router: Router,
              public translate: TranslateService,
              private searchReportVideoGQL: SearchReportVideoGQL,
              private reportService: ReportService,
              private findAllGmrGQL: GmrFindAllGQL,
              
              ) {
    super(router, translate);
  }

  searchQuery: QueryRef<
  SearchReportVideoQuery,
  SearchReportVideoQueryVariables
>;
grmQuery: QueryRef<GmrFindAllQuery, GmrFindAllQueryVariables>;

  ngOnInit(): void {
    this.findAllGmr();
    this.actionSearch()
  }


  pageChanged(event) {
    this.currentPage = event;
    this.actionSearch();
  }

  actionSearch(): void {
     this.filter.page = this.currentPage;
    this.searchQuery = this.searchReportVideoGQL.watch({
      input: this.filter,
    });
    this.searchQuery.valueChanges.subscribe({
      next: ({data}) => {
        this.modelResult = data.searchReportVideo as ReportVideoPage
      }
    })
  }

  getExcel(): void {
    this.toggleLoader(true);
    this.reportService.reportVideoExcel(this.filter).subscribe({
      next: (data) => {
        this.toggleLoader(false);
        this.downloadHandlerFile(data,'Relatório_Videos');
      },
      error: (err) => {
        super.onError(err);
        this.toggleLoader(false);
      },
    });
  }

  private findAllGmr(): void {
    this.grmQuery = this.findAllGmrGQL.watch();
    this.grmQuery.valueChanges.subscribe({
      next: ({ data }) => {
        this.modelGrm = data.gmrFindAll as GmrAdmin[];
      },
    });
  }

}
