import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {UserService} from "../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {
    AdminSearchTechinicalVisitStatusGQL,
    AdminSearchTechinicalVisitStatusQuery,
    AdminSearchTechinicalVisitStatusQueryVariables,
    AdminSearchTechnicalVisitGQL,
    AdminSearchTechnicalVisitQuery,
    AdminSearchTechnicalVisitQueryVariables,
    AdminTechinicalVisitDetailsGQL,
    AdminTechnicalVisitSummaryGQL,
    AdminTechnicalVisitSummaryQuery,
    AdminTechnicalVisitSummaryQueryVariables,
    AdminTechnicalVisitUpdateStatusGQL,
    GmrAdmin,
    GmrFindAllGQL,
    GmrFindAllQuery,
    GmrFindAllQueryVariables,
    TechnicalVisitAdminPage,
    TechnicalVisitFilterAdminInput,
    TechnicalVisitStatusAdmin,
    TechnicalVisitSummary
} from 'src/generated/graphql';
import {QueryRef} from 'apollo-angular';
import {ReportService} from 'src/app/service/report.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-techinical-visit',
    templateUrl: './techinical-visit.component.html',
    styleUrls: ['./techinical-visit.component.scss']
})
export class TechinicalVisitComponent extends BaseComponent implements OnInit {

    summary: TechnicalVisitSummary = new TechnicalVisitSummary();
    modelStatus: Array<TechnicalVisitStatusAdmin> = [];
    modelGrm: Array<GmrAdmin> = [];
    modelTechnicalVisits: TechnicalVisitAdminPage = new TechnicalVisitAdminPage();
    filter: TechnicalVisitFilterAdminInput = new TechnicalVisitFilterAdminInput();

    currentPage = 1;

    searchSummaryQuery: QueryRef<AdminTechnicalVisitSummaryQuery, AdminTechnicalVisitSummaryQueryVariables>;
    searchStatusQuery: QueryRef<AdminSearchTechinicalVisitStatusQuery, AdminSearchTechinicalVisitStatusQueryVariables>;
    grmQuery: QueryRef<GmrFindAllQuery, GmrFindAllQueryVariables>;
    technicalVisitQuery: QueryRef<AdminSearchTechnicalVisitQuery, AdminSearchTechnicalVisitQueryVariables>;


    constructor(public router: Router,
                public userService: UserService,
                public translate: TranslateService,
                private adminTechnicalVisitSummaryGQL: AdminTechnicalVisitSummaryGQL,
                private adminSearchTechinicalVisitStatusGQL: AdminSearchTechinicalVisitStatusGQL,
                private findAllGmrGQL: GmrFindAllGQL,
                private adminSearchTechnicalVisitGQL: AdminSearchTechnicalVisitGQL,
                private reportService: ReportService,
                private adminUpdateVisitStatus: AdminTechnicalVisitUpdateStatusGQL
       
    ) {
        super(router, translate);
    }

    ngOnInit(): void {
        const filterSearch = localStorage.getItem('filterSearch') as string;
        if (!this.isNullOrUndefined(filterSearch)) {
            this.filter = JSON.parse(filterSearch);
        }
        this.summaryList();
        this.statusList();
        this.findAllGmr();
        this.search();
    }

    summaryList(): void {
        this.searchSummaryQuery = this.adminTechnicalVisitSummaryGQL.watch();
        this.searchSummaryQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.summary = data.adminTechnicalVisitSummary as TechnicalVisitSummary;
            },
        });
    }

    statusList(): void {
        this.searchStatusQuery = this.adminSearchTechinicalVisitStatusGQL.watch();
        this.searchStatusQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.modelStatus = data.adminSearchTechinicalVisitStatus as Array<TechnicalVisitStatusAdmin>;
            },
        });
    }

    private findAllGmr(): void {
        this.grmQuery = this.findAllGmrGQL.watch();
        this.grmQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.modelGrm = data.gmrFindAll as GmrAdmin[];
            },
        });
    }

    search(): void {
        this.filter.page = this.currentPage;
        this.technicalVisitQuery = this.adminSearchTechnicalVisitGQL.watch({input: this.filter});
        this.technicalVisitQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.modelTechnicalVisits = data.adminSearchTechnicalVisit as TechnicalVisitAdminPage;
            },
        });
    }

    pageChanged(event: any): void {
        this.currentPage = event;
        this.search();
    }

    onSearch(): void {
        localStorage.setItem('filterSearch', JSON.stringify(this.filter));
        this.currentPage = 1;
        this.search();
    }

    getExcel() {
        this.toggleLoader(true);
        this.reportService.getTechincalVisistExcel(this.filter).subscribe({
            next: (data) => {
                this.toggleLoader(false);
                this.downloadHandlerFile(data, 'Relatório_Visita_Técnica');
            },
            error: (err) => {
                super.onError(err);
                this.toggleLoader(false);
            },
        });
    }

    onClean() {
        localStorage.removeItem('filterSearch');
        this.filter = new TechnicalVisitFilterAdminInput();
        this.filter.page = 1;
        this.search();
    }

    openPdf(createdById: string) {
        const token = localStorage.getItem('token') as string;
        let url = `${environment.base_url}technical/pdf?token=${token}&id=${createdById}`;
        window.open(encodeURI(url));
    }

    handlerVisitUpdateStatus(technicalVisitId: string) {

        this.confirmMessage('Aviso!', 'Tem certeza que deseja atualizar o status da Visita Técnica?', () => {
            
            this.adminUpdateVisitStatus.mutate({
                input: {
                    technicalVisitId: technicalVisitId
                }
            }).subscribe({
                next: ({data}) => {
    
                    this.showMessage('Sucesso', 'Status da Visita atualizado com sucesso!', 'success');
    
                    this.technicalVisitQuery.refetch();
                   
                }, error: ({data}) => {
                    this.onError(data);
                }
            });
        }
    );

        

    }
}
