import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../service/user.service";
import { AdminSearchMechanicsExtraGQL, AdminSearchMechanicsExtraQuery, AdminSearchMechanicsExtraQueryVariables, GmrAdmin, GmrFindAllGQL, GmrFindAllQuery, GmrFindAllQueryVariables, MechanicsExtraPage, TechnicalVisitFilterAdminInput } from 'src/generated/graphql';
import { QueryRef } from 'apollo-angular';
import { ReportService } from 'src/app/service/report.service';

@Component({
    selector: 'app-mechanic',
    templateUrl: './mechanic.component.html',
    styleUrls: ['./mechanic.component.scss']
})
export class MechanicComponent extends BaseComponent implements OnInit {

    modelGrm: Array<GmrAdmin> = [];
    filter: TechnicalVisitFilterAdminInput = new TechnicalVisitFilterAdminInput();
    model: MechanicsExtraPage = new MechanicsExtraPage();
    currentPage = 1;

    constructor(public router: Router,
                public translate: TranslateService,
                public userService: UserService,
                private findAllGmrGQL: GmrFindAllGQL,
                private reportService: ReportService,
                private adminSearchMechanicsExtraGQL: AdminSearchMechanicsExtraGQL,
                ) {

        super(router, translate);
    }

    grmQuery: QueryRef<GmrFindAllQuery, GmrFindAllQueryVariables>;
    reportQuery: QueryRef<AdminSearchMechanicsExtraQuery,AdminSearchMechanicsExtraQueryVariables>;


    ngOnInit(): void {
        this.findAllGmr();
        this.search();
    }

    private findAllGmr(): void {
        this.grmQuery = this.findAllGmrGQL.watch();
        this.grmQuery.valueChanges.subscribe({
          next: ({ data }) => {
            this.modelGrm = data.gmrFindAll as GmrAdmin[];
          },
        });
      }

      pageChanged(event: any): void {
        this.currentPage = event;
        this.search();
      }

      getExcel() {
        this.toggleLoader(true);
        this.reportService.getMechanicsExtraExcel(this.filter).subscribe({
          next: (data) => {
            this.toggleLoader(false);
            this.downloadHandlerFile(data,'Relatório_Mecânica_Extra');
          },
          error: (err) => {
            super.onError(err);
            this.toggleLoader(false);
          },
        });
      }

      search(): void {
        this.filter.page = this.currentPage;
        this.reportQuery = this.adminSearchMechanicsExtraGQL.watch({
          input: this.filter
        });
        this.reportQuery.valueChanges.subscribe({
          next: ({data}) => {
              this.model = data.adminSearchMechanicsExtra as MechanicsExtraPage
              console.log(this.model);
          }
        });
      }

}
