import {Component, OnInit} from "@angular/core";
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {DashboardService} from "../../service/dashboard.service";
import {UserService} from "../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {
    BannerPage,
    RemoveBannerGQL,
    SearchBannerGQL,
    SearchBannerInput,
    SearchBannerQuery,
    SearchBannerQueryVariables,
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {BannerService} from "src/app/service/banner.service";
import {environment} from "src/environments/environment";

declare var $: any;
declare var moment: any;

@Component({
    selector: "app-banner",
    templateUrl: "./banner.component.html",
    styleUrls: ["./banner.component.scss"],
})
export class BannerComponent extends BaseComponent implements OnInit {
    modelFilter: SearchBannerInput = new SearchBannerInput();

    // currentPage = 1;
    labels: any = {
        previousLabel: '',
        nextLabel: '',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    searchBannerQuery: QueryRef<SearchBannerQuery, SearchBannerQueryVariables>;
    modelBannerResult: BannerPage = new BannerPage();

    constructor(
        public router: Router,
        public dashboardService: DashboardService,
        public userService: UserService,
        public translate: TranslateService,
        private bannerService: BannerService,
        private removeBannerGQL: RemoveBannerGQL,
        private searchBannerGQL: SearchBannerGQL
    ) {
        super(router, translate);
        this.modelFilter.page = 1;
    }

    ngOnInit(): void {
        this.modelFilter.start = moment().subtract(30, "days");
        this.modelFilter.end = moment();

        this.setDatePicker('js-daterangepicker', (start, end, label) => {
            this.modelFilter.start = start.utcOffset(0, true).format();
            this.modelFilter.end = end.utcOffset(0, true).format();
        });
        this.searchBanner();
    }

    searchBanner(): void {
        this.searchBannerQuery = this.searchBannerGQL.watch({input: this.modelFilter});

        this.searchBannerQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.modelBannerResult = data.searchBanner as BannerPage;
                console.log(this.modelBannerResult);
            },
            error: (err) => {
                super.onError(err);
            },
        });
    }

    getImageUrl(image: string): string {
        return `${environment.base_url_image}banner/image/${image}`;
    }

    handlerSearch() {
        this.modelBannerResult = new BannerPage();
        this.searchBanner();
    }

    handlerRemoveItem(i: string): void {
        this.confirmMessage('Aviso!', 'Tem certeza que deseja remover o banner?', () => {
                this.removeBannerGQL.mutate({
                    id: i
                }).subscribe({
                    next: () => {
                        this.showMessage('Aviso', 'Banner removido com sucesso.', 'success');
                        this.searchBannerQuery.refetch({input: this.modelFilter});
                    }
                });
            }
        );
    }

    pageChanged(event) {
        this.modelFilter.page = event;
        this.searchBanner();
    }

    getExcel() {
        this.toggleLoader(true);
        this.bannerService.getExcel({
            keyword: this.modelFilter.keyword,
            start: this.modelFilter.start,
            end: this.modelFilter.end,
            page: this.modelFilter.page,
        }).subscribe({
            next: data => {
                this.toggleLoader(false);
                this.downloadHandlerFile(data,'Banner');
            }, error: err => {
                super.onError(err);
                this.toggleLoader(false);
            }
        });
    }
}
