<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Visita Técnica<!-- | <span>Gestão</span>--></h1>
            <a class="btn btn-sm btn-info btn-text-white" (click)="getExcel()">
                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                Exportar Excel</a>
        </div>
        <div class="row mb-3">
            <div class="col-12 col-md-3">
                <div class="vt_card finished">
                    <svg-icon src="assets/svg/accept.svg"></svg-icon>
                    <div class="col">
                        <p><b>FINALIZADA</b></p>
                        <p><b>{{this.summary.quantityFinished}}</b></p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="vt_card received">
                    <svg-icon src="assets/svg/upward.svg"></svg-icon>
                    <div class="col">
                        <p><b>RECEBIDA</b></p>
                        <p><b>{{this.summary.quantityReceived}}</b></p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="vt_card notreceived">
                    <svg-icon src="assets/svg/warning-big.svg"></svg-icon>
                    <div class="col">
                        <p><b>NÃO RECEBIDA</b></p>
                        <p><b>{{this.summary.quantityNotreceived}}</b></p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="vt_card returned">
                    <svg-icon src="assets/svg/x.svg"></svg-icon>
                    <div class="col">
                        <p><b>DEVOLVIDA</b></p>
                        <p><b>{{this.summary.quantityReturned}}</b></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-filter">
            <div class="input-material">
                <input id="name" class="form-control" type="text" appOnlyNumber [(ngModel)]="filter.fatherCode"/>
                <label for="name">
                    <span>Código Pai</span>
                </label>
            </div>

            <div class="input-material">
                <input id="cnpj" class="form-control" type="text" mask="00.000.000/0000-00" [(ngModel)]="filter.cpf"/>
                <label for="cnpj">
                    <span>CNPJ</span>
                </label>
            </div>
            <div class="input-material">
                <input id="razao" class="form-control" type="text" [(ngModel)]="filter.company"/>
                <label for="razao">
                    <span>Razão Social</span>
                </label>
            </div>
            <div class="input-material">
                <select id="profile" class="form-control" name="type" [(ngModel)]="filter.gmr">
                    <option selected [ngValue]="undefined">{{'select' | translate}}</option>
                    <option *ngFor="let r of modelGrm" [ngValue]="r.id">{{r.name}}</option>
                </select>
                <label for="profile" class="mb-0 mr-3">GMR</label>
            </div>
            <div class="input-material select">
                <select id="country" class="form-control" name="type" [(ngModel)]="filter.status">
                    <option selected [ngValue]="undefined">{{'Todos' | translate}}</option>
                    <option *ngFor="let r of modelStatus" [ngValue]="r.id">{{r.adminDescription}}</option>
                </select>
                <label for="country" class="mb-0 mr-3 disabled">Status</label>
            </div>
            <div class="input-material select">
                <select id="country" class="form-control" name="type" [(ngModel)]="filter.statusStandardization">
                    <option selected [ngValue]="undefined">{{'Todos' | translate}}</option>
                    <option *ngFor="let r of modelStatus" [ngValue]="r.id">{{r.adminDescription}}</option>
                </select>
                <label for="country" class="mb-0 mr-3 disabled">Status Padronização</label>
            </div>
            <a class="btn btn-primary btn-lg text-white" (click)="currentPage = 1; onSearch()">Buscar</a>
            <a class="btn btn-outline-primary btn-lg" (click)="onClean()">limpar</a>
        </div>

        <!--GRID-->
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <!--                <th scope="col">ID</th>-->
                    <th scope="col">Código Pai</th>
                    <th scope="col">Razão</th>
                    <th scope="col">GMR</th>
                    <th scope="col">Data Recebimento</th>
                    <th scope="col">Pontuação</th>
                    <th scope="col">Avaliar</th>
                    <th scope="col">Status</th>
                    <th scope="col">Status Padronização</th>
                    <th scope="col">Opções</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(modelTechnicalVisits.technicalVisits)">
                    <td colspan="5">
                        <p class="text-center py-5">{{'nenhumresultado'|translate}}</p>
                    </td>
                </tr>
                <tr *ngFor="let item of this.modelTechnicalVisits.technicalVisits |
                paginate: {itemsPerPage: modelTechnicalVisits.PageSize,
                currentPage: modelTechnicalVisits.currentPage,
                totalItems: modelTechnicalVisits.totalCount }">
                    <td>{{item.fatherCode}}</td>
                    <td>{{item.companyName}}</td>
                    <td>{{item.gmr}}</td>
                    <td>{{item.updateAt | date: "dd/MM/yyyy hh:mm"}}</td>
                    <td>{{item.points}}</td>
                    <td>{{item.avaliation}}</td>
                    <td><span class="badge badge-{{getClassBadgeByStatus(item.status)}}">{{item.status}}</span></td>
                    <td><span
                            class="badge badge-{{getClassBadgeByStatus(item.statusStandardization)}}">{{item.statusStandardization}}</span>
                    </td>
                    <!-- <td>
                        <a class="btn btn-sm btn-ico btn-primary" href="javascript:void('');"
                           [routerLink]="['/visita-detalhe/' + item.id]"
                           *ngIf="item.status.toLowerCase() !== 'não recebida' || item.statusStandardization.toLowerCase() !== 'não recebida'">
                            <svg-icon class="svg-white" src="assets/svg/eyes.svg"></svg-icon>
                        </a>
                     </td>        -->
                    <td *ngIf="item.status.toLowerCase() !== 'não recebida'">
                        <a class="btn btn-sm btn-ico btn-primary" href="javascript:void('');">
                            <svg-icon [routerLink]="['/visita-detalhe/' + item.id]" class="svg-white"
                                      src="assets/svg/eyes.svg"></svg-icon>
                        </a>
                    </td>
                    <td *ngIf="item.status.toLowerCase() == 'não recebida' && (item.statusStandardization.toLowerCase() == 'recebida' || item.statusStandardization.toLowerCase() == 'devolvida' || item.statusStandardization.toLowerCase() == 'finalizada')">
                        <a class="btn btn-sm btn-ico btn-primary" href="javascript:void('');">
                            <svg-icon [routerLink]="['/padronizacao/' + item.id]" class="svg-white"
                                      src="assets/svg/eyes.svg"></svg-icon>

                        </a>
                    </td>
                    <td>
                        <a class="btn btn-sm btn-ico btn-primary" href="javascript:void('');">
                        <svg-icon (click)="handlerVisitUpdateStatus(item.id)" class="svg-white"
                                  src="assets/svg/edit.svg"></svg-icon>
                        </a>
                    </td>
                    <td *ngIf="item.status.toLowerCase() == 'finalizada' || item.statusStandardization.toLowerCase() == 'finalizada'">
                        <svg-icon (click)="openPdf(item.id)" class="svg-primary"
                                  src="assets/svg/pdf.svg"></svg-icon>
                    </td>
                    
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>

    </div>
</div>









