import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject,Observable} from 'rxjs';
import {LoginAdminGQL, LoginAdminMutation, User, UserAdmin} from '../../generated/graphql';
import { EmailValidator } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { of } from 'zen-observable';

@Injectable({
    providedIn: 'root'
})
export class UserService  {
    isAuthenticated = false;

    private tokenDataSource = new BehaviorSubject<string>(null);
    token = this.tokenDataSource.asObservable();

    private userDataSource = new BehaviorSubject<UserAdmin>(new UserAdmin());
    user = this.userDataSource.asObservable();

    private notificationDataSource = new BehaviorSubject<Notification[]>([]);
    notification = this.notificationDataSource.asObservable();

    constructor(private loginAdminGQL: LoginAdminGQL) {
    }

    updateToken(token: string) {
        localStorage.setItem('token', token);
    }

    getToken(){
       return localStorage.getItem('token');
    }

    getAuthenticate(credentials: {email: string, password: string} ): Observable<LoginAdminMutation> {
        return this.loginAdminGQL.mutate({
            input:{
                email: credentials.email,
                password: credentials.password
            }
        }).pipe(switchMap(({data}) => {
            const token = data?.loginAdmin.token;
            const userAdmin = data?.loginAdmin.user;
            this.updateToken(token);
            console.log("user-admin");
            console.log(userAdmin);
            this.userDataSource.next(userAdmin);
            this.isAuthenticated = true;
            return of(data as LoginAdminMutation);
        }))
    }

    getStatusAuthenticated() {
        const token = localStorage.getItem('token') as string;
        if (token != null) {
            this.isAuthenticated = true;
        } else {
            this.isAuthenticated = false;
        }
        return this.isAuthenticated;
    }

    search(page, filter) {
        // const url = `${this.BASE_URL}/user/search/${page}`;
        // return this.http.post(url, filter);
    }

    getMe() {
        // const url = `${this.BASE_URL}/user/me`;
        // return this.http.get(url).subscribe({
        //     next: data => {
        //         this.userDataSource.next(data as User);
        //         this.getUserLocal();
        //     }
        // });
    }

    create(model) {
        // const url = `${this.BASE_URL}/user`;
        // return this.http.post(url, model);
    }

    update(model) {
        // const url = `${this.BASE_URL}/user`;
        // return this.http.put(url, model);
    }

    updateUser(user: UserAdmin, tokenChanged: boolean = false, t: string = null) {
        if (tokenChanged) {
            this.updateToken(t);
            this.tokenDataSource.next(t);
        }
        this.userDataSource.next(user);
    }

    getUserLocal(): UserAdmin {
        return this.userDataSource.value;
    }

    delete(id) {
        // const url = `${this.BASE_URL}/user/${id}`;
        // return this.http.delete(url);
    }

    getNotification() {
        // const url = `${this.BASE_URL}/user/notification`;
        // this.http.get(url).subscribe({
        //     next: data => {
        //         this.notificationDataSource.next(data as Notification[]);
        //     }, error: err => {
        //     }
        // });
    }

    updateNotification(id) {
        // const url = `${this.BASE_URL}/user/notification/${id}`;
        // return this.http.put(url, {});
    }

}
