import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';

declare var $: any;

export class BaseComponent {

    constructor(public router: Router,
                public translate: TranslateService) {
    }

    setToken(token: string) {
        localStorage.setItem('token', token);
    }

    getBaseURL() {
        return environment.base_url;
    }

    getImageBaseURL() {
        return environment.base_url_image;
    }

    setCulture(culture: string) {
        localStorage.setItem('culture', culture);
    }

    switchLanguage(language: string, canReload = false) {
        if (language === 'pt') {
            language = 'pt';
            this.setCulture('pt-BR');
        } else if (language === 'en') {
            language = 'en';
            this.setCulture('en-US');
        } else if (language === 'es') {
            language = 'es';
            this.setCulture('es-ES');
        }
        this.translate.use(language);
        if (canReload) {
            location.reload();
        }
    }

    handlerConfirm() {
        $('#modalNoteResume').modal('show');
    }

    showModal(idModal) {
        $('#' + idModal).modal('show');
    }

    closeModal(idModal) {
        $('#' + idModal).modal('hide');
    }

    showMessage(t: string, m: string, type: any = 'warning') {
        Swal.fire({
            title: t,
            html: m,
            icon: type,
            confirmButtonColor: '#032246',
        });
    }

    confirmMessage(t: string, m: string, callback: any = null) {
        Swal.fire({
            title: t,
            html: m,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            confirmButtonColor: '#032246',
            cancelButtonColor: '#dc3545'
        }).then((result) => {
            if (!this.isNullOrUndefined(result.value) && result.value === true) {
                if (callback != null) {
                    callback();
                }
            }
        });
    }

    onError(error) {
        this.toggleLoader(false);
        if (error.status === 401) {
            this.router.navigate(['/authenticate']);
            return;
        }

        // const e = JSON.parse(error['error']);

        if (error.status === 400) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), error.error.message, 'warning');
            return;
        }

        if (error.status === 500) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgError'), 'warning');
            return;
        }
    }

    isNullOrUndefined(value) {
        return typeof value === 'undefined' || value == null || value.length === 0;
    }

    validateField(obj) {
        let strError = ``;
        obj.map((e) => {
            if (this.isNullOrUndefined(e.value)) {
                strError += `${e.text}`;
            }
        });
        return strError;
    }

    handlerFlagImage(value) {
        switch (value) {
            case 'Chile':
                return 'chile.svg';
            case 'Peru':
                return 'peru.svg';
            case 'Bolívia':
                return 'bolivia.svg';
            case 'Venezuela':
                return 'venezuela.svg';
            case 'Argentina':
                return 'argentina.svg';
            case 'México':
                return 'mexico.svg';
            case 'Equador':
                return 'equador.svg';
            case 'Guatemala':
                return 'guatemala.svg';
            case 'Brazil':
                return 'brazil.svg';
            case 'Colômbia':
                return 'colombia.svg';
            case 'Uruguai':
                return 'uruguay.svg';
            case 'Paraguay':
                return 'paraguay.svg';
        }
    }

    handlerStepBar(value) {

        switch (value) {
            case 1:
                return 'width: 0%';
            case 2:
                return 'width: 25%';
            case 3:
                return 'width: 50%';
            case 4:
                return 'width: 75%';
            case 5:
                return 'width: 100%';
        }
    }

    handlerTitlesBoxVertical(): void {
        const el = $('.page-content__details__tab');
        if (!el[0]) { return; }
        el.each((i, x) => {
            $(x).css({width: $(x).parent()[0].getBoundingClientRect().height});
        });
    }

    handlerBoxSize(): void {
        const el = $('.js-set-border');
        if (!el[0]) { return; }
        el.each((i, x) => {
            const size = $(x).parent()[0].getBoundingClientRect().height;
            $(x)
            .css({
                height: size
            })
            .find('span').css({
                width: size
            });
        });
    }

    getMonthNames() {
        let arrMonth = [];
        switch (this.translate.currentLang) {
            case 'en':
                arrMonth = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ];
                break;
            case 'es':
                arrMonth = [
                    'Enero',
                    'Febrero',
                    'Marzo',
                    'Abril',
                    'Mayo',
                    'Junio',
                    'Julio',
                    'Agosto',
                    'Septiembre',
                    'Octubre',
                    'Noviembre',
                    'Deciembre'
                ];
                break;
            default:
                arrMonth = [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ];
                break;
        }
        return arrMonth;
    }

    getDaysOfDayWeek() {
        let arr = [];
        switch (this.translate.currentLang) {
            case 'en':
                arr = [
                    'Sun',
                    'Mon',
                    'Tue',
                    'Wed',
                    'Thu',
                    'Fri',
                    'Sat'
                ];
                break;
            case 'es':
                arr = [
                    'Dom',
                    'lun',
                    'mar',
                    'mie',
                    'jue',
                    'vie',
                    'Sab'
                ];
                break;
            default:
                arr = [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ];
                break;
        }
        return arr;
    }

    setDatePicker(id: string, callback, date = 30): void {
        $('.' + id).daterangepicker({
            startDate: moment().subtract(date, 'days'),
            cancelButtonClasses: 'btn-danger',
            ranges: {
                Hoje: [moment(), moment()],
                Ontem: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '7 Dias': [moment().subtract(6, 'days'), moment()],
                '30 Dias': [moment().subtract(29, 'days'), moment()]
            },
            locale: {
                customRangeLabel: 'Personalizar',
                format: 'DD/MM/YYYY',
                separator: ' - ',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                monthNames: this.getMonthNames(),
                daysOfWeek: this.getDaysOfDayWeek(),
                firstDay: 1
            }
        }, callback);
    }

    setDate(id: string, callback, startDate?, timepicker?, maxDate?, format = 'DD/MM/YYYY') {
        $('.' + id).daterangepicker({
            timePicker: timepicker ? true : false,
            timePicker24Hour: timepicker ? true : false,
            startDate: startDate ? moment(startDate).utc() : moment(),
            singleDatePicker: true,
            maxDate: maxDate,
            cancelButtonClasses: 'btn-danger',
            locale: {
                customRangeLabel: 'Personalizar',
                format: format,
                separator: ' - ',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                monthNames: this.getMonthNames(),
                daysOfWeek: this.getDaysOfDayWeek(),
                firstDay: 1
            }
        }, callback);
    }

    toggleLoader(b: boolean): void {
        if (!b) {
            $('#loaderBox').stop().fadeOut();
        } else {
            $('#loaderBox').stop().fadeIn();
        }
    }

    isEMailValid(strEmail) {
        const str = strEmail;
        const exp = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
        const testResult = exp.test(str);
        if (!testResult) {
            this.showMessage(this.translate.instant('attention'), this.translate.instant('emailInform'), 'warning');
        }
        return testResult;
    }

    isCPFValid(strCPF) {
        let Soma;
        let Resto;
        Soma = 0;
        const strErrorTitle = this.translate.instant('attention');
        const strErrorDescription = 'Informe um CPF válido para prosseguir.';
        strCPF = strCPF.replace(/\D+/g, '');
        if (strCPF === '00000000000' || strCPF === '12112112155') {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }
        for (let i = 1; i <= 9; i++) {
            // tslint:disable-next-line:radix
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        }
        Resto = (Soma * 10) % 11;
        if ((Resto === 10) || (Resto === 11)) {
            Resto = 0;
        }
        // tslint:disable-next-line:radix
        if (Resto !== parseInt(strCPF.substring(9, 10))) {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }
        Soma = 0;
        for (let i = 1; i <= 10; i++) {
            // tslint:disable-next-line:radix
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        }
        Resto = (Soma * 10) % 11;
        if ((Resto === 10) || (Resto === 11)) {
            Resto = 0;
        }
        // tslint:disable-next-line:radix
        if (Resto !== parseInt(strCPF.substring(10, 11))) {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }
        return true;
    }

    onLogout() {
        this.confirmMessage('Atenção', 'Deseja realmente sair?', () => {
            const p = window.location.pathname.split('/');
            const virtualPath = p.length >= 3 ? '/' + p[1] : '';
            const redirectUrl = 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' +
                window.location.origin + virtualPath + '/logout';
            window.location.href = redirectUrl;
        });
    }

    getTranslate(s: string) {
        if (!this.isNullOrUndefined(s)) {
            return this.translate.instant(s);
        }
    }

    getMangerTypeById(k: string) {
        if (!this.isNullOrUndefined(k)) {
            return this.translate.instant('managersIds.' + k);
        }
    }

    getMangerTypeByKey(k: string) {
        if (!this.isNullOrUndefined(k)) {
            return this.translate.instant('managersName.' + k);
        }
    }

    downloadHandlerFile(e: any, fileName: string) {
        const downLoad = $('#btnDownload');
        downLoad.attr('href', e);
        downLoad.attr('download', fileName + '_' + moment().format('DD-MM-YYYY'));
        setTimeout(() => {
            $('#btnDownload')[0].click();
        });
    }

    enumStandardization(name: string) {
        if (this.isNullOrUndefined(name)) {
            return;
        }
        switch (name) {
            case 'wall':
                return 'Muro';
            case 'gates':
                return 'Portões';
            case 'gradil':
                return 'Gradil';
            case 'facade':
            case 'facadePlate':
                return 'Testeira';
            case 'Plate1':
                return 'Placa 1';
            case 'Plate2':
                return 'Placa 2';
            case 'Plate3':
                return 'Placa 3';
            case 'Cage':
                return 'Gaiola';
            default:
                return 'Adicionar';
        }
    }

    getClassBadgeByStatus(status: string) {
        if (this.isNullOrUndefined(status)) {
            return;
        }
        switch (status.toLowerCase()) {
            case 'finalizada':
                return 'success';
                break;
            case 'recebida':
                return 'info';
                break;
            case 'não recebida':
                return 'warning';
                break;
            case 'devolvida':
                return 'danger';
                break;
        }
    }

    labels: any = {
        previousLabel: ' ',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

}
