import {Component, OnInit} from "@angular/core";
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {DashboardService} from "../../service/dashboard.service";
import {UserService} from "../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {
    AccessDashboard,
    AccessDashboardGQL,
    AccessDashboardQuery,
    AccessDashboardQueryVariables,
    ActiveUsers,
    ActiveUsersDashboardGQL,
    ActiveUsersDashboardQuery,
    ActiveUsersDashboardQueryVariables,
    AdminSummaryQuizGQL,
    AdminSummaryQuizQuery,
    AdminSummaryQuizQueryVariables,
    AdminSummaryWatchingTrainingGQL,
    AdminSummaryWatchingTrainingQuery,
    AdminSummaryWatchingTrainingQueryVariables,
    AdminTechnicalVisitSummaryGQL,
    AdminTechnicalVisitSummaryQuery,
    AdminTechnicalVisitSummaryQueryVariables,
    BannerAccess,
    BannerDashboardGQL,
    BannerDashboardQuery,
    BannerDashboardQueryVariables,
    ContactCount,
    ContactDashboardGQL,
    ContactDashboardQuery,
    ContactDashboardQueryVariables,
    DownloadAccess,
    DownloadDashboardGQL,
    DownloadDashboardQuery,
    DownloadDashboardQueryVariables,
    NewsDashboard,
    NewsDashboardGQL,
    NewsDashboardQuery,
    NewsDashboardQueryVariables,
    ResaleCount,
    ResalesDashboardGQL,
    ResalesDashboardQuery,
    ResalesDashboardQueryVariables,
    SummaryQuiz,
    SummaryWatchingTraining,
    TechnicalVisitSummary,
} from "src/generated/graphql";
import {QueryRef} from "apollo-angular";
import {ReportService} from "src/app/service/report.service";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent extends BaseComponent implements OnInit {
    modelUserAccess: AccessDashboard[] = [];
    modelActiveUser: ActiveUsers[] = [];
    modelResales: ResaleCount[] = [];
    modelNews: NewsDashboard[] = [];
    modelContact: ContactCount = new ContactCount();
    modelDownload: DownloadAccess[] = [];
    modelBanner: BannerAccess[] = [];
    summary: TechnicalVisitSummary = new TechnicalVisitSummary();
    summaryTraining: any = [];
    summaryQuiz: any = [];

    trainingSeptember: any = 0;
    trainingOctober: any = 0;
    trainingNovember: any = 0;
    trainingDezember: any = 0;
    trainingJanuary: any = 0;
    trainingFebruary: any = 0;

    columnNames: [
        { type: "string"; role: "Country" },
        { type: "number"; role: "Access" }
    ];
    type = "LineChart";
    width = "100%";
    data = [["01/01", 0]];
    options = {
        colors: ["#e0440e", "#e6693e", "#ec8f6e", "#f3b49f", "#f6c7b6"],
        is3D: true,
    };

    constructor(
        public router: Router,
        public userService: UserService,
        public translate: TranslateService,
        private accessDashboardGQL: AccessDashboardGQL,
        private reportService: ReportService,
        private activeUsersDashboardGQL: ActiveUsersDashboardGQL,
        private resalesDashboardGQL: ResalesDashboardGQL,
        private contactDashboardGQL: ContactDashboardGQL,
        private newsDashboardGQL: NewsDashboardGQL,
        private adminTechnicalVisitSummaryGQL: AdminTechnicalVisitSummaryGQL,
        private adminSummaryWatchingTrainingGQL: AdminSummaryWatchingTrainingGQL,
        private adminSummaryQuizGQL: AdminSummaryQuizGQL,
        private downloadDashboardGQL: DownloadDashboardGQL,
        private bannerDashboardGQL: BannerDashboardGQL,
    ) {
        super(router, translate);
    }

    userAccessQuery: QueryRef<AccessDashboardQuery,
        AccessDashboardQueryVariables>;

    activeUsersQuery: QueryRef<ActiveUsersDashboardQuery,
        ActiveUsersDashboardQueryVariables>;

    resalesQuery: QueryRef<ResalesDashboardQuery,
        ResalesDashboardQueryVariables>;

    contactQuery: QueryRef<ContactDashboardQuery,
        ContactDashboardQueryVariables>;
    newsQuery: QueryRef<NewsDashboardQuery,
        NewsDashboardQueryVariables>;


    searchSummaryQuery: QueryRef<AdminTechnicalVisitSummaryQuery, AdminTechnicalVisitSummaryQueryVariables>;
    searchSummaryTraining: QueryRef<AdminSummaryWatchingTrainingQuery, AdminSummaryWatchingTrainingQueryVariables>;
    searchSummaryQuiz: QueryRef<AdminSummaryQuizQuery, AdminSummaryQuizQueryVariables>;
    downloadQuery: QueryRef<DownloadDashboardQuery, DownloadDashboardQueryVariables>;
    bannerQuery: QueryRef<BannerDashboardQuery, BannerDashboardQueryVariables>;


    ngOnInit(): void {
        this.userAccess();
        this.activeUser();
        this.resales();
        this.contacts();
        this.news();
        this.summaryList();
        this.summaryTrainingList();
        this.summaryQuizList();
        this.bannerAccess();
        this.downloadAccess();
    }

    userAccess(): void {
        this.userAccessQuery = this.accessDashboardGQL.watch();
        this.userAccessQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.modelUserAccess = data.accessDashboard as AccessDashboard[];
                console.log(this.modelUserAccess);

                this.createArrChartLine();
            },
        });
    }

    activeUser(): void {
        this.activeUsersQuery = this.activeUsersDashboardGQL.watch();
        this.activeUsersQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.modelActiveUser = data.activeUsersDashboard as ActiveUsers[];
            },
        });
    }

    resales(): void {
        this.resalesQuery = this.resalesDashboardGQL.watch();
        this.resalesQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.modelResales = data.resalesDashboard as ResaleCount[];
            },
        });
    }

    contacts(): void {
        this.contactQuery = this.contactDashboardGQL.watch();
        this.contactQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.modelContact = data.contactDashboard[0] as ContactCount;
            },
        });
    }

    news(): void {
        this.newsQuery = this.newsDashboardGQL.watch();
        this.newsQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.modelNews = data.newsDashboard as NewsDashboard[];
            },
        });
    }

    createArrChartLine() {
        this.data = [];
        this.modelUserAccess.forEach((e) => {
            this.data.push([e.createdAt, e.total]);
        });
    }

    getExcel() {
        this.toggleLoader(true);
        this.reportService.getUserAccessDashboardExcel().subscribe({
            next: (data) => {
                this.toggleLoader(false);
                this.downloadHandlerFile(data, 'Acessos');
            },
            error: (err) => {
                super.onError(err);
                this.toggleLoader(false);
            },
        });
    }

    summaryList(): void {
        this.searchSummaryQuery = this.adminTechnicalVisitSummaryGQL.watch();
        this.searchSummaryQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.summary = data.adminTechnicalVisitSummary as TechnicalVisitSummary;
            },
        });
    }

    summaryTrainingList(): void {
        this.searchSummaryTraining = this.adminSummaryWatchingTrainingGQL.watch();
        this.searchSummaryTraining.valueChanges.subscribe({
            next: ({data}) => {
                this.summaryTraining = data.adminSummaryWatchingTraining as SummaryWatchingTraining[];

                this.trainingSeptember = this.summaryTraining.filter(i => i.month == 9)[0];
                this.trainingOctober = this.summaryTraining.filter(i => i.month == 10)[0];
                this.trainingNovember = this.summaryTraining.filter(i => i.month == 11)[0];
                this.trainingDezember = this.summaryTraining.filter(i => i.month == 12)[0];
                this.trainingJanuary = this.summaryTraining.filter(i => i.month == 1)[0];
                this.trainingFebruary = this.summaryTraining.filter(i => i.month == 2)[0];

                console.log(this.summaryTraining);

            },
        });
    }

    summaryQuizList(): void {
        this.searchSummaryQuiz = this.adminSummaryQuizGQL.watch();
        this.searchSummaryQuiz.valueChanges.subscribe({
            next: ({data}) => {
                this.summaryQuiz = data.adminSummaryQuiz as SummaryQuiz[];

                console.log(this.summaryQuiz);

            },
        });
    }

    downloadAccess(): void {
        this.downloadQuery = this.downloadDashboardGQL.watch();
        this.downloadQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.modelDownload = data.downloadDashboard as DownloadAccess[];
            }
        });
    }

    bannerAccess(): void {
        this.bannerQuery = this.bannerDashboardGQL.watch();
        this.bannerQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.modelBanner = data.bannerDashboard as DownloadAccess[];
                console.log(this.modelBanner);
            }
        });
    }


}
