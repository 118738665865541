import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {UserService} from "../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {
    AnswerContactInput, AsnwerContactGQL,
    Contact,
    ContactPage,
    SearchContactGQL,
    SearchContactInput,
    SearchContactQuery
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import moment from "moment";
import {ContactService} from "../../service/contact.service";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends BaseComponent implements OnInit {
    filter: SearchContactInput = new SearchContactInput();
    searchContactQuery: QueryRef<SearchContactQuery>;
    contactPage: ContactPage = new ContactPage();
    contact: Contact = new Contact();

    constructor(public router: Router,
                public userService: UserService,
                public contactService: ContactService,
                public searchContactGQL: SearchContactGQL,
                public asnwerContactGQL: AsnwerContactGQL,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.filter.start = moment().subtract(30, 'days').format();
        this.filter.end = moment().format();

        this.searchContactQuery = this.searchContactGQL.watch({input: this.filter});
        this.searchContactQuery.valueChanges.subscribe(({data}) => {
            this.contactPage = data.searchContact as ContactPage;
        })

        this.setDatePicker('js-daterangepicker', (start, end, label) => {
            this.filter.start = start.utcOffset(0, true).format();
            this.filter.end = end.utcOffset(0, true).format();
        });
    }

    onDetailContact(contact: Contact) {
        Object.assign(this.contact, contact);
        this.showModal('modalContact');
    }

    onSave() {
        const data: AnswerContactInput = {
            contactId: this.contact.id,
            answer: this.contact.answer
        }
        this.asnwerContactGQL.mutate({input: data}).subscribe(({data}) => {
            this.closeModal('modalContact');
            this.searchContactQuery.refetch({input: this.filter}).then();
            this.showMessage('Aviso', 'Contato respondido com sucesso', 'success');
        })
    }

    onFilter() {
        this.searchContactQuery.refetch({input: this.filter}).then();
    }

    getExcel() {
        this.toggleLoader(true);
        this.contactService.getExcel(this.filter).subscribe({
            next: data => {
                this.toggleLoader(false);
                this.downloadHandlerFile(data,'Contatos');
            }, error: err => {
                super.onError(err);
                this.toggleLoader(false);
            }
        });
    }
}
