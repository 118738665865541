import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { QueryRef } from "apollo-angular";
import { BaseComponent } from "src/app/base/base.component";
import { ReportService } from "src/app/service/report.service";
import {
  FindAllRangeGQL,
  FindAllRangeQuery,
  FindAllRangeQueryVariables,
  GmrAdmin,
  GmrFindAllGQL,
  GmrFindAllQuery,
  GmrFindAllQueryVariables,
  Range,
  SearchUserResellerGQL,
  SearchUserResellerQuery,
  SearchUserResellerQueryVariables,
  UserResetPasswordGQL,
  UsersResellePage,
  UsersReseller,
  UsersResellerInput,
} from "src/generated/graphql";

@Component({
  selector: "app-user-reseller",
  templateUrl: "./user-reseller.component.html",
  styleUrls: ["./user-reseller.component.scss"],
})
export class UserResellerComponent extends BaseComponent implements OnInit {
  modelRange: Range[] = [];
  modelGrm: GmrAdmin[] = [];
  modelUserReseller: UsersResellePage = new UsersResellePage();
  currentPage = 1;
  filter: UsersResellerInput = new UsersResellerInput();

  constructor(
    public router: Router,
    public translate: TranslateService,
    private findAllRangeGQL: FindAllRangeGQL,
    private findAllGmrGQL: GmrFindAllGQL,
    private searchUserResellerGQL: SearchUserResellerGQL,
    private reportService: ReportService,
    private userResetPasswordGQL: UserResetPasswordGQL
  ) {
    super(router, translate);
  }

  rangeQuery: QueryRef<FindAllRangeQuery, FindAllRangeQueryVariables>;
  grmQuery: QueryRef<GmrFindAllQuery, GmrFindAllQueryVariables>;
  searchQuery: QueryRef<
    SearchUserResellerQuery,
    SearchUserResellerQueryVariables
  >;

  ngOnInit() {
    this.findAllRange();
    this.findAllGmr();
    this.search();
  }

  pageChanged(event: any): void {
    this.currentPage = event;
    this.search();
  }

  private findAllRange(): void {
    this.rangeQuery = this.findAllRangeGQL.watch();
    this.rangeQuery.valueChanges.subscribe({
      next: ({ data }) => {
        this.modelRange = data.findAllRange as Range[];
      },
    });
  }

  private findAllGmr(): void {
    this.grmQuery = this.findAllGmrGQL.watch();
    this.grmQuery.valueChanges.subscribe({
      next: ({ data }) => {
        this.modelGrm = data.gmrFindAll as GmrAdmin[];
      },
    });
  }

  search(): void {
    this.filter.page = this.currentPage;
    this.searchQuery = this.searchUserResellerGQL.watch({
      input: this.filter,
    });
    this.searchQuery.valueChanges.subscribe({
      next: ({ data }) => {
        this.modelUserReseller = data.userReseller as UsersResellePage;
      },
    });
  }

  searchUsersReseller(): void {
    this.currentPage = 1;
    this.search();
  }

  getExcel() {
    this.toggleLoader(true);
    this.reportService.getExcel(this.filter).subscribe({
      next: (data) => {
        console.log(data);
        this.toggleLoader(false);
        this.downloadHandlerFile(data,"Relatório_Revendas");
      },
      error: (err) => {
        super.onError(err);
        this.toggleLoader(false);
      },
    });
  }

  onResetPasswordUser(i: string): void {
    this.confirmMessage('Aviso!', 'Tem certeza que deseja resetar a senha?', () => {
      this.userResetPasswordGQL.mutate({id: i}).subscribe({
        next: ({data}) => {
          this.showMessage('Sucesso', 'Senha resetada com sucesso!', 'success');
        }
      });
    });
  }
}
