<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<section class="container">

    <div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
        <h1 class="page-title">Dashboard</h1>
        <a href="javascript:void('excel')" class="btn btn-sm btn-info btn-lg text-white" (click)="getExcel()">Exportar
            Excel</a>
    </div>

    <!--Gráfico de Acessos-->
    <div class="col-12">
        <div class="card mt-3 mx-2 bg-white p-3">
            <div class="text-primary col-12 font-weight-bold">Gráfico de Acessos</div>
            <hr>
            <div class="text-center py-5" *ngIf="isNullOrUndefined(data)">
                Nenhum dado registrado no momento!
            </div>
            <div class="graph_div">
                <google-chart
                        [type]="type"
                        [width]="width"
                        [data]="data"
                        [options]="options" style="width: 100%">
                </google-chart>
            </div>

        </div>
    </div>

    <!--Usuarios ativos e revendas por GME-->
    <div class="col-12">
        <div class="row">
            <div class="col-md-6 mt-3">
                <div class="card mx-2 bg-white" style="min-height: 155px;">
                    <div class="col-12 bg-tertiary rounded-top p-3 text-white font-weight-bold">Usuários Ativos</div>
                    <div class="p-3">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-md-4 text-center border-right px-2 my-2"
                                 *ngFor="let a of modelActiveUser; let i = index">
                                <h5>{{a.total}}</h5>
                                {{a.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-3">
                <div class="card mx-2 bg-white" style="min-height: 155px;">
                    <div class="col-12 bg-quaternary rounded-top p-3 text-white font-weight-bold">Revendas por GME</div>
                    <div class="p-3">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-md-3 text-center border-right px-2 mb-4" *ngFor="let item of modelResales"
                            >
                                <h5>{{item.total}}</h5>
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Visita Técnica-->
    <div class="col-12">
        <div class="card mt-3 mx-2 bg-white p-3">
            <div class="text-primary col-12 font-weight-bold">Visita Técnica</div>
            <hr>
            <div class="p-3">
                <div class="row d-flex justify-content-center align-items-center">
                    <div class="col-12 col-md-3 text-center border-right text-success px-2 mb-5">
                        <svg-icon class="svg-success" src="assets/svg/accept.svg"></svg-icon>
                        <h5 class="mt-2 mb-1">{{this.summary.quantityFinished}}</h5>
                        <!-- <h5 class="mt-2 mb-1">0</h5> -->
                        <strong>
                            Finalizada
                        </strong>

                    </div>
                    <div class="col-12 col-md-3 text-center border-right text-info px-2 mb-5">
                        <svg-icon class="infosvg" src="assets/svg/sidebar/chart-big.svg"></svg-icon>
                        <h5 class="mt-2 mb-1">{{this.summary.quantityReceived}}</h5>
                        <!-- <h5 class="mt-2 mb-1">0</h5> -->
                        <strong>
                            Recebida
                        </strong>
                    </div>
                    <div class="col-12 col-md-3 text-center border-right text-danger px-2 mb-5">
                        <svg-icon class="errorSvg" src="assets/svg/x.svg"></svg-icon>
                        <h5 class="mt-2 mb-1">{{this.summary.quantityNotreceived}}</h5>
                        <!-- <h5 class="mt-2 mb-1">0</h5> -->
                        <strong>
                            Não Recebida
                        </strong>

                    </div>
                    <div class="col-12 col-md-3 text-center px-2 text-warning mb-5">
                        <svg-icon class="returnedSvg" src="assets/svg/warning-big.svg"></svg-icon>
                        <h5 class="mt-2 mb-1">{{this.summary.quantityReturned}}</h5>
                        <!-- <h5 class="mt-2 mb-1">0</h5> -->
                        <strong>
                            Devolvida
                        </strong>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Treinamentos e fale conosco-->
    <div class="d-sm-flex">
        <div class="col-sm-12 mt-3">
            <div class="card mx-2 bg-white">
                <div class="col-12 bg-primary rounded-top p-3 text-white font-weight-bold">Vídeo de Treinamentos</div>

                    <div class="row p-4">
                        <!-- <div class="col-md-4 mb-3 px-2">
                            <div class="card bg-white">
                                <div class="col-12 bg-success rounded-top p-3 text-white font-weight-bold">Setembro
                                </div>
                                <div class="p-3">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="col-6 text-center">
                                            <h5>{{this.trainingSeptember.total}}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-md-4 mb-3 px-2">
                            <div class="card bg-white">
                                <div class="col-12 bg-success rounded-top p-3 text-white font-weight-bold">Outubro</div>
                                <div class="p-3">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="col-6 text-center">
                                            <h5>{{this.trainingOctober.total}}</h5>
                                            <!-- <h5>0</h5> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3 px-2">
                            <div class="card bg-white">
                                <div class="col-12 bg-success rounded-top p-3 text-white font-weight-bold">Novembro
                                </div>
                                <div class="p-3">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="col-6 text-center">
                                            <h5>{{this.trainingNovember.total}}</h5>
                                            <!-- <h5>0</h5> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3 px-2">
                            <div class="card bg-white">
                                <div class="col-12 bg-success rounded-top p-3 text-white font-weight-bold">Dezembro
                                </div>
                                <div class="p-3">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="col-6 text-center">
                                            <h5>{{this.trainingDezember.total}}</h5>
                                            <!-- <h5 class="mb-3"></h5> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3 px-2">
                            <div class="card bg-white">
                                <div class="col-12 bg-success rounded-top p-3 text-white font-weight-bold">Janeiro</div>
                                <div class="p-3">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="col-6 text-center">
                                            <h5>{{this.trainingJanuary.total}}</h5>
                                            <!-- <h5 class="mb-3">0</h5> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3 px-2">
                            <div class="card bg-white">
                                <div class="col-12 bg-success rounded-top p-3 text-white font-weight-bold">Fevereiro</div>
                                <div class="p-3">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="col-6 text-center">
                                            <h5>{{this.trainingFebruary.total}}</h5>
                                            <!-- <h5 class="mb-3">0</h5> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


            </div>
        </div>

    </div>

    <div class="d-sm-flex">
        <div class="col-sm-6 mt-3">
            <div class="card mx-2 bg-white">
                <div class="col-12 bg-quinternary rounded-top p-3 text-white font-weight-bold">Notícias</div>
                <div class="p-3 table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th class="align-middle w-75">Título</th>
                            <th class="align-middle text-center">Acessos</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let n of modelNews; let i = index">
                            <td>
                                <div class="text-ellipsis">
                                    {{n.title}}
                                </div>
                            </td>
                            <td class="text-center">{{n.total}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-sm-6 mt-3">
            <div class="card mx-2 bg-white">
                <div class="col-12 bg-orange rounded-top p-3 text-white font-weight-bold">Fale Conosco</div>
                <div class="row py-3">
                    <div class="col-md-6 text-center text-orange font-weight-bold">
                        <svg-icon class="svg-success" src="assets/svg/message.svg"></svg-icon>
                        <h5 class="mt-2 mb-1 text-orange">{{modelContact.noAnswered}}</h5>
                        Não Respondidas
                    </div>
                    <div class="col-md-6 text-center text-muted font-weight-bold border-right">
                        <svg-icon class="svg-warning" src="assets/svg/message.svg"></svg-icon>
                        <h5 class="mt-2 mb-1 text-muted">{{modelContact.total}}</h5>
                        Total
                    </div>

                    <!--          <div class="text-center font-weight-bold mt-4">-->
                    <!--            <svg-icon class="svg-danger" src="assets/svg/message.svg"></svg-icon>-->
                    <!--            <h5 class="mt-2 mb-1 text-danger">5</h5>-->
                    <!--            NÃO RESPONDIDAS-->
                    <!--          </div>-->
                </div>
            </div>
        </div>
    </div>

    <div class="d-sm-flex">
        <div class="col-sm-6 mt-3">
            <div class="card mx-2 bg-white">
                <div class="col-12 bg-quinary rounded-top p-3 text-white font-weight-bold">Quiz</div>
                <div class="p-3 table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th class="align-middle w-50">Pergunta</th>
                            <th class="align-middle text-center">Mês</th>
                            <th class="align-middle text-center">Total</th>
                            <th class="align-middle text-center">Corretas</th>
                            <th class="align-middle text-center">Incorretas</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let q of this.summaryQuiz let i = index">
                            <td>
                                <div class="text-ellipsis">
                                    {{q.question}}
                                </div>
                            </td>
                            <td class="text-center">{{q.month}}</td>
                            <td class="text-center">{{q.total}}</td>
                            <td class="text-center">{{q.correct}}</td>
                            <td class="text-center">{{q.incorrect}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="col-sm-6 mt-3">
            <div class="card mx-2 bg-white">
                <div class="col-12 bg-red rounded-top p-3 text-white font-weight-bold">Banner</div>
                <div class="p-3 table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th class="align-middle w-75">Banner</th>
                            <th class="align-middle text-center">Acessos</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let n of modelBanner; let i = index">
                            <td>
                                <div class="text-ellipsis">
                                    {{n.title}}
                                </div>
                            </td>
                            <td class="text-center">{{n.total}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>

    <div class="d-sm-flex">
        <div class="col-sm-6 mt-3">
            <div class="card mx-2 bg-white">
                <div class="col-12 bg-red rounded-top p-3 text-white font-weight-bold">Download</div>
                <div class="p-3 table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th class="align-middle w-75">Download</th>
                            <th class="align-middle text-center">Acessos</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let n of modelDownload; let i = index">
                            <td>
                                <div class="text-ellipsis">
                                    {{n.name}}
                                </div>
                            </td>
                            <td class="text-center">{{n.total}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</section>
