import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { GmrAdmin, GmrFindAllGQL, GmrFindAllQuery, GmrFindAllQueryVariables, ReportCouponsPage, ReportVideoPage, SearchReportCouponsGQL, SearchReportCouponsQuery, SearchReportCouponsQueryVariables, SearchReportVideoGQL, SearchReportVideoQuery, SearchReportVideoQueryVariables, TechnicalVisitFilterAdminInput } from 'src/generated/graphql';
import { QueryRef } from 'apollo-angular';
import { PassThrough } from 'stream';
import { ReportService } from 'src/app/service/report.service';

@Component({
  selector: 'app-report-coupon',
  templateUrl: './report-coupon.component.html',
  styleUrls: ['./report-coupon.component.scss']
})
export class ReportCouponComponent extends BaseComponent implements OnInit {

  filter: TechnicalVisitFilterAdminInput = new TechnicalVisitFilterAdminInput();
  modelGrm: Array<GmrAdmin> = [];
  modelResult: ReportCouponsPage = new ReportCouponsPage();
 
  currentPage = 1;

  constructor(public router: Router,
              public translate: TranslateService,
              private searchCouponsGQL: SearchReportCouponsGQL,
              private reportService: ReportService,
              private findAllGmrGQL: GmrFindAllGQL,
              
              ) {
    super(router, translate);
  }

  searchQuery: QueryRef<
  SearchReportCouponsQuery,
  SearchReportCouponsQueryVariables
>;
grmQuery: QueryRef<GmrFindAllQuery, GmrFindAllQueryVariables>;

  ngOnInit(): void {
    this.findAllGmr();
    this.actionSearch()
  }


  pageChanged(event) {
    this.currentPage = event;
    this.actionSearch();
  }

  actionSearch(): void {
     this.filter.page = this.currentPage;
    this.searchQuery = this.searchCouponsGQL.watch({
      input: this.filter,
    });
    this.searchQuery.valueChanges.subscribe({
      next: ({data}) => {
        this.modelResult = data.searchReportCoupons as ReportCouponsPage
      }
    })
  }

  getExcel(): void {
    this.toggleLoader(true);
    this.reportService.reportCouponExcel(this.filter).subscribe({
      next: (data) => {
        this.toggleLoader(false);
        this.downloadHandlerFile(data,'Relatório_Cupons');
      },
      error: (err) => {
        super.onError(err);
        this.toggleLoader(false);
      },
    });
  }

  private findAllGmr(): void {
    this.grmQuery = this.findAllGmrGQL.watch();
    this.grmQuery.valueChanges.subscribe({
      next: ({ data }) => {
        this.modelGrm = data.gmrFindAll as GmrAdmin[];
      },
    });
  }

}
