import {BrowserModule} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {HeaderComponent} from './views/header/header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SidebarComponent} from './views/sidebar/sidebar.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {LogoutComponent} from './views/logout/logout.component';
import {AppDataService} from './service/app_data.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import {HomeComponent} from './views/home/home.component';
import {AutocompleteComponent} from './util/autocomplete/autocomplete.component';
import {NotificationComponent} from './views/notification/notification.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {UserComponent} from './views/user/user.component';
import {HeaderHttpInterceptor} from './base/header-interceptor';
import {LightboxModule} from 'ngx-lightbox';
// NOVAS VIEWS
import {LoginComponent} from './views/login/login.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {GraphQLModule} from './graphql.module';
import {CURRENCY_MASK_CONFIG} from 'ng2-currency-mask';
import {AccessControlComponent} from './views/access-control/access-control.component';
import {AccessControlHomeComponent} from './views/access-control/home/access-control-home.component';
import {BannerComponent} from './views/banner/banner.component';
import {BannerDetailComponent} from './views/banner/detail/banner-detail.component';
import {DownloadsComponent} from './views/downloads/downloads.component';
import {DownloadsDetailComponent} from './views/downloads/detail/downloads-detail.component';
import {NewsComponent} from './views/news/news.component';
import {TechinicalVisitComponent} from './views/techinical-visit/techinical-visit.component';
import {AdminComponent} from './views/admin/admin.component';
import {ContactComponent} from './views/contact/contact.component';
import {CropImageComponent} from './views/crop-image/crop-image.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {TechinicalVisitDetailComponent} from './views/techinical-visit/detail/techinical-visit-detail.component';
import {ReportComponent} from './views/report/report.component';
import {PrizeComponent} from './views/prize/prize.component';
import {GoogleChartsModule} from 'angular-google-charts';
import {NewsDetailComponent} from './views/news/detail/news-detail.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {NgxMaskModule} from "ngx-mask";
import {UserResellerComponent} from './views/user-reseller/user-reseller.component';
import {UserResellerTeamComponent} from './views/user-reseller-team/user-reseller-team.component';
import {UserSalesTeamComponent} from './views/user-sales-team/user-sales-team.component';
import {UserExcludedComponent} from './views/user-excluded/user-excluded.component';
import {MechanicComponent} from './views/mechanic/mechanic.component';
import {OnlyNumberDirective} from "./directive/only-number.directive";
import { ReportTrainingComponent } from './views/report/report-training/report-training.component';
import { ReportQuizComponent } from './views/report/report-quiz/report-quiz.component';
import { ReportCouponComponent } from './views/report/report-coupon/report-coupon.component';


registerLocaleData(localePt, 'pt-BR');

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        SidebarComponent,
        LogoutComponent,
        HomeComponent,
        AutocompleteComponent,
        NotificationComponent,
        UserComponent,
        LoginComponent,
        DashboardComponent,
        AccessControlComponent,
        AccessControlHomeComponent,
        BannerComponent,
        BannerDetailComponent,
        DownloadsComponent,
        DownloadsDetailComponent,
        NewsComponent,
        NewsDetailComponent,
        TechinicalVisitComponent,
        AdminComponent,
        ContactComponent,
        CropImageComponent,
        TechinicalVisitDetailComponent,
        ReportComponent,
        PrizeComponent,
        UserResellerComponent,
        UserResellerTeamComponent,
        UserSalesTeamComponent,
        UserExcludedComponent,
        OnlyNumberDirective,
        MechanicComponent,
        ReportTrainingComponent,
        ReportQuizComponent,
        ReportCouponComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        SlickCarouselModule,
        FormsModule,
        GraphQLModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgApexchartsModule,
        LightboxModule,
        NgxPaginationModule,
        ImageCropperModule,
        GoogleChartsModule,
        CKEditorModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderHttpInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'BRL'
        },
        {
            provide: CURRENCY_MASK_CONFIG,
            useValue: {   // BRL
                align: 'left',
                allowNegative: true,
                decimal: ',',
                precision: 2,
                prefix: 'R$ ',
                suffix: '',
                thousands: '.'
            }
        },
        TranslateService,
        AppDataService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
