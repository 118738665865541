import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../service/user.service';
import { GmrAdmin, GmrFindAllGQL, GmrFindAllQuery, GmrFindAllQueryVariables, ReportQuizPage, SearchReportQuizzesGQL, SearchReportQuizzesQuery, SearchReportQuizzesQueryVariables, TechnicalVisitFilterAdminInput } from 'src/generated/graphql';
import { QueryRef } from 'apollo-angular';
import { ReportService } from 'src/app/service/report.service';

@Component({
  selector: 'app-report-quiz',
  templateUrl: './report-quiz.component.html',
  styleUrls: ['./report-quiz.component.scss']
})
export class ReportQuizComponent extends BaseComponent implements OnInit {

  filter: TechnicalVisitFilterAdminInput = new TechnicalVisitFilterAdminInput();
  modelGrm: Array<GmrAdmin> = [];
  modelResult: ReportQuizPage = new ReportQuizPage();
 
  currentPage = 1;
  


  constructor(public router: Router,
              public translate: TranslateService,
              private searchReportQuizzesGQL: SearchReportQuizzesGQL,
              private reportService: ReportService,
              private findAllGmrGQL: GmrFindAllGQL,
              
              ) {
    super(router, translate);
  }

  searchQuery: QueryRef<
  SearchReportQuizzesQuery,
  SearchReportQuizzesQueryVariables
>;
grmQuery: QueryRef<GmrFindAllQuery, GmrFindAllQueryVariables>;

  ngOnInit(): void {
    this.findAllGmr();
    this.actionSearch();
  }


  pageChanged(event) {
    this.currentPage = event;
    this.actionSearch();
  }

  actionSearch(): void {
    this.filter.page = this.currentPage;
   this.searchQuery = this.searchReportQuizzesGQL.watch({
     input: this.filter,
   });
   this.searchQuery.valueChanges.subscribe({
     next: ({data}) => {
       this.modelResult = data.searchReportQuizzes as ReportQuizPage
     }
   })
 }

 getExcel(): void {
   this.toggleLoader(true);
   this.reportService.reportQuizExcel(this.filter).subscribe({
     next: (data) => {
       this.toggleLoader(false);
       this.downloadHandlerFile(data,'Relatório_Quiz');
     },
     error: (err) => {
       super.onError(err);
       this.toggleLoader(false);
     },
   });
 }

 private findAllGmr(): void {
   this.grmQuery = this.findAllGmrGQL.watch();
   this.grmQuery.valueChanges.subscribe({
     next: ({ data }) => {
       this.modelGrm = data.gmrFindAll as GmrAdmin[];
     },
   });
 }

}
