import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../../service/user.service";
import {Lightbox} from 'ngx-lightbox';
import {
    AdminTechinicalVisitDetailsGQL,
    AdminTechinicalVisitDetailsQuery,
    AdminTechinicalVisitDetailsQueryVariables,
    AdminTechnicalVisitSaveGQL,
    AdminTechnicalVisitSaveStandardizationGQL,
    SearchBannerInput,
    TechnicalVisitDetails,
    TechnicalVisitSaveInput,
    Training
} from "../../../../generated/graphql";
import { QueryRef } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import {isArray} from "util";

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-techinical-visit-detail',
    templateUrl: './techinical-visit-detail.component.html',
    styleUrls: ['./techinical-visit-detail.component.scss']
})
export class TechinicalVisitDetailComponent extends BaseComponent implements OnInit {

    model: TechnicalVisitDetails = new TechnicalVisitDetails();
    modelTechVisit: any = {};
    standardization: any = {};

    technicalVisitId: string = '';
    modeInput: TechnicalVisitSaveInput = new TechnicalVisitSaveInput();

    baseFiles = `${environment.base_url_image}technical-visit/image/`;

    computerizedManagentCommentDisabled = false;
    trainingCommentDisabled = false;
    installationSafetyCommentDisabled = false;
    standardizationCommentDisabled = false;

    flagStandardization = false;
    albums: [] = [];

    constructor(public router: Router,
                public translate: TranslateService,
                public userService: UserService,
                private activatedRoute: ActivatedRoute,
                private lightbox: Lightbox,
                private adminTechinicalVisitDetailsGQL: AdminTechinicalVisitDetailsGQL,
                private adminTechnicalVisitSaveGQL: AdminTechnicalVisitSaveGQL,
                private adminTechnicalVisitSaveStandardizationGQL: AdminTechnicalVisitSaveStandardizationGQL,
                ) {

        super(router, translate);
        this.flagStandardization = this.router.url.indexOf('/padronizacao') >= 0;
        this.modelTechVisit.technicalVisitType = [];
        this.model.training = new Training();
        this.model.training.trainingDate = '';
    }


    file: File = null;
    thumpPath: string | ArrayBuffer;
    croppedImage: any = null;
    modelFilter: SearchBannerInput = new SearchBannerInput();

    technicalVisitQuery: QueryRef<AdminTechinicalVisitDetailsQuery, AdminTechinicalVisitDetailsQueryVariables>;

    ngOnInit(): void {
        this.modelFilter.start = moment().subtract(30, 'days');
        this.modelFilter.end = moment();

        this.setDatePicker('js-daterangepicker', (start, end, label) => {
            this.modelFilter.start = start.utcOffset(0, true).format();
        });

        // console.log('this.router.url');
        // console.log(this.router.url);

        this.activatedRoute.params.subscribe((param) => {
            this.technicalVisitId = param.id;
            this.find(this.technicalVisitId);
        });
    }

    find(techincaVisitlId: string) {
        this.technicalVisitQuery = this.adminTechinicalVisitDetailsGQL.watch({id: techincaVisitlId});
        this.technicalVisitQuery.valueChanges.subscribe({
            next: ({data}) => {
                // console.log(data);
                this.model = data.adminTechinicalVisitDetails as TechnicalVisitDetails;
                this.createModelTechVisit();
            },
        });
    }

    createModelTechVisit() {
        this.modelTechVisit.technicalVisitId = this.model.technicalVisitId;

        // GESTAO INFORMATIZADA
        const listCustomers = this.model.computerizedManagent.items.filter((x) => x.typeImage === 'listCustomers');
        const cashFlow = this.model.computerizedManagent.items.filter((x) => x.typeImage === 'cashFlow');
        const lObj = !listCustomers[0] ? this.createEmptyItem('listCustomers') : listCustomers[0];
        const c1Obj = !cashFlow[0] ? this.createEmptyItem('cashFlow') : cashFlow[0];
        const c2Obj = !cashFlow[1] ? this.createEmptyItem('cashFlow') : cashFlow[1];

        const lObjStatus = lObj.status === null ? 'pending' : lObj.status;
        const c1ObjStatus = c1Obj.status === null ? 'pending' : c1Obj.status;
        const c2ObjStatus = c2Obj.status === null ? 'pending' : c2Obj.status;

        const computerizedManagent = {
            typeId: this.model.computerizedManagent.typeId,
            message: '',
            technicalVisitItemId: this.model.computerizedManagent.technicalVisitItemId,
            type: 'computerizedManagent',
            items: [
                {type: 'listCustomers', id: lObj.id, status: lObjStatus, currentStatus: lObjStatus, path: lObj.path},
                {type: 'cashFlow', id: c1Obj.id, status: c1ObjStatus, currentStatus: c1ObjStatus, path: c1Obj.path},
                {type: 'cashFlow', id: c2Obj.id, status: c2ObjStatus, currentStatus: c2ObjStatus, path: c2Obj.path}
            ]
        };
        this.modelTechVisit.technicalVisitType.push(computerizedManagent);

        // TREINAMENTO
        const certificate = this.model.training.items.filter((x) => x.typeImage === 'certificate');
        const tObj = !certificate[0] ? this.createEmptyItem('certificate') : certificate[0];
        const tObjStatus = tObj.status === null ? 'pending' : tObj.status;
        // @ts-ignore
        const training = {
            typeId: this.model.training.typeId,
            message: '',
            technicalVisitItemId: this.model.training.technicalVisitItemId,
            type: 'training',
            items: [
                {type: 'certificate', id: tObj.id, status: tObjStatus, currentStatus: tObjStatus, path: tObj.path}
            ]
        };
        this.modelTechVisit.technicalVisitType.push(training);

        // SEGURANÇA
        const installationSafetyA = this.model.installationSafety.items.filter((x) => x.typeImage === 'image1');
        const installationSafetyB = this.model.installationSafety.items.filter((x) => x.typeImage === 'image2');
        const sObj = !installationSafetyA[0] ? this.createEmptyItem('image1') : installationSafetyA[0];
        const s2Obj = !installationSafetyB[0] ? this.createEmptyItem('image2') : installationSafetyB[0];
        const sObjStatus = sObj.status === null ? 'pending' : sObj.status;
        const s2ObjStatus = s2Obj.status === null ? 'pending' : s2Obj.status;
        const installationSafety = {
            typeId: this.model.installationSafety.typeId,
            message: '',
            technicalVisitItemId: this.model.installationSafety.technicalVisitItemId,
            type: 'installationSafety',
            items: [
                {type: 'image1', id: sObj.id, status: sObjStatus, currentStatus: sObjStatus, path: sObj.path},
                {type: 'image2', id: s2Obj.id, status: s2ObjStatus, currentStatus: s2ObjStatus, path: s2Obj.path}
            ]
        };
        this.modelTechVisit.technicalVisitType.push(installationSafety);

        this.standardization = this.model.standarlization;
        // console.log('this.standardization');
        // console.log(this.standardization);
        this.generateModel();
    }

    createEmptyItem(t) {
        return {type: t, id: '', status: 'pending', currentStatus: 'pending', path: ''};
    }

    createModelStandardization() {
        // MATRIZ
        const a = {
            typeId: this.model.standarlization.resaleFacade?.typeId,
            message: $('#standardizationComment').val(),
            technicalVisitItemId: this.standardization.resaleFacade?.technicalVisitItemId,
            items: []
        };
        this.standardization.resaleFacade?.items.map((x) => {
            a.items.push({id: x.id, status: x.status, currentStatus: x.status, path: "", type: ""});
        });
        this.modelTechVisit.technicalVisitType.push(a);

        // MATRIZ FROTA
        const aFrota = {
            typeId: this.standardization.resaleFleet?.typeId,
            message: $('#standardizationComment').val(),
            technicalVisitItemId: this.standardization.resaleFleet?.technicalVisitItemId,
            items: []
        };
        this.standardization.resaleFleet?.items.map((x) => {
            aFrota.items.push({id: x.id, status: x.status, currentStatus: x.status, path: "", type: ""});
        });
        this.modelTechVisit.technicalVisitType.push(aFrota);

        // FILIAIS
        this.standardization.companyBranch?.map((x) => {
            const f = {
                typeId: x.resaleFacade?.typeId,
                message: '',
                technicalVisitItemId: x.resaleFacade?.technicalVisitItemId,
                items: []
            };
            x.resaleFacade?.items.map((e) => {
                f.items.push({id: e.id, status: e.status, currentStatus: e.status, path: "", type: ""});
            });
            this.modelTechVisit.technicalVisitType.push(f);

            const jFrota = {
                typeId: x.resaleFleet?.typeId,
                message: '',
                technicalVisitItemId: x.resaleFleet?.technicalVisitItemId,
                items: []
            };
            x.resaleFleet?.items.map((y) => {
                jFrota.items.push({id: y.id, status: y.status, currentStatus: y.status, path: "", type: ""});
            });
            this.modelTechVisit.technicalVisitType.push(jFrota);
        });

    }

    submit(): void {
        let r = 0;
        if (!this.flagStandardization) {
            if (!this.computerizedManagentCommentDisabled && this.isNullOrUndefined($('#computerizedManagentComment').val())) {
                this.showMessage('Atenção', 'Para prosseguir é necessário inserir um comentário na aba Gestão informatizada.', 'warning');
                return;
            }
            if (!this.trainingCommentDisabled && this.isNullOrUndefined($('#trainingComment').val())) {
                this.showMessage('Atenção', 'Para prosseguir é necessário inserir um comentário na aba Treinamento.', 'warning');
                return;
            }
            if (!this.installationSafetyCommentDisabled && this.isNullOrUndefined($('#installationSafetyComment').val())) {
                this.showMessage('Atenção', 'Para prosseguir é necessário inserir um comentário na aba Segurança nas instalações.', 'warning');
                return;
            }
        }

        this.modelTechVisit.technicalVisitType.map((x) => {
            const u = x.items.filter((y) => this.isNullOrUndefined(y.status));
            if (u.length > 0) {
                r++;
            }
        });
        const v = this.standardization.resaleFacade?.items.filter((y) => this.isNullOrUndefined(y.status));
        if (v.length > 0) { r++; }
        const v2 = this.standardization.resaleFleet?.items.filter((y) => this.isNullOrUndefined(y.status));
        if (v2.length > 0) { r++; }
        this.standardization.companyBranch.map((y) => {
            const v3 = y?.resaleFacade?.items.filter((z) => this.isNullOrUndefined(z.status));
            if (v3.length > 0) { r++; }
            const v4 = y?.resaleFleet?.items.filter((z) => this.isNullOrUndefined(z.status));
            if (v4.length > 0) { r++; }
        });

        if (r > 0) {
            this.showMessage('Atenção', 'Para prosseguir é necessário avaliar todos os itens.', 'warning');
            return;
        }
        if (!this.standardizationCommentDisabled && this.isNullOrUndefined($('#standardizationComment').val())) {
            this.showMessage('Atenção', 'Para prosseguir é necessário inserir um comentário na aba Padronização.', 'warning');
            return;
        }
        this.createModelStandardization();
        // return;

        /*let validation = '';
        if (!this.isNullOrUndefined(validation)) {
            this.showMessage('Atenção', 'Preencha os campos obrigatórios corretamente: <br><br> ' + validation, 'warning');
            return;
        }*/

        if (this.flagStandardization) { // PADRONZIAÇAO
            this.onSaveStandardization();
            return;
        }

        // console.log(this.modelTechVisit);

        this.adminTechnicalVisitSaveGQL.mutate({
            input: this.modelTechVisit
        }).subscribe({
            next: ({data}) => {
                const a: any = data;
                if (a.errors) {
                    this.showMessage('Atenção', a.errors[0].message, 'warning');
                    return;
                }
                // console.log('adminTechnicalVisitSaveGQL');
                // console.log(this.modelTechVisit);
                this.showMessage('Sucesso', 'Visita técnica avaliada com sucesso!', 'success');
                this.router.navigate(['/visita']);
            }, error: ({data}) => {
                this.onError(data);
                this.router.navigate(['/visita']);
                // window.location.reload();
            }
        });
    }

    onSaveStandardization() {
        // console.log('Aqui vamos salvar a padronização');
        // console.log(this.modelTechVisit);

        this.adminTechnicalVisitSaveStandardizationGQL.mutate({
            input: this.modelTechVisit
        }).subscribe({
            next: ({data}) => {
                // console.log('adminTechnicalVisitSaveStandardizationGQL');
                this.showMessage('Sucesso', 'Padronização avaliada com sucesso!', 'success');
                this.router.navigate(['/visita']);
            }, error: ({data}) => {
                this.onError(data);
                this.router.navigate(['/visita']);
                // window.location.reload();
            }
        });


    }

    getImageUrl(image: string): string {
        return `${environment.base_url_image}technical-visit/image/${image}`;
    }

    getImageUrlUser(image: string): string {
        return `${environment.base_url_image}user/image/${image}`;
    }

    generateModel() {
        this.filterModel(this.standardization.resaleFacade);
        this.filterModel(this.standardization.resaleFleet);
        this.standardization.companyBranch.map((b) => {
            this.filterModel(b.resaleFacade);
            this.filterModel(b.resaleFleet);
        });
        setTimeout(() => {
            this.trainingBlockComment();
            this.standardizationBlockComment();
            this.installationSafetyBlockComment();
            this.computerizedManagentBlockComment();
        }, 100);
    }

    filterModel(standardizationItem: any) {
        /*console.log('inicio filter model');
        console.log(standardizationItem);*/
        standardizationItem.items.map((x) => {
            const itemStatus = x.status === null ? 'pending' : x.status;
            x.currentStatus = itemStatus;
            x.status = itemStatus;
        });
        standardizationItem.typeImage.map((x) => {
            const el = standardizationItem.items.filter((c) => c.typeImage === x.name);
            if (!el[0]) { return; }
            const itemStatus = el[0].status === null ? 'pending' : el[0].status;
            if (this.isNullOrUndefined(el)) {
                x.idPath = '';
                x.path = '';
                x.status = 'pending';
                x.currentStatus = 'pending';
            } else {
                x.idPath = el[0].id;
                x.path = el[0].path;
                x.status = itemStatus;
                x.currentStatus = itemStatus;
            }
        });
        /*console.log('finalizado filter model');
        console.log(standardizationItem);*/
    }

    standardizationBlockComment() {
        let r = 0;
        this.standardization?.resaleFacade?.items.map((x) => {
            if ((x.status === 'pending' && x.currentStatus === 'pending')
                || (x.status !== x.currentStatus && (x.status === 'pending' || x.status === 'disapproved'))) {
                r++;
            }
        });

        this.standardization?.resaleFleet?.items.map((x) => {
            if ((x.status === 'pending' && x.currentStatus === 'pending')
                || (x.status !== x.currentStatus && (x.status === 'pending' || x.status === 'disapproved'))) {
                r++;
            }
        });
        this.standardization?.companyBranch?.map((y) => {
            y?.resaleFacade?.items.map((x) => {
                if ((x.status === 'pending' && x.currentStatus === 'pending')
                    || (x.status !== x.currentStatus && (x.status === 'pending' || x.status === 'disapproved'))) {
                    r++;
                }
            });
            y?.resaleFleet?.items.map((x) => {
                if ((x.status === 'pending' && x.currentStatus === 'pending')
                    || (x.status !== x.currentStatus && (x.status === 'pending' || x.status === 'disapproved'))) {
                    r++;
                }
            });
        });
        this.standardizationCommentDisabled = this.model.status.toLowerCase() === 'recebida' ? false : this.model.statusStandardization.toLowerCase() !== 'recebida' || r === 0;
        // this.standardizationCommentDisabled = this.model.statusStandardization.toLowerCase() !== 'recebida' || r === 0;
    }

    installationSafetyBlockComment() {
        let r = 0;
        this.modelTechVisit.technicalVisitType.map((c) => {
            if (c.type === 'installationSafety') {
                c.items.map((x) => {
                    if ((x.status === 'pending' && x.currentStatus === 'pending')
                        || (x.status !== x.currentStatus && (x.status === 'pending' || x.status === 'disapproved'))) {
                        r++;
                    }
                });
            }
        });
        this.installationSafetyCommentDisabled = this.model.status.toLowerCase() !== 'recebida' || r === 0;
    }

    trainingBlockComment() {
        let r = 0;
        this.modelTechVisit.technicalVisitType.map((c) => {
            if (c.type === 'training') {
                c.items.map((x) => {
                    if ((x.status === 'pending' && x.currentStatus === 'pending')
                        || (x.status !== x.currentStatus && (x.status === 'pending' || x.status === 'disapproved'))) {
                        r++;
                    }
                });
            }
        });
        this.trainingCommentDisabled = this.model.status.toLowerCase() !== 'recebida' || r === 0;
    }

    computerizedManagentBlockComment() {
        let r = 0;
        this.modelTechVisit.technicalVisitType.map((c) => {
            if (c.type === 'computerizedManagent') {
                c.items.map((x) => {
                    if ((x.status === 'pending' && x.currentStatus === 'pending')
                        || (x.status !== x.currentStatus && (x.status === 'pending' || x.status === 'disapproved'))) {
                        r++;
                    }
                });
            }
        });
        this.computerizedManagentCommentDisabled = this.model.status.toLowerCase() !== 'recebida' || r === 0;
    }

    open(index: number, images: any): void {
        this.albums = [];
        if (isArray(images)) {
            for (const img of images) {
                const album = {
                    src: img.pathImage
                };
                // @ts-ignore
                this.albums.push(album);
            }
        } else {
            const album = {
                src: images // .pathImage
            };

            // @ts-ignore
            this.albums.push(album);
        }
        this.lightbox.open(this.albums, index, {
            wrapAround: false,
            showImageNumberLabel: false,
            disableScrolling: true,
            showZoom: false,
            showRotate: false,
            alwaysShowNavOnTouchDevices: true
        });
    }

}
