import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {DashboardService} from '../../service/dashboard.service';
import {UserService} from '../../service/user.service';
import {TranslateService} from '@ngx-translate/core';
import {
    DownloadPage,
    GetCategoryDownloadGQL,
    GetCategoryDownloadQuery,
    GetCategoryDownloadQueryVariables,
    GetProfilesGQL,
    GetProfilesQuery,
    GetProfilesQueryVariables,
    Profile,
    RemoveDownloadGQL,
    SearchDownloadGQL, SearchDownloadInput,
    SearchDownloadQuery,
    SearchDownloadQueryVariables,
} from 'src/generated/graphql';
import {QueryRef} from 'apollo-angular';
import {DownloadResult} from './model/download-result';
import {CategoryDownload} from './model/category-download';
import {DownloadCreate} from './model/download-create';
import {DownloadService} from 'src/app/service/download.service';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-downloads',
    templateUrl: './downloads.component.html',
    styleUrls: ['./downloads.component.scss'],
})
export class DownloadsComponent extends BaseComponent implements OnInit {

    modelFilter: SearchDownloadInput = new SearchDownloadInput();
    // currentPage = 1;
    labels: any = {
        previousLabel: '',
        nextLabel: '',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    searchDownloadQuery: QueryRef<SearchDownloadQuery, SearchDownloadQueryVariables>;
    modelDownloadResult: DownloadPage = new DownloadPage();

    constructor(
        public router: Router,
        public dashboardService: DashboardService,
        public userService: UserService,
        public translate: TranslateService,
        private searchDownloadGQL: SearchDownloadGQL,
        private getCategoryDownloadGQL: GetCategoryDownloadGQL,
        private getProfilesGQL: GetProfilesGQL,
        private removeDownloadGQL: RemoveDownloadGQL,
        private downloadService: DownloadService
    ) {
        super(router, translate);
        this.modelFilter.page = 1;
    }

    ngOnInit(): void {
        this.modelFilter.start = moment().subtract(30, 'days');
        this.modelFilter.end = moment();

        this.setDatePicker('js-daterangepicker', (start, end, label) => {
            this.modelFilter.start = start.utcOffset(0, true).format();
            this.modelFilter.end = end.utcOffset(0, true).format();
        });

        this.searchDownload();
    }

    searchDownload() {
        this.searchDownloadQuery = this.searchDownloadGQL.watch({
            input: {
                keyword: this.modelFilter.keyword,
                start: this.modelFilter.start,
                end: this.modelFilter.end,
                page: this.modelFilter.page,
            },
        });

        this.searchDownloadQuery.valueChanges.subscribe(({data}) => {
            this.modelDownloadResult = data.searchDownload as DownloadPage;
            console.log(this.modelDownloadResult);
        });
    }

    handlerRemoveItem(i: string): void {
        this.confirmMessage('Aviso!', 'Tem certeza que deseja remover o download?', () => {
                this.removeDownloadGQL.mutate({id: i}).subscribe({
                    next: () => {
                        this.searchDownloadQuery.refetch({input: this.modelFilter});
                        this.showMessage('Aviso', 'Download removido com sucesso', 'success'
                        );
                    },
                });
            }
        );
    }

    message(): void {
        this.showMessage('Sucesso', 'Download cadastrado com sucesso!', 'success');
    }

    pageChanged(event) {
        this.modelFilter.page = event;
        this.searchDownload();
    }

    getExcel() {
        this.toggleLoader(true);
        this.downloadService.getExcel({
            keyword: this.modelFilter.keyword,
            start: this.modelFilter.start,
            end: this.modelFilter.end,
            page: this.modelFilter.page,
        }).subscribe({
            next: data => {
                this.toggleLoader(false);
                this.downloadHandlerFile(data,'Downloads');
            }, error: err => {
                super.onError(err);
                this.toggleLoader(false);
            }
        });
    }
}
