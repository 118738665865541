<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title"><small>Relatórios ></small> Cupons<!-- | <span>Gestão</span>--></h1>
        </div>

        <div class="page-filter">
            <div class="input-material">
                <input id="father" class="form-control" type="text" [(ngModel)]="filter.fatherCode"/>
                <label for="father">
                    <span>Código Pai</span>
                </label>
            </div>
            <div class="input-material">
                <input id="cpf" class="form-control" type="text" [(ngModel)]="filter.cpf"/>
                <label for="cpf">
                    <span>CPF</span>
                </label>
            </div>
            <div class="input-material">
                <input id="name" class="form-control" type="text" [(ngModel)]="filter.name"/>
                <label for="name">
                    <span>Nome</span>
                </label>
            </div>
                <div class="input-material">
                    <select id="profile" class="form-control" name="type" [(ngModel)]="filter.gmr">
                        <option selected
                                [ngValue]="undefined">{{'select' | translate}}</option>
                        <option *ngFor="let r of modelGrm" [ngValue]="r.id">{{r.name}}</option>
                    </select>
                    <label for="profile" class="mb-0 mr-3">GMR</label>
                </div>            
          
            <div class="input-material">
                <input id="gve" class="form-control" type="text" [(ngModel)]="filter.gve" />
                <label for="gve">
                    <span>GVE</span>
                </label>
            </div>
            <div class="input-material">
                <input id="razao" class="form-control" type="text" [(ngModel)]="filter.company"/>
                <label for="razao">
                    <span>Razão</span>
                </label>
            </div>
            <br>
            <a class="btn btn-primary btn-lg text-white" (click)="actionSearch()">Buscar</a>
            <a class="btn btn-info btn-lg text-white" (click)="getExcel()">Exportar Excel</a>
        </div>

        <!--GRID-->
        <div class="table-responsive">
            <table class="table table-striped" border="1">
                <thead>
                  <tr>
                    <th class="align-middle" rowspan="3">Perfil</th>
                    <th class="align-middle text-center" rowspan="3">Código Pai</th>
                    <th class="align-middle text-center" rowspan="3">Razão</th>
                    <th class="align-middle text-center" rowspan="3">Nome</th>
                    <th class="align-middle text-center" rowspan="3">CPF</th>
                    <th class="align-middle text-center" rowspan="3">GMR</th>
                    <th class="align-middle text-center" rowspan="3">GVE</th>
                    <th class="align-middle text-center" rowspan="3">Cupom</th>
                    <th class="align-middle text-center" rowspan="3">Data de Criação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let r of modelResult.coupons |
                  paginate: {
                   itemsPerPage: modelResult.PageSize,
                   currentPage: modelResult.currentPage,
                   totalItems: modelResult.totalCount}">

        <td class="align-middle text-center">{{r.profile}}</td>
        <td class="align-middle text-center">{{r.fatherCode}}</td>
        <td class="align-middle text-center">{{r.companyName}}</td>
        <td class="align-middle text-center">{{r.name}}</td>
        <td class="align-middle text-center">{{r.cpf}}</td>
        <td class="align-middle text-center">{{r.gmr}}</td>
        <td class="align-middle text-center">{{r.gve}}</td>
        <td class="align-middle text-center">{{r.coupon}}</td>
        <td class="align-middle text-center">{{r.createdAt | date: "dd/MM/yyyy"}}</td>

      </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>

    </div>
</div>









