<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <svg-icon [routerLink]="'/downloads'" src="assets/svg/arrow.svg"></svg-icon>
            <h1 class="page-title">Downloads<!-- | <span>Gestão</span>--></h1>
            <!--<a href="javascript:void('');" class="btn btn-gray-fill">
                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                Improtar Excel
            </a>-->
        </div>


        <div class="row my-3">
            <!--NAME-->
            <div class="col-md-6">
                <div class="input-material">
                    <input id="downloadTitle" class="form-control" type="text"
                           required maxlength="100" [(ngModel)]="modelDownload.name"/>
                    <label for="downloadTitle">Título*</label>
                </div>
            </div>

            <div class="col-6">
                <div class="input-material flex-grow-1">
                    <select id="downloadCategory" class="form-control" name="type" [(ngModel)]="modelDownload.category.id">
                        <option selected [ngValue]="undefined">Selecione</option>
                        <option *ngFor="let item of categoryDownload" [ngValue]="item.id">{{item.name}}</option>
                    </select>
                    <label for="downloadCategory" class="mb-0 mr-3">Categoria</label>
                </div>
            </div>
            <div class="col-12">
                <div class="card p-3 shadow-none mb-4">
                    <label>Perfis de Acesso</label>
                    <div class="row mx-0">
                        <!--<div class="col-auto px-2 mb-2">
                            <div class="custom-control custom-checkbox mb-0">
                                <input type="checkbox" class="custom-control-input" id="1">
                                <label class="custom-control-label" for="1">Perfil 1</label>
                            </div>
                        </div>-->
                        <div class="col-auto px-2 mb-2" *ngFor="let item of this.profiles">
                            <div class="custom-control custom-checkbox mb-0">
                                <input type="checkbox" class="custom-control-input" [(ngModel)]="item.checked"
                                       [id]="item.id"
                                       [disabled]="item.id === 'MA'">
                                <label class="custom-control-label" [for]="item.id">{{item.name}}</label>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-sm-12">
                    <div class="card p-3 shadow-none mb-4 justify-content-center" style="min-height: 320px;">
                        <div class="row m-0 text-center" style="object-fit: cover;">
                            <div class="col-12"*ngIf="!isNullOrUndefined(modelDownload.path)">
                                <a [href]="getImageUrl(modelDownload.path)" target="_blank" class="btn btn-info text-white mt-3">Fazer Download</a>
                                <p class="text-center my-4">Ou enviar novo arquivo</p>
                            </div>
                            <div class="col-12 pb-2 pr-2 p-0 d-flex flex-column justify-content-center align-items-center">
                                <div class="col-12 mb-2" *ngIf="!isNullOrUndefined(thumpPath)">
                                    <svg-icon class="svg" src="assets/svg/pdf.svg"></svg-icon>
                                    <svg-icon class="svg" src="assets/svg/media.svg"></svg-icon>
                                </div>
                                <ng-container *ngIf="isNullOrUndefined(thumpPath)">
                                    <svg-icon class="svg-gray" src="assets/svg/pdf.svg"></svg-icon>
                                    <svg-icon class="svg-gray" src="assets/svg/media.svg"></svg-icon>
                                    <span class="my-2">
                                        Faça o upload do arquivo.<br/>
                                        <small>Arquivo PDF ou MP4, tamanho máximo de 200MB</small>
                                      </span>
                                </ng-container>
                                <form>
                                    <div class="form-group mt-4">
                                        <label for="FormControlFile2" class="btn btn-block btn-outline-primary">
                                            <span *ngIf="isNullOrUndefined(thumpPath)">Adicionar um arquivo</span>
                                            <span *ngIf="!isNullOrUndefined(thumpPath)">Alterar arquivo</span>
                                        </label>
                                        <input type="file" class="form-control-file d-none" id="FormControlFile2"
                                               onclick="this.value = null" (change)="handleFileInput($event.target.files)">
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-block btn-primary text-white mt-3" (click)="handlerSave()">Salvar</button>
        </div>
    </div>
</div>

