import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {DashboardService} from "../../service/dashboard.service";
import {UserService} from "../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {BaseComponent} from "../../base/base.component";
import {environment} from "src/environments/environment";
import {
    CreateNewsGQL,
    GetProfilesGQL,
    GetProfilesQuery,
    GetProfilesQueryVariables, NewsPage,
    RemoveNewsGQL,
    SearchNewsGQL, SearchNewsInput,
    SearchNewsQuery,
    SearchNewsQueryVariables,
} from "src/generated/graphql";
import {QueryRef} from "apollo-angular";
import {NewsService} from "src/app/service/news.service";

declare var $: any;
declare var moment: any;

@Component({
    selector: "app-news",
    templateUrl: "./news.component.html",
    styleUrls: ["./news.component.scss"],
})
export class NewsComponent extends BaseComponent implements OnInit {

    modelFilter: SearchNewsInput = new SearchNewsInput();

    // currentPage = 1;
    labels: any = {
        previousLabel: '',
        nextLabel: '',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    profileQuery: QueryRef<GetProfilesQuery, GetProfilesQueryVariables>;
    profiles: Array<any> = [];

    searchNewsQuery: QueryRef<SearchNewsQuery, SearchNewsQueryVariables>;
    modelNewsResult: NewsPage = new NewsPage();

    constructor(
        public router: Router,
        public dashboardService: DashboardService,
        public userService: UserService,
        public translate: TranslateService,
        private getProfilesGQL: GetProfilesGQL,
        private searchNewsGQL: SearchNewsGQL,
        private createNewsGQL: CreateNewsGQL,
        private newsService: NewsService,
        private removeNewsGQL: RemoveNewsGQL,
    ) {
        super(router, translate);
        this.modelFilter.page = 1;
    }

    ngOnInit(): void {
        this.modelFilter.start = moment().subtract(30, 'days');
        this.modelFilter.end = moment();

        this.setDatePicker('js-daterangepicker', (start, end, label) => {
            this.modelFilter.start = start.utcOffset(0, true).format();
            this.modelFilter.end = end.utcOffset(0, true).format();
        });

        this.searchNews();

    }

    getImageUrl(image: string): string {
        return `${environment.base_url_image}news/image/${image}`;
    }

    searchNews() {
        this.searchNewsQuery = this.searchNewsGQL.watch({
            input: {
                keyword: this.modelFilter.keyword,
                start: this.modelFilter.start,
                end: this.modelFilter.end,
                page: this.modelFilter.page,
            },
        });

        this.searchNewsQuery.valueChanges.subscribe(({data}) => {
            this.modelNewsResult = data.searchNews as NewsPage;
        });
    }

    handlerRemoveItem(i: string): void {
        this.confirmMessage('Aviso!', 'Tem certeza que deseja remover o notícia?', () => {
                this.removeNewsGQL.mutate({id: i}).subscribe({
                    next: () => {
                        this.searchNewsQuery.refetch({input: this.modelFilter});
                        this.showMessage('Aviso', 'Notícia removida com sucesso', 'success');
                    },
                });
            }
        );
    }

    /*handlerSaveNews(): void {
        const arrValidateFields = [
            {value: this.modelCreateNews.title, text: "Titulo <br>"},
            {value: this.modelCreateNews.text, text: "Descrição <br>"},
        ];

        if (!this.newsChecked()) {
            arrValidateFields.push({value: "", text: "Perfil <br>"});
        }
        if (super.isNullOrUndefined(this.file)) {
            arrValidateFields.push({value: "", text: "Foto <br>"});
        }

        if (this.modelCreateNews.scheduled) {
            if (super.isNullOrUndefined(this.modelCreateNews.start)) {
                arrValidateFields.push({
                    value: "",
                    text: "Data início agendamento <br>",
                });
            }
            if (super.isNullOrUndefined(this.modelCreateNews.end)) {
                arrValidateFields.push({
                    value: "",
                    text: "Data fim agendamento <br>",
                });
            }
        }
        const strError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(strError)) {
            this.showMessage(
                "Atenção",
                `Preencha os campos corretamente: <br><br> ${strError}`,
                "warning"
            );
            return;
        }

        var profileId = "";
        this.profiles
            .filter((f) => f.checked)
            .map((i) => {
                profileId += `${i.id}|`;
            });
        profileId = profileId.slice(0, -1);

        this.createNewsGQL
            .mutate({
                input: {
                    title: this.modelCreateNews.title,
                    highlight: this.modelCreateNews.highlight,
                    scheduled: this.modelCreateNews.scheduled,
                    profile: profileId,
                    text: this.modelCreateNews.text,
                    start: this.modelCreateNews.scheduled ? this.modelCreateNews.start : null,
                    end: this.modelCreateNews.scheduled ? this.modelCreateNews.end : null
                },
            })
            .subscribe({
                next: ({data}) => {
                    const newsId = data.createNews.id;
                    this.newsService.upload(this.file, newsId).subscribe({
                        next: () => {
                            this.showMessage(
                                "Sucesso",
                                "Notícia cadastrado com sucesso!",
                                "success"
                            );
                            this.modelCreateNews = new NewsCreate(false, false);
                            this.closeModal("modalNews");
                            this.toggleLoader(false);
                            this.searchNews();
                        },
                        error: (err) => {
                            const message = JSON.parse(err).message;
                            super.showMessage("Atenção", message, "error");
                            this.toggleLoader(false);
                            this.removeNewsGQL
                                .mutate({
                                    id: newsId,
                                })
                                .subscribe({
                                    next: () => {
                                    },
                                });
                        },
                    });
                },
                error: (err) => {
                    this.toggleLoader(false);
                },
            });
    }*/

    newsChecked(): boolean {
        return this.profiles.find((i) => i.checked);
    }

    handlerSearchNews() {
        this.searchNews();
    }

    pageChanged(event) {
        this.modelFilter.page = event;
        this.searchNews();
    }

    getExcel() {
        this.toggleLoader(true);
        this.newsService.getExcel({
            keyword: this.modelFilter.keyword,
            start: this.modelFilter.start,
            end: this.modelFilter.end,
            page: this.modelFilter.page,
        }).subscribe({
            next: data => {
                this.toggleLoader(false);
                this.downloadHandlerFile(data,'News');
            }, error: err => {
                super.onError(err);
                this.toggleLoader(false);
            }
        });
    }
}
