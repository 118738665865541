import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-access-control-home',
    templateUrl: './access-control-home.component.html',
    styleUrls: ['./access-control-home.component.scss']
})
export class AccessControlHomeComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    UpdateUser(list: number) {

    }

    actionDelete(id: any) {

    }
}
