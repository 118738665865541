<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
  <div class="page-padding">
      <div class="page-header-custom">
          <h1 class="page-title">Usuários - Usuários Excluido </h1>
          <a href="javascript:void('excel')"  class="btn btn-sm btn-info btn-text-white" (click)="getExcel()">
              <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
              Exportar Excel
          </a>
      </div>
      <div class="page-filter">
          <div class="input-material">
              <input id="name" class="form-control" type="text"  required [(ngModel)]="filter.name"/>
              <label for="name">
                  <span>{{'Nome' | translate}}</span>
              </label>
          </div>
          <div class="input-material">
            <input id="fatherCode" class="form-control" type="text" maxlength="100" autocomplete="off" required [(ngModel)]="filter.fatherCode"/>
            <label for="fatherCode" class="mb-0 mr-3">{{'Código Pai'|translate}}</label>
          </div>

          <div class="input-material">
            <input id="document" class="form-control" type="text" autocomplete="off" required
                   [dropSpecialCharacters]="false" mask="000.000.000-00||00.000.000/0000-00" [(ngModel)]="filter.cpfCnPJ"/>
            <label for="cpfcnpj" class="mb-0 mr-3">{{'CPF/CNPJ'|translate}}</label>
          </div>

          <a class="btn btn-primary btn-lg text-white" (click)="searchUserExclued()">Buscar</a>
      </div>
      
      <!--GRID-->
      <div class="table-responsive">
          <table class="table">
        <thead>
          <tr>
          <th scope="col">Código Pai</th>
          <th scope="col">CNPJ</th>
          <th scope="col">Nome</th>
          <th scope="col">CPF</th>
          <th scope="col">Email</th>
          <th scope="col">Telefone</th>
          <th scope="col">Excluído por</th>
          <th scope="col">Data da Validação</th>
          <th scope="col">Data da Exclusão</th>
          <th scope="col">Hora da Exclusão</th>
          <th scope="col">Último acesso</th>

          <th scope="col">Vídeo Outubro</th>
          <th scope="col">Data Vídeo Outubro</th>
          <th scope="col">Hora Vídeo Outubro</th>
          <th scope="col">Quiz Outubro</th>
          <th scope="col">Data Quiz Outubro</th>
          <th scope="col">Hora Quiz Outubro</th>
    
          <th scope="col">Vídeo Novembro</th>
          <th scope="col">Data Vídeo Novembro</th>
          <th scope="col">Hora Vídeo Novembro</th>
          <th scope="col">Quiz Novembro</th>
          <th scope="col">Data Quiz Novembro</th>
          <th scope="col">Hora Quiz Novembro</th>
          

          <th scope="col">Vídeo Dezembro</th>
          <th scope="col">Data Vídeo Dezembro</th>
          <th scope="col">Hora Vídeo Dezembro</th>
          <th scope="col">Quiz Dezembro</th>
          <th scope="col">Data Quiz Dezembro</th>
          <th scope="col">Hora Quiz Dezembro</th>
         

          <th scope="col">Vídeo Janeiro</th>
          <th scope="col">Data Vídeo Janeiro</th>
          <th scope="col">Hora Vídeo Janeiro</th>
          <th scope="col">Quiz Janeiro</th>
          <th scope="col">Data Quiz Janeiro</th>
          <th scope="col">Hora Quiz Janeiro</th>
          

          <th scope="col">Vídeo Fevereiro</th>
          <th scope="col">Data Vídeo Fevereiro</th>
          <th scope="col">Hora Vídeo Fevereiro</th>
          <th scope="col">Quiz Fevereiro</th>
          <th scope="col">Data Quiz Fevereiro</th>
          <th scope="col">Hora Quiz Fevereiro</th>
  
          </tr>
              </thead>
              <tbody>
                <tr *ngIf="isNullOrUndefined(modelUserExcluded.users)">
                  <td colspan="20">
                    <p class="pt-5 pb-5 text-center">
                      Nenhum registro encontrado.
                    </p>
                  </td>
                </tr>
              <tr *ngFor="let item of this.modelUserExcluded.users |
              paginate: {itemsPerPage: modelUserExcluded.PageSize,
              currentPage: modelUserExcluded.currentPage,
              totalItems: modelUserExcluded.totalCount }">
                  
                  <td>{{item.fatherCode}}</td>
                  <td>{{item.cpfCnpj}}</td>
                  <td>{{item.name}}</td>
                  <td>{{item.cpf}}</td>
                  <td>{{item.email}}</td>
                  <td>{{item.phone}}</td>
                  <td>{{item.excludedBy}}</td>
                  <td>{{item.validationDate}}</td>
                  <td>{{item.deletedAt}}</td>
                  <td>{{item.deleteHour}}</td>
                  <td>{{item.lastAccess}}</td>
                 

                  <td>{{item.videoOctober}}</td>
                  <td>{{item.videoOctoberDate}}</td>
                  <td>{{item.videoOctoberHour}}</td>
                  <td>{{item.quizOneOctober}}</td>
                  <td>{{item.quizOneOctoberDate}}</td>
                  <td>{{item.quizOneOctoberHour}}</td>

                 <td>{{item.videoNovember}}</td>
                  <td>{{item.videoNovemberDate}}</td>
                  <td>{{item.videoNovemberHour}}</td>
                  <td>{{item.quizOneNovember}}</td>
                  <td>{{item.quizOneNovemberDate}}</td>
                  <td>{{item.quizOneNovemberHour}}</td>

                  <td>{{item.videoDecember}}</td>
                  <td>{{item.videoDecemberDate}}</td>
                  <td>{{item.videoDecemberHour}}</td>
                  <td>{{item.quizOneDecember}}</td>
                  <td>{{item.quizOneDecemberDate}}</td>
                  <td>{{item.quizOneDecemberHour}}</td>
                  
                  <td>{{item.videoJanuary}}</td>
                  <td>{{item.videoJanuaryDate}}</td>
                  <td>{{item.videoJanuaryHour}}</td>
                  <td>{{item.quizOneJanuary}}</td>
                  <td>{{item.quizOneJanuaryDate}}</td>
                  <td>{{item.quizOneJanuaryHour}}</td>

                  <td>{{item.videoFebruary}}</td>
                  <td>{{item.videoFebruaryDate}}</td>
                  <td>{{item.videoFebruaryHour}}</td>
                  <td>{{item.quizOneFebruary}}</td>
                  <td>{{item.quizOneFebruaryDate}}</td>
                  <td>{{item.quizOneFebruaryHour}}</td>

              
              </tr>
              </tbody>
          </table>
      </div>

      <div class="text-right">
          <pagination-controls
                  [previousLabel]="labels.previousLabel"
                  [nextLabel]="labels.nextLabel"
                  [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                  [screenReaderPageLabel]="labels.screenReaderPageLabel"
                  [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                  (pageChange)="pageChanged($event)"></pagination-controls>
      </div>
  </div>
</div>



