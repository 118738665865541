<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Pontuação ME<!-- | <span>Gestão</span>--></h1>
        </div>

        <div class="page-filter">
            <div class="input-material">
                <input id="father" class="form-control" type="text" [(ngModel)]="filter.fatherCode"/>
                <label for="father">
                    <span>Código Pai</span>
                </label>
            </div>
            <div class="input-material">
                <input id="cpf" class="form-control" type="text" [(ngModel)]="filter.cpf"/>
                <label for="cpf">
                    <span>CPF</span>
                </label>
            </div>
            <div class="input-material">
                <input id="name" class="form-control" type="text" [(ngModel)]="filter.name"/>
                <label for="name">
                    <span>Nome</span>
                </label>
            </div>
                <div class="input-material">
                    <select id="profile" class="form-control" name="type" [(ngModel)]="filter.gmr">
                        <option selected
                                [ngValue]="undefined">{{'select' | translate}}</option>
                        <option *ngFor="let r of modelGrm" [ngValue]="r.id">{{r.name}}</option>
                    </select>
                    <label for="profile" class="mb-0 mr-3">GMR</label>
                </div>            
          
            <div class="input-material">
                <input id="gve" class="form-control" type="text" [(ngModel)]="filter.gve" />
                <label for="gve">
                    <span>GVE</span>
                </label>
            </div>
            <div class="input-material">
                <input id="razao" class="form-control" type="text" [(ngModel)]="filter.company"/>
                <label for="razao">
                    <span>Razão</span>
                </label>
            </div>
            <br>
            <a class="btn btn-primary btn-lg text-white" (click)="search()">Buscar</a>
            <a class="btn btn-info btn-lg text-white" (click)="getExcel()">Exportar Excel</a>
        </div>

        <!--GRID-->
        <div class="table-responsive">
            <table class="table table-striped" border="1">
                <thead>
                <tr>
                    <th class="align-middle" rowspan="2">Perfil</th>
                    <th class="align-middle text-center" rowspan="2">Código Pai</th>
                    <th class="align-middle text-center" rowspan="2">Razão</th>
                    <th class="align-middle text-center" rowspan="2">Nome</th>
                    <th class="align-middle text-center" rowspan="2">CPF</th>
                    <th class="align-middle text-center" rowspan="2">GME</th>
                    <th class="align-middle text-center" rowspan="2">GVE</th>
                    <!-- <th class="align-middle text-center" rowspan="2">Equipe Válida</th> -->
                    <!-- <th class="align-middle text-center" colspan="5">Setembro</th> -->
                    <th class="align-middle text-center" colspan="3">Outubro</th>
                     <th class="align-middle text-center" colspan="3">Novembro</th>
                   <th class="align-middle text-center" colspan="3">Dezembro</th>
                    <th class="align-middle text-center" colspan="3">Janeiro</th>
                    <th class="align-middle text-center" colspan="3">Fevereiro</th>

                    <th class="align-middle text-center" rowspan="2">Total</th>
                    <th class="align-middle text-center" rowspan="2">VALIDADOS</th>
                    <th class="align-middle text-center" rowspan="2">VALIDOU TODOS?</th>
                    <th class="align-middle text-center" rowspan="2">DATA DE VALIDAÇÃO</th>
                </tr>
                <tr>
                    <!-- <th class="align-middle text-center">Equipe Quiz</th>
                    <th class="align-middle text-center">Treinamento</th>
                    <th class="align-middle text-center">Quiz</th>
                    <th class="align-middle text-center">Subtotal</th>
                    <th class="align-middle text-center">Excluídos</th> -->

                    <th class="align-middle text-center">Equipe Quiz</th>
                    <th class="align-middle text-center">Treinamento</th>
                    <th class="align-middle text-center">Quiz</th>
                   
                    <th class="align-middle text-center">Equipe Quiz</th>
                    <th class="align-middle text-center">Treinamento</th>
                    <th class="align-middle text-center">Quiz</th>
                   
                    <th class="align-middle text-center">Equipe Quiz</th>
                    <th class="align-middle text-center">Treinamento</th>
                    <th class="align-middle text-center">Quiz</th>
                   
                    <th class="align-middle text-center">Equipe Quiz</th>
                    <th class="align-middle text-center">Treinamento</th>
                    <th class="align-middle text-center">Quiz</th>
                   
                    <th class="align-middle text-center">Equipe Quiz</th>
                    <th class="align-middle text-center">Treinamento</th>
                    <th class="align-middle text-center">Quiz</th>

                   
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of this.model.technicalVisits | 
                    paginate: {itemsPerPage: this.model.PageSize,
                    currentPage: this.model.currentPage,
                    totalItems: this.model.totalCount }">

                    <td class="align-middle">{{item?.profileId}}</td>
                    <td class="align-middle text-center">{{item?.fatherCode}}</td>
                    <td class="align-middle text-center">{{item?.companyName}}</td>
                    <td class="align-middle text-center">{{item?.name}}</td>
                    <td class="align-middle text-center">{{item?.cpf}}</td>
                    <td class="align-middle text-center">{{item?.gme}}</td>
                    <td class="align-middle text-center">{{item?.gve}}</td>
                    <!-- <td class="align-middle text-center">{{item.teamValid}}</td> -->

                    <!-- <td class="align-middle text-center">{{item.septemberTeamQuiz}}</td>
                    <td class="align-middle text-center">{{item.septemberTraining}}</td>
                    <td class="align-middle text-center">{{item.septemberQuiz}}</td>
                    <td class="align-middle text-center">{{item.septemberSubTotal}}</td>
                    <td class="align-middle text-center">{{item.septemberExcluded}}</td> -->

                    <td class="align-middle text-center">{{item.teamQuizOctober}}</td>
                    <td class="align-middle text-center">{{item.trainingOctober}}</td>
                    <td class="align-middle text-center">{{item.quizOctober}}</td>

                    <td class="align-middle text-center">{{item.teamQuizNovember}}</td>
                    <td class="align-middle text-center">{{item.trainingNovember}}</td>
                    <td class="align-middle text-center">{{item.quizNovember}}</td>

                    <td class="align-middle text-center">{{item.teamQuizDecember}}</td>
                    <td class="align-middle text-center">{{item.trainingDecember}}</td>
                    <td class="align-middle text-center">{{item.quizDecember}}</td>

                    <td class="align-middle text-center">{{item.teamQuizJanuary}}</td>
                    <td class="align-middle text-center">{{item.trainingJanuary}}</td>
                    <td class="align-middle text-center">{{item.quizJanuary}}</td>

                    <td class="align-middle text-center">{{item.teamQuizFebruary}}</td>
                    <td class="align-middle text-center">{{item.trainingFebruary}}</td>
                    <td class="align-middle text-center">{{item.quizFebruary}}</td>
                  

                    <td class="align-middle text-center">{{item.total}}</td>

                    <td class="align-middle text-center">{{item.quantity_valid_employees}}</td>
                    <td class="align-middle text-center">{{item.allValidEmployees}}</td>
                    <td class="align-middle text-center">{{item.updateAt | date:"yyyy-MM-dd" }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>

    </div>
</div>









