<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Usuários Admin <!-- | <span>Gestão</span>--></h1>
            <!--<a href="javascript:void('');" class="btn btn-gray-fill">
                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                Improtar Excel
            </a>-->
            <a href="javascript:void('excel')"  class="btn btn-sm btn-info btn-text-white" (click)="getExcel()">
                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                Exportar Excel
            </a>
            <a href="javascript:void('');" class="btn btn-sm btn-secondary btn-text-white" (click)="onShowCreate()">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                Adicionar
            </a>

        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" class="form-control" type="text"  required/>
                <label for="name">
                    <svg-icon [svgClass]="'input-search'" src="assets/svg/search.svg"></svg-icon>
                    <span>{{'search' | translate}}</span>
                </label>
            </div>
            <div class="input-material">
                <select id="profile" class="form-control" name="type">
                    <option selected
                            [ngValue]="undefined">{{'select' | translate}}</option>
                </select>
                <label for="profile" class="mb-0 mr-3">{{'profile'|translate}}</label>
            </div>
            <div class="input-material select">
                <select id="country" class="form-control" name="type">
                    <option selected [ngValue]="undefined">{{'select' | translate}}</option>
                </select>
                <label for="country" class="mb-0 mr-3 disabled"> {{'country'|translate}}</label>
            </div>
            <a class="btn btn-primary btn-lg text-white">Buscar</a>
        </div>

        <!--GRID-->
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col" class="w-10"></th>
                    <th scope="col">Nome</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Perfil</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of this.modelResult.userAdmin |
                paginate: {itemsPerPage: modelResult.PageSize,
                currentPage: modelResult.currentPage,
                totalItems: modelResult.totalCount }">
                    <td class="text-left">
                        <a (click)="onEdit(item)" class="btn btn-sm btn-info text-white">
                            <svg-icon [svgClass]="'svg-icon-md'" [svgStyle]="{'fill':'#fff'}" src="assets/svg/edit.svg"></svg-icon>
                        </a>
                        <a (click)="onRemove(item.id)" class="btn btn-sm btn-danger text-white ml-2" *ngIf="user.id !== item.id">
                            <svg-icon [svgClass]="'svg-icon-md'" [svgStyle]="{'fill':'#fff'}" src="assets/svg/trash.svg"></svg-icon>
                        </a>
                    </td>
                    <td>{{item.name}}</td>
                    <td>{{item.email}}</td>
                    <td>{{getMangerTypeById(item.profileId.toLowerCase())}}</td>
                    <td>-</td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>

<!--MODAL USER-->
<div class="modal-custom modal fade" id="modalUser" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="modal-header-title">
                    Usuário Detalhe
                </p>
                <a href="javascript:void('');"
                   class="btn "
                   type="button" (click)="closeModal('modalUser')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row my-3">
                    <!--<div class="col-lg-6">
                        <div class="input-material">
                            <select id="profileAdmin" class="form-control" name="type" [(ngModel)]="model.profileId">
                                <option selected
                                        [ngValue]="undefined">Selecione</option>
                                <option [ngValue]="'MA'">Master</option>
                                <option [ngValue]="'AD'">Administrador</option>
                            </select>
                            <label for="profileAdmin">Perfil*</label>
                        </div>
                    </div>-->
                    <div class="col-lg-12">
                        <div class="input-material">
                            <input type="text" class="form-control" autocomplete="off" id="nameProfile"
                                   maxlength="100"
                                   [(ngModel)]="model.name" name="name" required/>
                            <label for="nameProfile">Nome Completo*</label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-material ">
                            <input class="form-control" autocomplete="off" type="text" id="adminProfi" name="adminProfi"
                                   [(ngModel)]="model.email" required/>
                            <label for="adminProfi">E-mail*</label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-material ">
                            <input class="form-control" autocomplete="off" type="text" id="adminPass" name="adminPass"
                                   [(ngModel)]="modelPassword" required/>
                            <label for="adminPass">Senha*</label>
                        </div>
                    </div>
                </div>
                <button class="btn btn-block btn-primary text-white mt-3" (click)="onSave()">{{'save'|translate}}</button>
            </div>

        </div>
    </div>
</div>



