import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { QueryRef } from 'apollo-angular';
import { BaseComponent } from 'src/app/base/base.component';
import { ReportService } from 'src/app/service/report.service';
import { SearchUserExcludedGQL, SearchUserExcludedQuery, SearchUserExcludedQueryVariables, UserExludedInput, UserExludedPage } from 'src/generated/graphql';

@Component({
  selector: 'app-user-excluded',
  templateUrl: './user-excluded.component.html',
  styleUrls: ['./user-excluded.component.scss']
})
export class UserExcludedComponent extends BaseComponent implements OnInit {

  filter: UserExludedInput = new UserExludedInput();
  modelUserExcluded: UserExludedPage = new UserExludedPage();
  currentPage = 1;

  constructor(
    public router: Router,
    public translate: TranslateService,
    private reportService: ReportService,
    private searchUserExcledGQL: SearchUserExcludedGQL
  ) { 
    super(router, translate);
  }

  searchQuery: QueryRef<
  SearchUserExcludedQuery,
  SearchUserExcludedQueryVariables
>;

  ngOnInit() {
    this.search();
  }

  searchUserExclued(): void {
    this.currentPage = 1;
    this.search();
  }

  pageChanged(event:any) : void {
    this.currentPage = event;
    this.search();
  }

  getExcel() {
    this.toggleLoader(true);
    this.reportService.getUserExcludedExcel(this.filter).subscribe({
      next: (data) => {
        this.toggleLoader(false);
        this.downloadHandlerFile(data,'Relatório_Usuários_Excluidos');
      },
      error: (err) => {
        super.onError(err);
        this.toggleLoader(false);
      },
    });
  }

  search(): void {
    this.filter.page = this.currentPage;
    this.searchQuery = this.searchUserExcledGQL.watch({
      input: this.filter,
    });
    this.searchQuery.valueChanges.subscribe({
      next: ({ data }) => {
        this.modelUserExcluded = data.userExcluded as UserExludedPage;
      },
    });
  }

  

}
