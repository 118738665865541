<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <svg-icon [routerLink]="'/news'" src="assets/svg/arrow.svg"></svg-icon>
            <h1 class="page-title">Notícias<!-- | <span>Gestão</span>--></h1>
            <!--<a href="javascript:void('');" class="btn btn-gray-fill">
                      <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                      Improtar Excel
                  </a>-->
        </div>

        <div class="row my-3">
            <!--NAME-->
            <div class="col-6">
                <div class="input-material">
                    <input
                            id="downloadTitle"
                            class="form-control"
                            type="text"
                            required
                            maxlength="200"
                            [(ngModel)]="modelNew.title"
                    />
                    <label for="downloadTitle">Título*</label>
                </div>
            </div>
            <!-- HIGHLIGTH DROPDOWN -->
            <div class="col-4">
                <div class="input-material flex-grow-1">
                    <select
                            id="profileUser"
                            class="form-control"
                            name="type"
                            [(ngModel)]="modelNew.highlight"
                    >
                        <option selected [ngValue]="true">Sim</option>
                        <option [ngValue]="false">Não</option>
                    </select>
                    <label for="profileUser" class="mb-0 mr-3">Destaque</label>
                </div>
            </div>

            <div class="col-12">
                <div class="card p-3 shadow-none mb-4">
                    <label>Perfis de Acesso</label>
                    <div class="row mx-0">
                        <div class="col-auto px-2 mb-2" *ngFor="let item of this.profiles">
                            <div class="custom-control custom-checkbox mb-0">
                                <input type="checkbox" class="custom-control-input" [(ngModel)]="item.checked"
                                       [id]="item.id"
                                       [disabled]="item.id === 'MA'">
                                <label class="custom-control-label" [for]="item.id">{{item.name}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="card p-3 shadow-none mb-4">
                    <label class="row mx-0 mb-0">Agendar Notícia
                        <div class="custom-control custom-switch ml-3 mb-0">
                            <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch1"
                                    [(ngModel)]="modelNew.scheduled"
                            />
                            <label class="custom-control-label" for="customSwitch1"></label>
                        </div>
                    </label>
                    <div class="row mt-3" [ngClass]="{'d-none' : !modelNew.scheduled}">
                        <div class="col-6">
                            <div class="input-material">
                                <input
                                        id="start"
                                        class="form-control js-dateStart"
                                        type="text"
                                        autocomplete="off"
                                        maxlength="10"
                                        mask="00/00/0000"
                                        required
                                />
                                <label for="start">Inicio</label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-material">
                                <input
                                        id="end"
                                        class="form-control js-dateEnd"
                                        type="text"
                                        autocomplete="off"
                                        maxlength="10"
                                        mask="00/00/0000"
                                        required
                                />
                                <label for="end">Fim</label>
                            </div>

                        </div>
                    </div>
                </div>

                <!--<div (click)="showModal('modalCropNews')"
                     class="col-4 mt-3 d-flex justify-content-center align-items-center flex-column">
                    <div *ngIf="isNullOrUndefined(croppedImage) && isNullOrUndefined(modelNew.pathImage)" class="d-flex justify-content-center align-items-center flex-column">
                        <b class="text-primary" href="#">Adicionar Foto</b>
                        <svg-icon class="svg-icon-primary" src="assets/svg/image.svg"></svg-icon>
                    </div>
                    <img *ngIf="!isNullOrUndefined(modelNew.pathImage) || !isNullOrUndefined(croppedImage)"
                         src="{{!croppedImage ? getImageUrl(modelNew.pathImage) : croppedImage}}" alt="Banner" />
                </div>-->
            </div>


            <div class="col-12">
                <div class="card p-3 shadow-none mb-4">
                    <label class="row mx-0 mb-0">Imagem</label>
                    <div class="row mt-3">
                        <div (click)="showModal('modalCropNews')" class="col-12 mt-3 d-flex justify-content-center align-items-center flex-column">
                            <div *ngIf="isNullOrUndefined(croppedImage) && isNullOrUndefined(modelNew.pathImage)" class="d-flex justify-content-center align-items-center flex-column">
                                <b class="text-primary" href="#">Adicionar Foto</b>
                                <svg-icon class="svg-icon-primary" src="assets/svg/image.svg"></svg-icon>
                            </div>
                            <img *ngIf="!isNullOrUndefined(modelNew.pathImage) || !isNullOrUndefined(croppedImage)"
                                 src="{{!croppedImage ? getImageUrl(modelNew.pathImage) : croppedImage}}" alt="Banner" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="text-left mb-2">
                    <p class="text-muted size-md"><strong>Descrição</strong></p>
                </div>
                <!--<textarea
                        class="input-material mb-0"
                        id="description"
                        style="width: inherit; resize: none"
                        rows="4"
                        required
                        [(ngModel)]="modelNew.text"
                ></textarea>-->
                <ckeditor [(ngModel)]="modelNew.text" [editor]="Editor" [config]="optionsNews"></ckeditor>
            </div>
        </div>
        <button
                class="btn btn-block btn-primary text-white mt-3"
                (click)="handlerSaveNews()"
        >
            Salvar
        </button>
    </div>
</div>
<app-crop-image
        [cropId]="'modalCropNews'"
        [cropAspectRatio]="32/4"
        [cropResizeToWidth]="1920"
        [cropResizeToHeight]="240"
        [cropMaintainAspectRatio]="true"
        (croppedImageResult)="setCroppedImage($event)"
></app-crop-image>
