<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
  <div class="page-padding">
      <div class="page-header-custom">
          <h1 class="page-title">Usuários - Revenda </h1>
          <a href="javascript:void('excel')"  class="btn btn-sm btn-info btn-text-white" (click)="getExcel()">
              <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
              Exportar Excel
          </a>
      </div>
      <div class="page-filter">
          <div class="input-material">
              <input id="name" class="form-control" type="text"  required [(ngModel)]="filter.name"/>
              <label for="name">
                  <span>{{'Nome' | translate}}</span>
              </label>
          </div>
          <div class="input-material">
            <input id="document" class="form-control" type="text" autocomplete="off" required
                   [dropSpecialCharacters]="false" mask="000.000.000-00||00.000.000/0000-00" [(ngModel)]="filter.cpfCnPJ"/>
            <label for="document" class="mb-0 mr-3">{{'CPF/CNPJ'|translate}}</label>
          </div>

          <div class="input-material">
            <input id="razao" class="form-control" type="text" maxlength="100" autocomplete="off" required [(ngModel)]="filter.companyName"/>
            <label for="razao" class="mb-0 mr-3">{{'Razão Social'|translate}}</label>
          </div>

          <div class="input-material">
            <select id="range" class="form-control" name="type" [(ngModel)]="filter.range">
              <option selected [ngValue]="undefined">Todas</option>
              <option *ngFor="let r of modelRange" [ngValue]="r.id">{{r.name}}</option>
            </select>
            <label for="range" class="mb-0 mr-3">{{'Faixa'|translate}}</label>
          </div>
      </div>
      <div class="page-filter">
        <div class="input-material">
          <select id="profile" class="form-control" name="type" [(ngModel)]="filter.isActive">
            <option selected [ngValue]="undefined">Todos</option>
            <option [ngValue]="'true'">Sim</option>
            <option [ngValue]="'false'">Não</option>
          </select>
          <label for="profile" class="mb-0 mr-3">{{'Ativo'|translate}}</label>
        </div>
        <div class="input-material">
          <select id="gme" class="form-control" name="type" [(ngModel)]="filter.gmr">
            <option selected [ngValue]="undefined">Todos</option>
            <option *ngFor="let g of modelGrm" [ngValue]="g.id">{{g.name}}</option>
          </select>
          <label for="gme" class="mb-0 mr-3">{{'GMR'|translate}}</label>
        </div>
        <div class="input-material">
          <input id="gve" class="form-control" type="text" maxlength="100" required [(ngModel)]="filter.gve"/>
          <label for="gve" class="mb-0 mr-3">{{'GVE'|translate}}</label>
        </div>
        <div class="input-material">
          <input id="cod" class="form-control" type="text" maxlength="50" required [(ngModel)]="filter.fatherCode"/>
          <label for="cod" class="mb-0 mr-3">{{'Código Pai'|translate}}</label>
        </div>
        <a class="btn btn-primary btn-lg text-white" (click)="searchUsersReseller()">Buscar</a>
      </div>

      <!--GRID-->
      <div class="table-responsive">
          <table class="table">
              <thead>
                <tr>
                  <th scope="col">Resetar Senha</th>
                  <th scope="col">Código Pai</th>
                  <th scope="col">GMR</th>
                  <th scope="col">GVE</th>
                  <th scope="col">Faixa</th>
                  <th scope="col">CNPJ</th>
                  <th scope="col">Nome</th>
                  <th scope="col">CPF</th>
                  <th scope="col">Data Nascimento</th>
                   <th scope="col">Email</th>
                  <th scope="col">Telefone</th>
                  <th scope="col">Data da Alteração</th>
                  <th scope="col">Hora da Alteração</th>
                  <th scope="col">Ativo</th>
                  <th scope="col">QTDE Acessos</th>
                  <th scope="col">QTDE Funcionários</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="isNullOrUndefined(modelUserReseller.users)">
                  <td colspan="20">
                    <p class="pt-5 pb-5 text-center">
                      Nenhum registro encontrado.
                    </p>
                  </td>
                </tr>
              <tr *ngFor="let item of  this.modelUserReseller.users |
              paginate: {itemsPerPage: modelUserReseller.PageSize,
              currentPage: modelUserReseller.currentPage,
              totalItems: modelUserReseller.totalCount }">
                  <td class="text-left">
                      <a  class="btn btn-sm btn-info text-white" href="javascript:void('')" (click)="onResetPasswordUser(item.id)">
                          <svg-icon [svgClass]="'svg-icon-md'" [svgStyle]="{'fill':'#fff'}" src="assets/svg/key.svg"></svg-icon>
                      </a>
                  </td>
                  <td>{{item.fatherCode}}</td>
                  <td>{{item.gmr}}</td>
                  <td>{{item.gve}}</td>
                  <td>{{item.range}}</td>
                  <td>{{item.cpfCnpj}}</td>
                  <td>{{item.name}}</td>
                  <td>{{item.cpf}}</td>
                  <td>{{item.birthDate}}</td>
                   <td>{{item.email}}</td>
                  <td>{{item.phone}}</td>
                  <td>{{item.updateDate}}</td>
                  <td>{{item.updateHour}}</td>
                  <td>{{item.active}}</td>
                  <td>{{item.quantityAccess}}</td>
                  <td>{{item.quantityEmployess}}</td>
              </tr>
              </tbody>
          </table>
      </div>

      <div class="text-right">
          <pagination-controls
                  [previousLabel]="labels.previousLabel"
                  [nextLabel]="labels.nextLabel"
                  [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                  [screenReaderPageLabel]="labels.screenReaderPageLabel"
                  [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                  (pageChange)="pageChanged($event)"></pagination-controls>
      </div>
  </div>
</div>



