import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base.component';
import { TranslateService } from "@ngx-translate/core";
import { GmrAdmin, GmrFindAllGQL, GmrFindAllQuery, GmrFindAllQueryVariables, ResaleJobRole, ResaleJobRolesGQL, ResaleJobRolesQuery, ResaleJobRolesQueryVariables, SearchUserResellerTeamGQL, SearchUserResellerTeamQuery, SearchUserResellerTeamQueryVariables, UserResetPasswordGQL, UsersResellerTeamInput, UsersReselleTeamPage } from 'src/generated/graphql';
import { QueryRef } from 'apollo-angular';
import { ReportService } from 'src/app/service/report.service';

@Component({
  selector: 'app-user-reseller-team',
  templateUrl: './user-reseller-team.component.html',
  styleUrls: ['./user-reseller-team.component.scss']
})
export class UserResellerTeamComponent extends BaseComponent implements OnInit {

  modelUserResellerTeam: UsersReselleTeamPage = new UsersReselleTeamPage();
  filter: UsersResellerTeamInput = new UsersResellerTeamInput();
  modelResaleJobRole: ResaleJobRole [] = [];
  modelGrm: GmrAdmin[] = [];
  currentPage = 1;

  constructor(
    public router: Router,
    public translate: TranslateService,
    private resaleJobGQL: ResaleJobRolesGQL,
    private findAllGmrGQL: GmrFindAllGQL,
    private userResetPasswordGQL: UserResetPasswordGQL,
    private searchUserResellerTeamGQL: SearchUserResellerTeamGQL,
    private reportService: ReportService,
    
  ) { 
    super(router, translate);
  }

  resaleJobQuery: QueryRef<ResaleJobRolesQuery, ResaleJobRolesQueryVariables>;
  grmQuery: QueryRef<GmrFindAllQuery, GmrFindAllQueryVariables>;
  searchQuery: QueryRef<
    SearchUserResellerTeamQuery,
    SearchUserResellerTeamQueryVariables
  >;

  ngOnInit() {
    this.findAllResaleJob();
    this.findAllGmr();
    this.search();
  }

  pageChanged(event: any): void {
    this.currentPage = event;
    this.search();
  }

  private findAllResaleJob(): void {
    this.resaleJobQuery = this.resaleJobGQL.watch();
    this.resaleJobQuery.valueChanges.subscribe({
      next: ({data}) => {
        this.modelResaleJobRole = data.resaleJobRoles as ResaleJobRole[]
      }
    })
  }

  getExcel() {
    this.toggleLoader(true);
    this.reportService.getResellerTeamExcel(this.filter).subscribe({
      next: (data) => {
        this.toggleLoader(false);
        this.downloadHandlerFile(data,'Relatório_Equipe_Revendas');
      },
      error: (err) => {
        super.onError(err);
        this.toggleLoader(false);
      },
    });
  }

  private findAllGmr(): void {
    this.grmQuery = this.findAllGmrGQL.watch();
    this.grmQuery.valueChanges.subscribe({
      next: ({ data }) => {
        this.modelGrm = data.gmrFindAll as GmrAdmin[];
      },
    });
  }

  searchUsersResellerTeam(): void {
    this.currentPage = 1;
    this.search();
  }

  search(): void {
    this.filter.page = this.currentPage;
    this.searchQuery = this.searchUserResellerTeamGQL.watch({
      input: this.filter,
    });
    this.searchQuery.valueChanges.subscribe({
      next: ({ data }) => {
        this.modelUserResellerTeam = data.userResellerTeam as UsersReselleTeamPage;
      },
    });
  }

  onResetPasswordUser(i: string): void {
    this.confirmMessage('Aviso!', 'Tem certeza que deseja resetar a senha?', () => {
      this.userResetPasswordGQL.mutate({id: i}).subscribe({
        next: ({data}) => {
          this.showMessage('Sucesso', 'Senha resetada com sucesso!', 'success');
        }
      });
    });
  }
}
