<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Usuários<!-- | <span>Gestão</span>--></h1>
            <!--<a href="javascript:void('');" class="btn btn-gray-fill">
                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                Improtar Excel
            </a>-->

            <a href="javascript:void('');" class="btn btn-secondary btn-text-white" >
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                Adicionar
            </a>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" class="form-control" type="text" required/>
                <label for="name">
                    <svg-icon [svgClass]="'input-search'" src="assets/svg/search.svg"></svg-icon>
                    <span>{{'search' | translate}}</span>
                </label>
            </div>
            <div class="input-material">
                <select id="profile" class="form-control" name="type">
                    <option selected
                            [ngValue]="undefined">{{'select' | translate}}</option>
                </select>
                <label for="profile" class="mb-0 mr-3">{{'profile'|translate}}</label>
            </div>
            <a class="btn btn-primary btn-lg text-white" >Buscar</a>

            <a href="javascript:void('');" class="btn btn-info btn-lg text-white">
                Exportar Excel
            </a>
        </div>

        <!--GRID-->
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <!--                <th scope="col">ID</th>-->
                    <th scope="col">{{'usuario'|translate}}</th>
                    <th scope="col">Status</th>
                    <th scope="col">{{'perfil'|translate}}</th>
                    <th scope="col">E-mail</th>
                    <!--                <th scope="col">{{'unidade'|translate}}</th>-->
                    <th scope="col" class="text-center">{{'pais'|translate}}</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <!-- <tr *ngIf="users.length === 0">
                    <td colspan="7">
                        <p class="text-center py-5">{{'nenhumresultado'|translate}}</p>
                    </td>
                </tr> -->
                <!-- <tr *ngFor="let x of users">
                    <td>{{x.name}}</td>
                    <td>{{!x.status ? '' : getTranslate(x.status)}}</td>
                    <td>{{getMangerTypeByKey(x.profile)}}</td>
                    <td>{{x.email}}</td>
                    <td class="d-flex flex-column align-items-center justify-content-center">
                        <img src="" width="25">País

                    </td>
                    <td>
                        <button>
                            <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                        </button>
                        <button>
                            <svg-icon src="assets/svg/delete.svg"></svg-icon>
                        </button>
                    </td>
                </tr> -->
                </tbody>
            </table>
        </div>

    </div>
</div>

<!--MODAL USER-->
<div class="modal-custom modal fade" id="modalUser" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="modal-header-title">
                    {{'user'|translate}}
                </p>
                <a href="javascript:void('');"
                   class="btn "
                   type="button" (click)="closeModal('modalUser')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row my-3">
                    <!--NAME-->
                    <div class="col-4">
                        <div class="input-material">
                            <!-- <input id="nameUser" class="form-control" type="text"
                                   required [(ngModel)]="model.name" maxlength="150"/> -->
                            <label for="nameUser">  {{'name'|translate}}</label>
                        </div>
                    </div>
                    <!--RE-->
                    <div class="col-4">
                        <div class="input-material">
                            <input id="reUser" class="form-control" type="text"
                                   required maxlength="15"/>
                            <label for="reUser">RE</label>
                        </div>
                    </div>
                    <!-- PROFILE DROPDOWN -->
                    <div class="col-4">
                        <div class="input-material flex-grow-1">
                            <select id="profileUser" class="form-control" name="type">
                                <option selected
                                        [ngValue]="null">{{'select' | translate}}</option>
                            </select>
                            <label for="profileUser" class="mb-0 mr-3">  {{'profile' |translate}}</label>
                        </div>
                    </div>
                    <!--EMAIL-->
                    <div class="col-6 mt-3">
                        <div class="input-material">
                            <input id="userEmail" class="form-control" type="text"
                                   required  maxlength="150"/>
                            <label for="userEmail">E-mail</label>
                        </div>
                    </div>
                    <!-- COUNTRY DROPDOWN -->
                    <div class="col-6 mt-3">
                        <div class="input-material flex-grow-1">
                            <select id="countryUser" class="form-control" name="type" >

                                <option selected
                                        [ngValue]="null">{{'select' | translate}}</option>

                                <!-- <option *ngFor="let c of countries | async" [ngValue]="c.id">{{c.name}}</option> -->


                            </select>
                            <label for="countryUser" class="mb-0 mr-3">  {{'country'|translate}}</label>
                        </div>
                    </div>
                    <!-- UNITY DROPDOWN -->
                    <!--                        <div class="col-4 mt-3">-->
                    <!--                            <div class="input-material flex-grow-1">-->
                    <!--                                <select id="unityUser" class="form-control" name="type" [(ngModel)]="userUnity">-->
                    <!--                                    <option selected-->
                    <!--                                            [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>-->
                    <!--                                    <option *ngFor="let u of unities | async" [ngValue]="u.id">{{u.name}}</option>-->

                    <!--                                </select>-->
                    <!--                                <label for="unityUser" class="mb-0 mr-3">  {{'unidade'|translate}}</label>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                </div>
                <button class="btn btn-block btn-warning text-white mt-3">  {{'save'|translate}}</button>
            </div>

        </div>
    </div>
</div>



