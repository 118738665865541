import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {LogoutComponent} from './views/logout/logout.component';
import {HomeComponent} from './views/home/home.component';
import {UserComponent} from './views/user/user.component';
import {AuthGuard} from './service/auth-guard.service';
import {LoginComponent} from './views/login/login.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {AccessControlComponent} from './views/access-control/access-control.component';
import {AccessControlHomeComponent} from './views/access-control/home/access-control-home.component';
import {BannerComponent} from './views/banner/banner.component';
import {DownloadsComponent} from './views/downloads/downloads.component';
import {NewsComponent} from './views/news/news.component';
import {TechinicalVisitComponent} from './views/techinical-visit/techinical-visit.component';
import {ContactComponent} from './views/contact/contact.component';
import {AdminComponent} from './views/admin/admin.component';
import {TechinicalVisitDetailComponent} from './views/techinical-visit/detail/techinical-visit-detail.component';
import {ReportComponent} from './views/report/report.component';
import {NewsDetailComponent} from "./views/news/detail/news-detail.component";
import {DownloadsDetailComponent} from "./views/downloads/detail/downloads-detail.component";
import {BannerDetailComponent} from "./views/banner/detail/banner-detail.component";
import {UserResellerComponent} from './views/user-reseller/user-reseller.component';
import {UserResellerTeamComponent} from './views/user-reseller-team/user-reseller-team.component';
import {UserSalesTeamComponent} from './views/user-sales-team/user-sales-team.component';
import {UserExcludedComponent} from './views/user-excluded/user-excluded.component';
import {MechanicComponent} from "./views/mechanic/mechanic.component";
import { ReportTrainingComponent } from './views/report/report-training/report-training.component';
import { ReportQuizComponent } from './views/report/report-quiz/report-quiz.component';
import { ReportCouponComponent } from './views/report/report-coupon/report-coupon.component';


const routes: Routes = [
    {
        path: 'login', component: LoginComponent
    },
    {
        path: 'logout', component: LogoutComponent
    },
    {
        path: '', component: MainComponent,
        children: [
            {path: 'dashboard', component: DashboardComponent},
            {path: 'user', component: UserComponent},
            {
                path: 'access-control', component: AccessControlComponent, children: [
                    {
                        path: '', component: AccessControlHomeComponent
                    },
                ]
            },
            {
                path: 'banner', component: BannerComponent
            },
            {
                path: 'banner-detail/:id', component: BannerDetailComponent
            },
            {
                path: 'banner-detail', component: BannerDetailComponent
            },
            {
                path: 'downloads', component: DownloadsComponent
            },
            {
                path: 'downloads-detail/:id', component: DownloadsDetailComponent
            },
            {
                path: 'downloads-detail', component: DownloadsDetailComponent
            },
            {
                path: 'news', component: NewsComponent
            },
            {
                path: 'news-detail/:id', component: NewsDetailComponent
            },
            {
                path: 'news-detail', component: NewsDetailComponent
            },
            {
                path: 'contact', component: ContactComponent
            },
            {
                path: 'admin', component: AdminComponent
            },
            {
                path: 'visita-detalhe/:id', component: TechinicalVisitDetailComponent
            },
            {
                path: 'padronizacao/:id', component: TechinicalVisitDetailComponent
            },
            {
                path: 'visita', component: TechinicalVisitComponent
            },
            {
                path: 'report', component: ReportComponent
            },
            {
                path: 'mecanica', component: MechanicComponent
            },
            {path: 'home', component: HomeComponent},
            {path: '', component: HomeComponent},
            {path: 'user-reseller', component: UserResellerComponent},
            {path: 'user-reseller-team', component: UserResellerTeamComponent},
            {path: 'user-sales-team', component: UserSalesTeamComponent},
            {path: 'user-excluded', component: UserExcludedComponent},
            {path: 'relatorio-treinamento', component: ReportTrainingComponent},
            {path: 'relatorio-quiz', component: ReportQuizComponent},
            {path: 'relatorio-cupom', component: ReportCouponComponent},


        ],
        canActivate: [AuthGuard]
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
