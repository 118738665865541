import {AfterContentInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {
    BannerPage,
    CreateUserAdminGQL,
    CreateUserAdminInput,
    RemoveUserAdminGQL,
    SearchBannerGQL,
    SearchBannerInput,
    SearchBannerQuery,
    SearchBannerQueryVariables,
    SearchUserAdminGQL,
    SearchUserAdminInput,
    SearchUserAdminQuery,
    SearchUserAdminQueryVariables,
    UpdateUserAdminGQL,
    UpdateUserAdminInput,
    User,
    UserAdmin,
    UserAdminPage
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { ReportService } from 'src/app/service/report.service';

declare var $: any;

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
})
export class AdminComponent
    extends BaseComponent
    implements AfterContentInit, OnDestroy {

    modelPassword = '';
    user = new UserAdmin();
    model = new UserAdmin();
    modelFilter: SearchUserAdminInput = new SearchUserAdminInput();

    currentPage = 1;
    labels: any = {
        previousLabel: '',
        nextLabel: '',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    searchQuery: QueryRef<SearchUserAdminQuery, SearchUserAdminQueryVariables>;
    modelResult: UserAdminPage = new UserAdminPage();

    constructor(
        public router: Router,
        public translate: TranslateService,
        public userService: UserService,
        private searchUserAdminGQL: SearchUserAdminGQL,
        private createUserAdminGQL: CreateUserAdminGQL,
        private updateUserAdminGQL: UpdateUserAdminGQL,
        private removeUserAdminGQL: RemoveUserAdminGQL,
        private reportService: ReportService
    ) {
        super(router, translate);
    }

    ngAfterContentInit() {
        this.userService.user.subscribe({
            next: (data) => {
                this.user = data as UserAdmin;
            },
            error: (err) => super.onError(err),
        });

        this.setDatePicker('picker-period', (start, end, label) => {
            // this.modelFilter.start = start.utcOffset(0, true).format();
            // this.modelFilter.end = end.utcOffset(0, true).format();
        });

        this.search();
    }

    ngOnDestroy(): void {
        const elModalUser = $('#modalUser');
        if (elModalUser[0]) {
            elModalUser.remove();
        }
    }

    search(): void {
        this.searchQuery = this.searchUserAdminGQL.watch({
            input: {
                name: this.modelFilter.name,
                email: this.modelFilter.email,
                profile: this.modelFilter.profile,
                page: this.currentPage,
            },
        });

        this.searchQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.modelResult = data.searchUserAdmin as UserAdminPage;
            },
            error: (err) => {
                super.onError(err);
            },
        });
    }

    pageChanged(event) {
        this.currentPage = event;
        this.search();
    }

 
    onSave() {
        const arrValidateFields: any = [
            {value: this.model.name, text: `Nome Completo <br>`},
            {value: this.model.email, text: `E-mail <br>`},
            {value: this.modelPassword, text: `Senha <br>`}
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos obrigatórios corretamente: <br> ${stringError}`);
            return;
        }
        if (!super.isEMailValid(this.model.email)) {
            this.showMessage('Atenção', 'Informe um e-mail válido para prosseguir.', 'warning');
            return;
        }
        if (this.isNullOrUndefined(this.model.id)) {
            const a: CreateUserAdminInput = {
                name: this.model.name,
                email: this.model.email,
                password: this.modelPassword,
                profile: 'AD'
            };
            this.onCreate(a);
        } else {
            const a: UpdateUserAdminInput = {
                id: this.model.id,
                name: this.model.name,
                email: this.model.email,
                password: this.modelPassword,
                profile: 'AD'
            };
            this.onUpdate(a);
        }
    }

    onCreate(a: any) {
        this.createUserAdminGQL
            .mutate({
                input: a,
            })
            .subscribe({next: ({data}) => {
                this.modelPassword = '';
                this.closeModal('modalUser');
                this.showMessage('Sucesso', 'Usuário cadastrado com sucesso!', 'success');
            }
        });
    }

    onUpdate(a: any) {
        this.updateUserAdminGQL
            .mutate({
                input: a,
            })
            .subscribe({next: ({data}) => {
                this.modelPassword = '';
                this.closeModal('modalUser');
                this.showMessage('Sucesso', 'Usuário atualizado com sucesso!', 'success');
            }
        });
    }

    onEdit(x: any) {
        console.log('actionUpdate');
        Object.assign(this.model, x);
        super.showModal('modalUser');
    }

    onRemove(i: string) {
        super.confirmMessage('Atenção', 'Deseja realmente remover o usuário?', () => {
            this.removeUserAdminGQL.mutate({id: i}).subscribe({
                next: () => {
                    this.search();
                    this.showMessage('Sucesso', 'Usuário removido com sucesso', 'success');
                },
            });
        });
    }

    onShowCreate() {
        this.modelPassword = '';
        this.model = new UserAdmin();
        this.showModal('modalUser');
    }

    getExcel() {
        this.toggleLoader(true);
        this.reportService.getUserAdminExcel(this.modelFilter).subscribe({
          next: (data) => {
            this.toggleLoader(false);
            this.downloadHandlerFile(data,'Admin');
          },
          error: (err) => {
            super.onError(err);
            this.toggleLoader(false);
          },
        });
      }
}
