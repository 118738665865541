import {Component, Input, OnInit} from "@angular/core";
import {BaseComponent} from "../../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {DashboardService} from "../../../service/dashboard.service";
import {UserService} from "../../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {
    Banner,
    CreateBannerGQL, CreateBannerInput,
    GetBannerByIdGQL,
    GetBannerByIdQuery,
    GetBannerByIdQueryVariables,
    GetProfilesGQL,
    GetProfilesQuery,
    GetProfilesQueryVariables,
    Profile, RemoveBannerGQL,
    UpdateBannerGQL, UpdateBannerInput,
} from "src/generated/graphql";
import {QueryRef} from "apollo-angular";
import {environment} from "src/environments/environment";
import {BannerService} from "src/app/service/banner.service";

declare var $: any;
declare var moment: any;

@Component({
    selector: "app-banner-detail",
    templateUrl: "./banner-detail.component.html",
    styleUrls: ["./banner-detail.component.scss"],
})
export class BannerDetailComponent extends BaseComponent implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public dashboardService: DashboardService,
        public userService: UserService,
        public translate: TranslateService,
        private getBannerByIdGQL: GetBannerByIdGQL,
        private getProfilesGQL: GetProfilesGQL,
        private bannerService: BannerService,
        private removeBannerGQL: RemoveBannerGQL,
        private createBannerGQL: CreateBannerGQL,
        private updateBannerGQL: UpdateBannerGQL
    ) {
        super(router, translate);
    }

    profileQuery: QueryRef<GetProfilesQuery, GetProfilesQueryVariables>;
    profiles: Array<any> = [];

    file: File = null;
    thumpPath: string | ArrayBuffer;
    croppedImage: any = null;

    bannerQuery: QueryRef<GetBannerByIdQuery, GetBannerByIdQueryVariables>;

    modelBanner: Banner = new Banner();

    id: string = '';
    title: string;

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((param) => {
            this.id = param.id;
            this.setDate('js-dateStart', (start, end, label) => {
                this.modelBanner.start = start.utcOffset(0, true).format();
            });
            this.setDate('js-dateEnd', (start, end, label) => {
                this.modelBanner.end = start.utcOffset(0, true).format();
            }, moment().add('days', 30));
            if (this.id !== 'new') {
                this.getBannerById(param.id);
            } else {
                this.modelBanner.highlight = false;
                this.getProfiles();
            }
        });
    }

    getBannerById(bannerId: string): void {
        this.bannerQuery = this.getBannerByIdGQL.watch({input: bannerId});
        this.bannerQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.modelBanner = data.banner as Banner;
                const listProfile = this.listProfile(this.modelBanner.profile);
                this.getProfiles(listProfile);
                if (this.modelBanner.scheduled) {
                    // console.log('aplicar data do modelo nos datepicker star end');
                    this.setDate('js-dateStart', (start, end, label) => {
                        this.modelBanner.start = start.utcOffset(0, true).format();
                    }, this.modelBanner.start.toString());
                    this.setDate('js-dateEnd', (start, end, label) => {
                        this.modelBanner.end = start.utcOffset(0, true).format();
                    }, this.modelBanner.end.toString());
                }
            },
        });
    }

    setCroppedImage(o: any) {
        this.croppedImage = o.thumb;
        this.file = o.file[0];
    }

    getImageUrl(image: string): string {
        return `${environment.base_url_image}banner/image/${image}`;
    }

    getProfiles(profile?: string[]): void {
        this.profiles = [];
        this.profileQuery = this.getProfilesGQL.watch();
        this.profileQuery.valueChanges.subscribe(({data}) => {
            const profiles = data.profiles as Array<Profile>;
            profiles.map((item) => {
                const c = !profile ? false : profile.filter((i) => i === item.id).length > 0;
                this.profiles.push({
                    id: item.id,
                    name: item.name,
                    checked: this.isNullOrUndefined(this.modelBanner.id) && item.id === 'MA' ? true : c,
                });
            });
            console.log(this.profiles);
        });
    }

    listProfile(profile: string): string[] {
        return profile.split('|');
    }

    handlerSaveBanner(): void {
        const arrValidateFields = [
            {value: this.modelBanner.title, text: 'Titulo <br>'},
            {value: this.modelBanner.position, text: 'Ordem <br>'},
        ];

        if (!this.bannerChecked()) {
            arrValidateFields.push({value: '', text: 'Perfil <br>'});
        }

        const strError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(strError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${strError}`, 'warning');
            return;
        }
        let profileId = '';
        this.profiles
            .filter((f) => f.checked)
            .map((i) => {
                profileId += `${i.id}|`;
            });
        profileId = profileId.slice(0, -1);

        if (this.modelBanner.scheduled && this.isNullOrUndefined(this.modelBanner.start)) {
            this.modelBanner.start = moment().utcOffset(0, true).format();
        }
        if (this.modelBanner.scheduled && this.isNullOrUndefined(this.modelBanner.end)) {
            this.modelBanner.end = moment().add('days', 30).utcOffset(0, true).format();
        }

        if (this.id !== 'new') {
            const a: UpdateBannerInput = {
                id: this.id,
                position: this.modelBanner.position,
                title: this.modelBanner.title,
                highlight: this.modelBanner.highlight,
                scheduled: this.modelBanner.scheduled,
                profile: profileId,
                pathImage: this.modelBanner.pathImage,
                pathLink: this.modelBanner.pathLink,
                start: this.modelBanner.scheduled ? this.modelBanner.start : null,
                end: this.modelBanner.scheduled ? this.modelBanner.end : null
            };
            this.actionUpdateData(a);
        } else {
            if (this.isNullOrUndefined(this.file)) {
                this.showMessage('Atenção', `Adicione uma imagem para o banner`, 'warning');
                return;
            }
            const a: CreateBannerInput = {
                title: this.modelBanner.title,
                position: this.modelBanner.position,
                highlight: this.modelBanner.highlight,
                scheduled: this.modelBanner.scheduled ? this.modelBanner.scheduled : false,
                profile: profileId,
                start: this.modelBanner.scheduled ? this.modelBanner.start : null,
                end: this.modelBanner.scheduled ? this.modelBanner.end : null
            };
            this.actionCreateData(a);
        }

    }

    actionCreateData(a: any) {
        this.createBannerGQL
            .mutate({
                input: a,
            })
            .subscribe({
                next: ({data}) => {
                    const bannerId = data.createBanner.id;
                    this.bannerService.upload(this.file, bannerId).subscribe({
                        next: (data2) => {
                            this.showMessage('Sucesso', 'Banner cadastrado com sucesso!', 'success');
                            this.toggleLoader(false);
                            setTimeout(() => {
                                this.router.navigate(['/banner']);
                            }, 100);
                        },
                        error: (err) => {
                            const message = JSON.parse(err).message;
                            super.showMessage('Atenção', message, 'error');
                            this.toggleLoader(false);
                            this.removeBannerGQL
                                .mutate({
                                    id: bannerId,
                                })
                                .subscribe({
                                    next: () => {
                                    },
                                });
                        },
                    });
                },
                error: (err) => {
                    this.toggleLoader(false);
                },
            });
    }

    actionUpdateData(a: any) {
        this.updateBannerGQL
            .mutate({
                input: a,
            })
            .subscribe({
                next: () => {
                    if (this.file != null) {
                        this.bannerService.upload(this.file, this.id).subscribe({
                            next: () => {
                                this.message();
                                setTimeout(() => {
                                    this.router.navigate(['/banner']);
                                }, 100);
                            },
                            error: (err) => {
                                const message = JSON.parse(err).message;
                                super.showMessage('Atenção', message, 'error');
                            },
                        });
                    } else {
                        this.message();
                        this.router.navigate(['/banner']);
                    }
                },
                error: (err) => {
                    this.toggleLoader(false);
                },
            });
    }

    bannerChecked(): boolean {
        return this.profiles.find((i) => i.checked);
    }

    message(): void {
        this.showMessage('Sucesso', 'Banner atualizado com sucesso!', 'success');
        this.toggleLoader(false);
    }
}
