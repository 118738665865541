import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SearchBannerInput } from "src/generated/graphql";
import { ServiceBase } from "../base/service.base";

@Injectable({
    providedIn: 'root'
})
export class BannerService extends ServiceBase {
    constructor(htt: HttpClient){
        super(htt);
    }
    
    upload(file: any, id: string) {
        const url = `${this.BASE_URL}upload/banner-image/${id}`;
        return new Observable((observer) => {
          const formData: FormData = new FormData(),
            xhr: XMLHttpRequest = new XMLHttpRequest();
    
          if (file != null) {
            formData.append("file", file, file.name);
          }
    
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                observer.next(JSON.parse(xhr.response));
                observer.complete();
              } else {
                observer.error(xhr.response);
              }
            }
          };
    
          xhr.open("POST", url, true);
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + this.getToken()
          );
          xhr.send(formData);
        });
    }

    getExcel(filter?: SearchBannerInput): Observable<any> {
      const url = `${this.BASE_URL}banner/excel`;
      return this.http.post(url, filter);
    }

}