<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title"><small>Relatórios ></small> Quizzes<!-- | <span>Gestão</span>--></h1>
        </div>

        <div class="page-filter">
            <div class="input-material">
                <input id="father" class="form-control" type="text" [(ngModel)]="filter.fatherCode"/>
                <label for="father">
                    <span>Código Pai</span>
                </label>
            </div>
            <div class="input-material">
                <input id="cpf" class="form-control" type="text" [(ngModel)]="filter.cpf"/>
                <label for="cpf">
                    <span>CPF</span>
                </label>
            </div>
            <div class="input-material">
                <input id="name" class="form-control" type="text" [(ngModel)]="filter.name"/>
                <label for="name">
                    <span>Nome</span>
                </label>
            </div>
                <div class="input-material">
                    <select id="profile" class="form-control" name="type" [(ngModel)]="filter.gmr">
                        <option selected
                                [ngValue]="undefined">{{'select' | translate}}</option>
                        <option *ngFor="let r of modelGrm" [ngValue]="r.id">{{r.name}}</option>
                    </select>
                    <label for="profile" class="mb-0 mr-3">GMR</label>
                </div>            
          
            <div class="input-material">
                <input id="gve" class="form-control" type="text" [(ngModel)]="filter.gve" />
                <label for="gve">
                    <span>GVE</span>
                </label>
            </div>
            <div class="input-material">
                <input id="razao" class="form-control" type="text" [(ngModel)]="filter.company"/>
                <label for="razao">
                    <span>Razão</span>
                </label>
            </div>
            <br>
            <a class="btn btn-primary btn-lg text-white" (click)="actionSearch()">Buscar</a>
            <a class="btn btn-info btn-lg text-white" (click)="getExcel()">Exportar Excel</a>
        </div>

        <!--GRID-->
        <div class="table-responsive">
            <table class="table table-striped" border="1">
                <thead>
                  <tr>
                    <th class="align-middle" rowspan="2">Perfil</th>
                    <th class="align-middle text-center" rowspan="2">Código Pai</th>
                    <th class="align-middle text-center" rowspan="2">Razão</th>
                    <th class="align-middle text-center" rowspan="2">Nome</th>
                    <th class="align-middle text-center" rowspan="2">CPF</th>
                    <th class="align-middle text-center" rowspan="2">GME</th>
                    <th class="align-middle text-center" rowspan="2">GVE</th>
                 
                    <th class="align-middle text-center" colspan="10">Outubro</th>
                    <th class="align-middle text-center" colspan="10">Novembro</th>
                    <th class="align-middle text-center" colspan="10">Dezembro</th>
                    <th class="align-middle text-center" colspan="10">Janeiro</th>
                    <th class="align-middle text-center" colspan="10">Fevereiro</th>

                    <th class="align-middle text-center" rowspan="2">PONTUAÇÃO FINAL</th>

                    <th class="align-middle text-center" rowspan="2">PONTUAÇÃO EQUIPE OUTUBRO</th>
                    <th class="align-middle text-center" rowspan="2">DATA EQUIPE QUIZ OUTUBRO</th>

                    <th class="align-middle text-center" rowspan="2">PONTUAÇÃO EQUIPE NOVEMBRO</th>
                    <th class="align-middle text-center" rowspan="2">DATA EQUIPE QUIZ NOVEMBRO</th>

                    <th class="align-middle text-center" rowspan="2">PONTUAÇÃO EQUIPE DEZEMBRO</th>
                    <th class="align-middle text-center" rowspan="2">DATA EQUIPE QUIZ DEZEMBRO</th>

                    <th class="align-middle text-center" rowspan="2">PONTUAÇÃO EQUIPE JANEIRO</th>
                    <th class="align-middle text-center" rowspan="2">DATA EQUIPE QUIZ JANEIRO</th>

                    <th class="align-middle text-center" rowspan="2">PONTUAÇÃO EQUIPE QUIZ FEVEREIRO</th>
                    <th class="align-middle text-center" rowspan="2">DATA EQUIPE QUIZ FEVEREIRO</th>

                  </tr>
                  <tr>
                    <ng-container *ngFor="let r of [1,2,3,4,5]">
                      <th class="align-middle text-center">Questão 1</th>
                      <th class="align-middle text-center">Data</th>
                      <th class="align-middle text-center">Hora</th>

                      <th class="align-middle text-center">Questão 2</th>
                      <th class="align-middle text-center">Data</th>
                      <th class="align-middle text-center">Hora</th>

                      <th class="align-middle text-center">Questão 3</th>
                      <th class="align-middle text-center">Data</th>
                      <th class="align-middle text-center">Hora</th>
                      <th class="align-middle text-center">Pontos</th>
                      
                    </ng-container>
                  </tr>
                <tbody>
                    <tr *ngFor="let r of modelResult.quizzes |
                    paginate: {
                     itemsPerPage: modelResult.PageSize,
                     currentPage: modelResult.currentPage,
                     totalItems: modelResult.totalCount}">

          <td class="align-middle">{{r.profileId}}</td>
          <td class="align-middle text-center">{{r.fatherCode}}</td>
          <td class="align-middle text-center">{{r.companyName}}</td>
          <td class="align-middle text-center">{{r.name}}</td>
          <td class="align-middle text-center">{{r.cpf}}</td>
          <td class="align-middle text-center">{{r.gmr}}</td>
          <td class="align-middle text-center">{{r.gve}}</td>


          <td class="align-middle text-center">{{r.quizOctober01}}</td>
          <td class="align-middle text-center">{{r.quizDateOctober01}}</td>
          <td class="align-middle text-center">{{r.quizHourOctober01}}</td>
          <td class="align-middle text-center">{{r.quizOctober02}}</td>
          <td class="align-middle text-center">{{r.quizDateOctober02}}</td>
          <td class="align-middle text-center">{{r.quizHourOctober02}}</td>
          <td class="align-middle text-center">{{r.quizOctober03}}</td>
          <td class="align-middle text-center">{{r.quizDateOctober03}}</td>
          <td class="align-middle text-center">{{r.quizHourOctober03}}</td>
          <td class="align-middle text-center">{{r.scoreOctober}}</td>

          <td class="align-middle text-center">{{r.quizNovember01}}</td>
          <td class="align-middle text-center">{{r.quizDateNovember01}}</td>
          <td class="align-middle text-center">{{r.quizHourNovember01}}</td>
          <td class="align-middle text-center">{{r.quizNovember02}}</td>
          <td class="align-middle text-center">{{r.quizDateNovember02}}</td>
          <td class="align-middle text-center">{{r.quizHourNovember02}}</td>
          <td class="align-middle text-center">{{r.quizNovember03}}</td>
          <td class="align-middle text-center">{{r.quizDateNovember03}}</td>
          <td class="align-middle text-center">{{r.quizHourNovember03}}</td>
          <td class="align-middle text-center">{{r.scoreNovember}}</td>


          <td class="align-middle text-center">{{r.quizDecember01}}</td>
          <td class="align-middle text-center">{{r.quizDateDecember01}}</td>
          <td class="align-middle text-center">{{r.quizHourDecember01}}</td>
          <td class="align-middle text-center">{{r.quizDecember02}}</td>
          <td class="align-middle text-center">{{r.quizDateDecember02}}</td>
          <td class="align-middle text-center">{{r.quizHourDecember02}}</td>
          <td class="align-middle text-center">{{r.quizDecember03}}</td>
          <td class="align-middle text-center">{{r.quizDateDecember03}}</td>
          <td class="align-middle text-center">{{r.quizHourDecember03}}</td>
          <td class="align-middle text-center">{{r.scoreDecember}}</td>

          <td class="align-middle text-center">{{r.quizJanuary01}}</td>
          <td class="align-middle text-center">{{r.quizDateJanuary01}}</td>
          <td class="align-middle text-center">{{r.quizHourJanuary01}}</td>
          <td class="align-middle text-center">{{r.quizJanuary02}}</td>
          <td class="align-middle text-center">{{r.quizDateJanuary02}}</td>
          <td class="align-middle text-center">{{r.quizHourJanuary02}}</td>
          <td class="align-middle text-center">{{r.quizJanuary03}}</td>
          <td class="align-middle text-center">{{r.quizDateJanuary03}}</td>
          <td class="align-middle text-center">{{r.quizHourJanuary03}}</td>
          <td class="align-middle text-center">{{r.scoreJanuary}}</td>


          <td class="align-middle text-center">{{r.quizFebruary01}}</td>
          <td class="align-middle text-center">{{r.quizDateFebruary01}}</td>
          <td class="align-middle text-center">{{r.quizHourFebruary01}}</td>
          <td class="align-middle text-center">{{r.quizFebruary02}}</td>
          <td class="align-middle text-center">{{r.quizDateFebruary02}}</td>
          <td class="align-middle text-center">{{r.quizHourFebruary02}}</td>
          <td class="align-middle text-center">{{r.quizFebruary03}}</td>
          <td class="align-middle text-center">{{r.quizDateFebruary03}}</td>
          <td class="align-middle text-center">{{r.quizHourFebruary03}}</td>
          <td class="align-middle text-center">{{r.scoreFebruary}}</td>


          <td class="align-middle text-center">{{r.finalScore}}</td>

          
          <td class="align-middle text-center">{{r.teamQuizOctober}}</td>
          <td class="align-middle text-center">{{r.teamQuizDateOctober}}</td>

          <td class="align-middle text-center">{{r.teamQuizNovember}}</td>
          <td class="align-middle text-center">{{r.teamQuizDateNovember}}</td>

          <td class="align-middle text-center">{{r.teamQuizDecember}}</td>
          <td class="align-middle text-center">{{r.teamQuizDateDecember}}</td>

          <td class="align-middle text-center">{{r.teamQuizJanuary}}</td>
          <td class="align-middle text-center">{{r.teamQuizDateJanuary}}</td>

          <td class="align-middle text-center">{{r.teamQuizFebruary}}</td>
          <td class="align-middle text-center">{{r.teamQuizDateFebruary}}</td>

        
        </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>

    </div>
</div>









