<nav id="sidebar">
    <ul class="list-unstyled components navbar-nav" id="accordionExample">

        <li class="header__mobile d-lg-none">
            <div class="header d-none">
                <div class="d-flex justify-content-around text-white">
                    <font class="header_font text-white">Idioma:</font>
                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'pt'}"
                       href="javascript:void('');"
                       (click)="switchLanguage('pt')">PT</a>
                    <span class="separator">|</span>
                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'es'}"
                       href="javascript:void('');"
                       (click)="switchLanguage('es')">ES</a>
                </div>
                <div class="header__btn mobile_notification notification_trigger">
                    <app-notification></app-notification>
                </div>
            </div>
        </li>

        <li class="nav-item  d-lg-none">
            <a href="javascript:void(0);" [routerLink]="['/']" class="nav-link text-white sidebar_top"
               style="height: initial;"
               (click)="actionCloseCollapse()">
                <span class="ico"><svg-icon src=""></svg-icon></span>
                <span class="text-center">
                  <!--<span class="lbl sidebar-title">
                      BIO
                  </span>-->
                  <span class="d-block d-lg-none header__user mb-0">
                    <span class="text-primary">{{'headerSalutation' | translate}}, <strong>{{user.name}}</strong></span>
                    <!--<img src="https://medicina.ucpel.edu.br/wp-content/uploads/2020/07/apacucpel_ucpel_image_319-1024x960.jpeg"
                         class="profile_photo"/>-->
                  </span>
                </span>
            </a>
        </li>

        <li class="nav-item">
            <a href="javascript:void('Home');" [routerLink]="['/']" class="nav-link align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/sidebar/home.svg"></svg-icon>
                <span class="lbl">Home</span>
            </a>
        </li>
        <li class="nav-item">
            <a href="javascript:void('Dashboard');" [routerLink]="['/dashboard']"
               class="nav-link  align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/dashboard.svg"></svg-icon>
                <span class="lbl">Dashboard</span>
            </a>
        </li>

        <li class="nav-item">
            <a href="javascript:void(0);" class="nav-link  align-items-center" style="gap: 20px"
               data-toggle="collapse"
               data-target="#idea" aria-expanded="false"
               aria-controls="idea">
                <svg-icon src="assets/svg/sidebar/content.svg"></svg-icon>
                <span class="lbl">Gestão de Conteúdo</span>
            </a>
            <div class="collapse" id="idea" data-parent="#accordionExample">
                <a href="javascript:void(0);"
                   (click)="actionCloseCollapse()" [routerLink]="['/banner']"
                   [ngClass]="{'active':router.url === '/banner'}"
                   class="nav-link  align-items-center">
                    <span class="lbl">Banner</span>
                </a>
                <a href="javascript:void(0);"
                   (click)="actionCloseCollapse()" [routerLink]="['/downloads']"
                   [ngClass]="{'active':router.url === '/downloads'}"
                   class="nav-link  align-items-center">
                    <span class="lbl">Downloads</span>
                </a>
                <a href="javascript:void(0);"
                   (click)="actionCloseCollapse()" [routerLink]="['/news']"
                   [ngClass]="{'active':router.url === '/news'}"
                   class="nav-link  align-items-center">
                    <span class="lbl">Notícias</span>
                </a>
            </div>
        </li>
         <li class="nav-item">
            <a href="javascript:void('User');" [routerLink]="['/visita']" class="nav-link align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/sidebar/flag.svg"></svg-icon>
                <span class="lbl">Visita Técnica</span>
            </a>
        </li>
        <!-- <li class="nav-item">
            <a href="javascript:void('User');" [routerLink]="['/mecanica']" class="nav-link align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/graph.svg"></svg-icon>
                <span class="lbl">Mecânica Extra</span>
            </a>
        </li> -->
        <li class="nav-item">
            <a href="javascript:void(0);" class="nav-link  align-items-center" style="gap: 20px"
               data-toggle="collapse"
               data-target="#ideaReport" aria-expanded="false"
               aria-controls="ideaReport">
                <svg-icon src="assets/svg/sidebar/chart-big.svg"></svg-icon>
                <span class="lbl">Relatórios</span>
            </a>
            <div class="collapse" id="ideaReport" data-parent="#accordionExample">
                <a href="javascript:void(0);"
                   (click)="actionCloseCollapseReport()" [routerLink]="['/mecanica']"
                   [ngClass]="{'active':router.url === '/mecanica'}"
                   class="nav-link  align-items-center">
                    <span class="lbl">Pontuação ME</span>
                </a>
                <a href="javascript:void(0);"
                   (click)="actionCloseCollapseReport()" [routerLink]="['/relatorio-treinamento']"
                   [ngClass]="{'active':router.url === '/relatorio-treinamento'}"
                   class="nav-link  align-items-center">
                    <span class="lbl">Vídeos</span>
                </a>

                <a href="javascript:void(0);"
                   (click)="actionCloseCollapseReport()" [routerLink]="['/relatorio-quiz']"
                   [ngClass]="{'active':router.url === '/relatorio-quiz'}"
                   class="nav-link  align-items-center">
                    <span class="lbl">Quizzes</span>
                </a>

                <a href="javascript:void(0);"
                (click)="actionCloseCollapseReport()" [routerLink]="['/relatorio-cupom']"
                [ngClass]="{'active':router.url === '/relatorio-cupom'}"
                class="nav-link  align-items-center">
                 <span class="lbl">Cupons</span>
             </a>
              
            </div>
        </li>

        <li class="nav-item">
            <a href="javascript:void(0);" class="nav-link  align-items-center" style="gap: 20px"
               data-toggle="collapse"
               data-target="#ideaUsers" aria-expanded="false"
               aria-controls="ideaUsers">
                <svg-icon src="assets/svg/sidebar/users.svg"></svg-icon>
                <span class="lbl">Usuários</span>
            </a>
            <div class="collapse" id="ideaUsers" data-parent="#accordionExample">
                <a href="javascript:void(0);"
                   (click)="actionCloseCollapseUsers()" [routerLink]="['/user-reseller']"
                   [ngClass]="{'active':router.url === '/user-reseller'}"
                   class="nav-link  align-items-center">
                    <span class="lbl">Revenda</span>
                </a>
                <a href="javascript:void(0);"
                   (click)="actionCloseCollapseUsers()" [routerLink]="['/user-reseller-team']"
                   [ngClass]="{'active':router.url === '/user-reseller-team'}"
                   class="nav-link  align-items-center">
                    <span class="lbl">Equipe Revenda</span>
                </a>
                <a href="javascript:void(0);"
                   (click)="actionCloseCollapseUsers()" [routerLink]="['/user-sales-team']"
                   [ngClass]="{'active':router.url === '/user-sales-team'}"
                   class="nav-link  align-items-center">
                    <span class="lbl">Equipe Comercial</span>
                </a>
                <a href="javascript:void(0);"
                   (click)="actionCloseCollapseUsers()" [routerLink]="['/user-excluded']"
                   [ngClass]="{'active':router.url === '/user-excluded'}"
                   class="nav-link  align-items-center">
                    <span class="lbl">Usuários Excluídos</span>
                </a>
            </div>
        </li>
        <!--
        <li class="nav-item">
            <a href="javascript:void('User');" [routerLink]="['/user']" class="nav-link align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/sidebar/users.svg"></svg-icon>
                <span class="lbl">{{'users' | translate}}</span>
            </a>
        </li>
        <li class="nav-item">
            <a href="javascript:void('User');" [routerLink]="['/visita']" class="nav-link align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/sidebar/flag.svg"></svg-icon>
                <span class="lbl">Visita Técnica</span>
            </a>
        </li>-->
        <li class="nav-item">
            <a href="javascript:void('User');" [routerLink]="['/contact']" class="nav-link align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/sidebar/contact-us.svg"></svg-icon>
                <span class="lbl">Fale Conosco</span>
            </a>
        </li>
        <li class="nav-item">
            <a href="javascript:void('User');" [routerLink]="['/admin']" class="nav-link align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/sidebar/user.svg"></svg-icon>
                <span class="lbl">Usuários Admin</span>
            </a>
        </li>
        <!--
        <li class="nav-item">
            <a href="javascript:void('User');" [routerLink]="['/report']" class="nav-link align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/sidebar/chart-big.svg"></svg-icon>
                <span class="lbl">Relatórios</span>
            </a>
        </li>-->
        <!--<li class="nav-item">
            <a href="javascript:void(0);" class="nav-link text-white align-items-center" style="gap: 20px"
               data-toggle="collapse"
               data-target="#setting" aria-expanded="false"
               aria-controls="setting">
                <svg-icon src="assets/svg/settings.svg"></svg-icon>
                <span class="lbl">Configurações</span>
            </a>
            <div class="collapse" id="setting" data-parent="#accordionExample">
                <a href="javascript:void(0);"
                   (click)="actionCloseCollapse()" [routerLink]="['/settings/camera']"
                   class="nav-link text-white align-items-center">
                    <span class="lbl">Câmera</span>
                </a>
                <a href="javascript:void(0);" (click)="actionCloseCollapse()" [routerLink]="['/']"
                   class="nav-link text-white align-items-center">
                    <span class="lbl">Acesso</span>
                </a>
                <a href="javascript:void(0);" (click)="actionCloseCollapse()" [routerLink]="['/']"
                   class="nav-link text-white align-items-center">
                    <span class="lbl">Alarme</span>
                </a>
                <a href="javascript:void(0);" (click)="actionCloseCollapse()" [routerLink]="['/']"
                   class="nav-link text-white align-items-center">
                    <span class="lbl">Bloco</span>
                </a>
                <a href="javascript:void(0);" (click)="actionCloseCollapse()" [routerLink]="['/']"
                   class="nav-link text-white align-items-center">
                    <span class="lbl">Unidade</span>
                </a>
            </div>
        </li>-->
    </ul>
    <div class="logout d-lg-none">
        <a href="javascript:void('sair');" [routerLink]="['/logout']">
            {{'sair' | translate}}
        </a>
    </div>

</nav>
