<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Fale Conosco</h1>
            <a href="javascript:void('excel')" class="btn btn-sm btn-info btn-text-white" (click)="getExcel()">
                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                Exportar Excel
            </a>
        </div>

        <div class="page-filter">
            <div class="input-material">
                <input id="period" class="form-control js-daterangepicker" type="text"/>
                <label for="period">
                    <span>Período</span>
                </label>
            </div>
            <div class="input-material">
                <input [(ngModel)]="filter.name" id="name" class="form-control" type="text"/>
                <label for="name">
                    <span>Nome</span>
                </label>
            </div>
            <div class="input-material">
                <input [(ngModel)]="filter.email" id="email" class="form-control" type="text"/>
                <label for="email">
                    <span>E-mail</span>
                </label>
            </div>
            <div class="input-material select">
                <select id="country" class="form-control" name="type" [(ngModel)]="filter.status">
                    <!-- <option selected [ngValue]="undefined">{{'select' | translate}}</option> -->
                    <option [ngValue]="''">Todos</option>
                    <option [ngValue]="'1'">Aguardando Resposta</option>
                    <option [ngValue]="'2'">Respondido</option>
                </select>
                <label for="country" class="mb-0 mr-3 disabled"> Status</label>
            </div>
            <a (click)="onFilter()" class="btn btn-primary btn-lg text-white">Buscar</a>
        </div>

        <!--GRID-->
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th class="text-center" class="w-5" scope="col"></th>
                    <th class="text-center" style="width: 17%;" scope="col">Status</th>
                    <th scope="col">Data/Hora</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Email</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Razão Social</th>
                    <th class="text-center" scope="col">Código do Cliente</th>
                    <th scope="col">CPF/CNPJ</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(contactPage.contact)">
                    <td colspan="10">
                        <p class="text-center py-5">Nenhum resultado encontrado</p>
                    </td>
                </tr>
                <tr *ngFor="let x of contactPage.contact">
                    <td class="text-center">
                        <a (click)="onDetailContact(x)" class="btn btn-sm btn-info text-white">
                            <svg-icon [svgStyle]="{'fill':'#fff'}" src="assets/svg/eyes.svg"></svg-icon>
                        </a>
                    </td>
                    <td class="text-center">
                        <div class="btn btn-sm bg-warning text-white" *ngIf="isNullOrUndefined(x.answeredAt)">
                            Aguardando
                        </div>
                        <div class="btn btn-sm bg-primary text-white" *ngIf="!isNullOrUndefined(x.answeredAt)">
                            Respondido
                        </div>
                    </td>
                    <td>{{x.createdAt|date:'dd/MM/yyyy HH:mm'}}</td>
                    <td>{{x.name}}</td>
                    <td>{{x.email}}</td>
                    <td>{{x.phone | mask:x.phone?.length > 10 ? '(00) 0 0000-0000' : '(00) 0000-0000'}}</td>
                    <td>{{x.user?.companyName}}</td>
                    <td class="text-center">{{x.user?.fatherCode}}</td>
                    <td>{{x.user?.cpfCnpj | mask: '000.000.000-00||00.000.000/0000-00'}}</td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>

<!--MODAL USER-->
<div class="modal-custom modal fade" id="modalContact" data-keyboard="false"
     data-backdrop="static" role="dialog" aria-labelledby="modalNoteResumeLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="modal-header-title mb-0">Fale Conosco</p>
                <a href="javascript:void('');" class="btn" type="button" (click)="closeModal('modalContact')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="border-bottom pb-3">
                    <div class="row">
                        <div class="col-sm-6">
                            <dl class="row mx-n1">
                                <dt class="contact_lbl col-auto px-1">Nome:</dt>
                                <dd class="contact_value col px-1">{{contact.name}}</dd>
                            </dl>
                        </div>
                        <div class="col-sm-6">
                            <dl class="row mx-n1">
                                <dt class="contact_lbl col-auto px-1">Data/Hora:</dt>
                                <dd class="contact_value col px-1">{{contact.createdAt | date:'dd/MM/yyyy HH:mm'}}</dd>
                            </dl>
                        </div>
                        <div class="col-sm-6">
                            <dl class="row mx-n1">
                                <dt class="contact_lbl col-auto px-1">Email:</dt>
                                <dd class="contact_value col px-1">{{contact.email}}</dd>
                            </dl>
                        </div>
                        <div class="col-sm-6">
                            <dl class="row mx-n1">
                                <dt class="contact_lbl col-auto px-1">Telefone:</dt>
                                <dd class="contact_value col px-1">{{contact.phone | mask:contact.phone?.length > 10 ? '(00) 0 0000-0000' : '(00) 0000-0000'}}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
                <div class="border-bottom py-3">
                    <div class="row">
                        <div class="col-sm-6">
                            <dl class="row mx-n1">
                                <dt class="contact_lbl col-12 px-1">Razão Social:</dt>
                                <dd class="contact_value col-12 px-1">{{contact.user?.companyName}}</dd>
                            </dl>
                        </div>
                        <div class="col-sm-6">
                            <dl class="row mx-n1">
                                <dt class="contact_lbl col-auto px-1">Código do cliente:</dt>
                                <dd class="contact_value col px-1">{{contact.user?.fatherCode}}</dd>
                            </dl>
                            <dl class="row mx-n1">
                                <dt class="contact_lbl col-auto px-1">CPF/CNPJ:</dt>
                                <dd class="contact_value col px-1">{{contact.user?.cpfCnpj | mask: '000.000.000-00||00.000.000/0000-00'}}</dd>
                            </dl>
                        </div>
                        <div class="col-12">
                            <dl class="row mx-n1">
                                <dt class="contact_lbl col-auto px-1">Mensagem:</dt>
                                <dd class="contact_value col px-1">{{contact.message}}</dd>
                            </dl>
                        </div>
                    </div>
                </div>

                <dl class="row mx-n1 pt-3" style="row-gap: 15px">
                    <dt class="contact_lbl col-12 px-1">Resposta:</dt>
                    <dd class="col-12 px-1">
                        <textarea [(ngModel)]="contact.answer" id="response" class="form-control" rows="4"
                                  [disabled]="!isNullOrUndefined(contact.answeredAt)"
                                  placeholder="Digite uma mensagem..."></textarea>
                        <label for="response"></label>
                    </dd>
                </dl>

                <button *ngIf="isNullOrUndefined(contact.answeredAt)" (click)="onSave()"
                        class="btn btn-block btn-primary text-white mt-3"> {{'save'|translate}}</button>
            </div>

        </div>
    </div>
</div>









