<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Segurança Eletrônica | <span>Controle de acesso</span></h1>
            <a class="btn btn-gray-fill">
                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                Exportar Excel
            </a>
            <a routerLink="/access-control/check-list" class="btn btn-secondary">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                Check list
            </a>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" class="form-control" type="text" required/>
                <label for="name">
                    <svg-icon [svgClass]="'input-search'" src="assets/svg/search.svg"></svg-icon>
                    <span>Busca</span>
                </label>
            </div>
            <div class="input-material">
                <input id="date" class="form-control" type="text" required/>
                <label for="date">
                    <span>Início</span>
                    <svg-icon class="right" src="assets/svg/calendar.svg"></svg-icon>
                </label>
            </div>
            <div class="input-material select">
                <select id="country" class="form-control" required>
                    <option disabled value hidden selected></option>
                    <option>Brasil</option>
                    <option>Argentina</option>
                </select>
                <label for="country">País</label>
            </div>
            <div class="input-material select">
                <select id="unity" class="form-control" required>
                    <option disabled value hidden selected></option>
                    <option>São Paulo</option>
                    <option>Buenos Ares</option>
                </select>
                <label for="unity">Unidade</label>
            </div>
            <a class="btn btn-primary btn-lg text-white">Buscar</a>
        </div>

        <div class="table-responsive">
            <table class="table table_regular">
                <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">País</th>
                    <th class="text-center" scope="col">Unidade</th>
                    <th scope="col">Usuário</th>
                    <th scope="col">Perfil</th>
                    <th class="text-center" scope="col">Status</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let list of [1,2,3,4,5]">
                    <td>00000</td>
                    <td></td>
                    <td class="text-center">Itapevi</td>
                    <td>
                        developers
                    </td>
                    <td>admin</td>
                    <td class="text-center">Ativo</td>
                    <td>
                        <div class="d-flex justify-content-center" style="gap: 15px">
                            <svg-icon (click)="UpdateUser(list)" src="assets/svg/edit.svg"></svg-icon>
                            <svg-icon (click)="actionDelete(list.id)" src="assets/svg/close-circle.svg"></svg-icon>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
