import {AfterViewInit, Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
    ApexNonAxisChartSeries,
    ApexAxisChartSeries,
    ApexPlotOptions,
    ApexChart,
    ApexStroke,
    ApexMarkers,
    ApexYAxis,
    ApexXAxis,
    ApexGrid,
    ApexLegend,
    ApexResponsive,
    ApexTitleSubtitle,
    ApexDataLabels,
    ApexFill,
    ApexTooltip,
    ChartComponent,
} from 'ng-apexcharts';
import {DashboardService} from '../../service/dashboard.service';
import {UserService} from '../../service/user.service';

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    btnNew = false;

    constructor(public router: Router,
                public dashboardService: DashboardService,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit() {
        $('#sidebar').addClass('is-hide');
    }

    ngAfterViewInit() {
        // this.userService.user.subscribe({
        //     next: data => {
        //         if (!this.isNullOrUndefined(data.id)) {
        //             this.btnNew = data.profile === 'local' || data.profile === 'administrator';
        //         }
        //     }
        // });
    }

    ngOnDestroy(): void {
        $('#sidebar').removeClass('is-hide');
    }


}



