<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <svg-icon [svgClass]="'cursor-pointer'" [routerLink]="'/visita'" src="assets/svg/arrow.svg"></svg-icon>
            <h1 class="page-title">{{flagStandardization ? 'Padronização' : 'Visita Técnica'}} | Detalhe</h1>
        </div>

        <!--GRID-->
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <!--                <th scope="col">ID</th>-->
                    <th scope="col">Código Pai</th>
                    <th scope="col">Razão</th>
                    <th scope="col">Status {{model.status}} / {{model.statusStandardization}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="false">
                    <td colspan="3">
                        <p class="text-center py-5">{{'nenhumresultado'|translate}}</p>
                    </td>
                </tr>
                <td>{{model.fatherCode}}</td>
                <td>{{model.companyName}}</td>
                <td *ngIf="flagStandardization"><span class="badge badge-{{getClassBadgeByStatus(model.statusStandardization)}}">{{model.statusStandardization}}</span></td>
                <td *ngIf="!flagStandardization"><span class="badge badge-{{getClassBadgeByStatus(model.status)}}">{{model.status}}</span></td>
                </tbody>
            </table>

        </div>
        <div class="row mb-3">
            <div class="col-6  mb-3">
                <strong>A revenda é um posto de combustível ? </strong>  {{!model.gasStation ? 'Não' : 'Sim'}}
            </div>
            <div class="col-6">
                <button type="submit" class="btn btn-primary float-right text-white" (click)="submit()"
                        *ngIf="(!flagStandardization && model.status?.toLowerCase() === 'recebida') || (flagStandardization && model.statusStandardization?.toLowerCase() === 'recebida')">
                    <span>Enviar</span>
                </button>
            </div>
        </div>


        <div class="row mx-0">
            <div class="col-12">
                <div class="card card-height_ bg-white">
                    <div class="card-header pb-0" [ngClass]="{'d-none' : flagStandardization}">
                        <ul class="nav nav-tabs col-12">
                            <li class="nav-item col-md-3 px-0">
                                <a class="nav-link active" id="home-tab" data-toggle="tab"
                                   href="#tab-gestao" role="tab"
                                   aria-controls="home" aria-selected="true">Gestão informatizada</a>
                            </li>
                            <li class="nav-item col-md-3 px-0">
                                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab-treinamento"
                                   role="tab"
                                   aria-controls="profile" aria-selected="false">Treinamento</a>
                            </li>
                            <li class="nav-item col-md-3 px-0">
                                <a class="nav-link" id="contact-tab" data-toggle="tab" href="#tab-seguranca"
                                   role="tab"
                                   aria-controls="contact" aria-selected="false">Segurança nas instalações</a>
                            </li>
                            <li class="nav-item col-md-3 px-0">
                                <a class="nav-link" id="padronizacao-tab" data-toggle="tab"
                                   href="#tab-padronizacao" role="tab"
                                   aria-controls="contact" aria-selected="false">Padronização</a>
                            </li>
                        </ul>
                    </div>

                    <div class="tab-content" id="myTabContent">
                        <!--Tab 1-->
                        <div class="tab-pane fade" id="tab-gestao" role="tabpanel"
                             [ngClass]="{'show active' : !flagStandardization}"
                             aria-labelledby="home-tab">
                            <div class="card-body">
                                <div class="row justify-content-between">
                                    <div class="col-12">
                                        <h3>Lista de Clientes</h3>
                                    </div>
                                    <ng-container *ngFor="let c of modelTechVisit.technicalVisitType; let cI = index;">
                                        <div class="col-12" *ngIf="c.type === 'computerizedManagent'">
                                            <ng-container *ngFor="let d of c.items; let cIdi = index;">
                                                <ng-container *ngIf="d.type === 'listCustomers'">
                                                    <div class="cardPhoto" *ngIf="isNullOrUndefined(d.path)">
                                                        <div class="d-flex justify-content-center align-items-center flex-column">
                                                            <img src="assets/images/nophoto.png" alt=""
                                                                 style="height: 80px"/>
                                                        </div>
                                                    </div>
                                                    <a href="{{baseFiles + d.path}}" target="_blank" class="cardPhoto"
                                                       *ngIf="!isNullOrUndefined(d.path)">
                                                        <span class="d-flex justify-content-center align-items-center flex-column">
                                                            <img src="{{baseFiles + d.path}}" alt=""
                                                                 style="height: 80px"
                                                                 *ngIf="d.path.toLowerCase().indexOf('.j') >= 0 || d.path.toLowerCase().indexOf('.png') >= 0"/>
                                                            <svg-icon class="svg-icon-primary"
                                                                      src="assets/svg/image.svg"
                                                                      *ngIf="d.path.toLowerCase().indexOf('.j') < 0 && d.path.toLowerCase().indexOf('.png') < 0"></svg-icon>
                                                            <!--<img src="assets/images/nophoto.png" alt="" style="height: 80px" />-->
                                                            <!--<svg-icon class="svg-icon-primary" src="assets/svg/image.svg"></svg-icon>-->
                                                        </span>
                                                    </a>
                                                    <div class="col-12">
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio"
                                                                   id="pendente{{cI + cIdi}}" name="radio{{cI + cIdi}}"
                                                                   [(ngModel)]="d.status"
                                                                   (change)="computerizedManagentBlockComment()"
                                                                   [disabled]="model.status.toLowerCase() !== 'recebida' || (d.currentStatus === 'approved' || d.currentStatus === 'disapproved')"
                                                                   [value]="'pending'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="pendente{{cI + cIdi}}">Revisar</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio"
                                                                   id="aprovar{{cI + cIdi}}" name="radio{{cI + cIdi}}"
                                                                   [(ngModel)]="d.status"
                                                                   (change)="computerizedManagentBlockComment()"
                                                                   [disabled]="model.status.toLowerCase() !== 'recebida' || (d.currentStatus === 'approved' || d.currentStatus === 'disapproved')"
                                                                   [value]="'approved'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="aprovar{{cI + cIdi}}">Aprovado</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio"
                                                                   id="reprovar{{cI + cIdi}}" name="radio{{cI + cIdi}}"
                                                                   [(ngModel)]="d.status"
                                                                   (change)="computerizedManagentBlockComment()"
                                                                   [disabled]="model.status.toLowerCase() !== 'recebida' || (d.currentStatus === 'approved' || d.currentStatus === 'disapproved')"
                                                                   [value]="'disapproved'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="reprovar{{cI + cIdi}}">Reprovado</label>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </ng-container>

                                    <div class="col-12">
                                        <h3>Fluxo de Caixa</h3>
                                    </div>
                                    <ng-container *ngFor="let c of modelTechVisit.technicalVisitType; let cI = index;">
                                        <ng-container *ngIf="c.type === 'computerizedManagent'">
                                            <ng-container *ngFor="let d of c.items; let cIdi = index;">
                                                <div class="col-6" *ngIf="d.type === 'cashFlow'">
                                                    <div class="cardPhoto" *ngIf="isNullOrUndefined(d.path)">
                                                        <div class="d-flex justify-content-center align-items-center flex-column">
                                                            <img src="assets/images/nophoto.png" alt=""
                                                                 style="height: 80px"/>
                                                        </div>
                                                    </div>
                                                    <a href="{{baseFiles + d.path}}" target="_blank" class="cardPhoto"
                                                       *ngIf="!isNullOrUndefined(d.path)">
                                                        <span class="d-flex justify-content-center align-items-center flex-column">
                                                            <img src="{{baseFiles + d.path}}" alt=""
                                                                 style="height: 80px"
                                                                 *ngIf="d.path.toLowerCase().indexOf('.j') >= 0 || d.path.toLowerCase().indexOf('.png') >= 0"/>
                                                            <svg-icon class="svg-icon-primary"
                                                                      src="assets/svg/image.svg"
                                                                      *ngIf="d.path.toLowerCase().indexOf('.j') < 0 && d.path.toLowerCase().indexOf('.png') < 0"></svg-icon>
                                                            <!--<img src="assets/images/nophoto.png" alt="" style="height: 80px" />-->
                                                        </span>
                                                    </a>
                                                    <div class="col-12">
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio"
                                                                   id="pendente2{{cI + cIdi}}"
                                                                   name="radioCashFlow{{cI + cIdi}}"
                                                                   [(ngModel)]="d.status"
                                                                   (change)="computerizedManagentBlockComment()"
                                                                   [disabled]="model.status.toLowerCase() !== 'recebida' || (d.currentStatus === 'approved' || d.currentStatus === 'disapproved')"
                                                                   [value]="'pending'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="pendente2{{cI + cIdi}}">Revisar</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio"
                                                                   id="aprovar2{{cI + cIdi}}"
                                                                   name="radioCashFlow{{cI + cIdi}}"
                                                                   [(ngModel)]="d.status"
                                                                   (change)="computerizedManagentBlockComment()"
                                                                   [disabled]="model.status.toLowerCase() !== 'recebida' || (d.currentStatus === 'approved' || d.currentStatus === 'disapproved')"
                                                                   [value]="'approved'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="aprovar2{{cI + cIdi}}">Aprovado</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio"
                                                                   id="reprovar2{{cI + cIdi}}"
                                                                   name="radioCashFlow{{cI + cIdi}}"
                                                                   [(ngModel)]="d.status"
                                                                   (change)="computerizedManagentBlockComment()"
                                                                   [disabled]="model.status.toLowerCase() !== 'recebida' || (d.currentStatus === 'approved' || d.currentStatus === 'disapproved')"
                                                                   [value]="'disapproved'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="reprovar2{{cI + cIdi}}">Reprovado</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>

                                <div class="row w-100">
                                    <div class="col-12">
                                        <h3>Comentários</h3>
                                    </div>

                                </div>
                                <div class="col-12">
                                    <div class="chat_body">
                                        <ng-container *ngFor="let item of this.model.computerizedManagent?.chat">
                                            <div class="row mx-n2 align-items-end my-3" *ngIf="item.userAdmin">
                                                <div class="col-auto px-2">
                                                    <img class="chat_photo" src="assets/images/logo-chat.png"
                                                         alt="logo-chat">
                                                </div>
                                                <div class="col-md-8 px-2">
                                                    <div class="chat_bubbly chat_bubbly-user">
                                                        <p class="chat_bubbly_message">{{item.message}}</p>
                                                        <p class="chat_bubbly_date mb-0">{{item.createdAt | date: 'dd/MM/yyyy hh:mm'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mx-n2 align-items-end my-3 flex-row-reverse"
                                                 *ngIf="item.userId">
                                                <div class="col-auto px-2">
                                                    <img class="chat_photo border-secondary"
                                                         [src]="this.getImageUrlUser(item.userImagePath)"
                                                         alt="logo-chat">
                                                </div>
                                                <div class="col-md-8 px-2">
                                                    <div class="chat_bubbly chat_bubbly-user anwser">
                                                        <p class="chat_bubbly_message">{{item.message}}</p>
                                                        <p class="chat_bubbly_date mb-0">{{item.createdAt | date: 'dd/MM/yyyy hh:mm'}}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="chat_footer">
                                        <div class="position-relative">
                                            <ng-container
                                                    *ngFor="let c of modelTechVisit.technicalVisitType; let cI = index;">
                                                <div class="chat_input" *ngIf="c.type === 'computerizedManagent'">
                                                    <textarea name="computerizedManagentComment"
                                                              id="computerizedManagentComment" class="comment-field"
                                                              [(ngModel)]="c.message"
                                                              [disabled]="computerizedManagentCommentDisabled"
                                                              rows="1"></textarea>
                                                </div>
                                            </ng-container>
                                            <!--<div contenteditable="true" data-text="Digite sua mensagem" class="chat_input"></div>-->
                                            <svg-icon class="chat_send" src="assets/svg/send.svg"></svg-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Tab 2-->
                        <div class="tab-pane fade " id="tab-treinamento" role="tabpanel" aria-labelledby="profile">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h3>Data do Treinamento</h3>
                                    </div>
                                    <div class="col-12">
                                        <div class="input-material">
                                            <input id="period" class="form-control" [mask]="'00/00/0000'" type="text"
                                                   [disabled]="true"
                                                   [(ngModel)]="model.training.trainingDate"
                                            />
                                            <label class="disabled" for="period">Data de Treinamento</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <h3>Upload Certificado</h3>
                                    </div>
                                    <ng-container *ngFor="let c of modelTechVisit.technicalVisitType; let cI = index;">
                                        <div class="col-12" *ngIf="c.type === 'training'">
                                            <ng-container *ngFor="let d of c.items; let cIdi = index;">
                                                <ng-container *ngIf="d.type === 'certificate'">
                                                    <div class="cardPhoto" *ngIf="isNullOrUndefined(d.path)">
                                                        <div class="d-flex justify-content-center align-items-center flex-column">
                                                            <img src="assets/images/nophoto.png" alt=""
                                                                 style="height: 80px"/>
                                                        </div>
                                                    </div>
                                                    <a href="{{baseFiles + d.path}}" target="_blank" class="cardPhoto"
                                                       *ngIf="!isNullOrUndefined(d.path)">
                                                        <span class="d-flex justify-content-center align-items-center flex-column">
                                                            <img src="{{baseFiles + d.path}}" alt=""
                                                                 style="height: 80px"
                                                                 *ngIf="d.path.toLowerCase().indexOf('.j') >= 0 || d.path.toLowerCase().indexOf('.png') >= 0"/>
                                                            <svg-icon class="svg-icon-primary"
                                                                      src="assets/svg/image.svg"
                                                                      *ngIf="d.path.toLowerCase().indexOf('.j') < 0 && d.path.toLowerCase().indexOf('.png') < 0"></svg-icon>
                                                        </span>
                                                    </a>
                                                    <div class="col-12">
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio"
                                                                   id="pendenteTraining{{cI + cIdi}}"
                                                                   name="radioTraining{{cI + cIdi}}"
                                                                   [(ngModel)]="d.status"
                                                                   (change)="trainingBlockComment()"
                                                                   [disabled]="model.status.toLowerCase() !== 'recebida' || (d.currentStatus === 'approved' || d.currentStatus === 'disapproved')"
                                                                   [value]="'pending'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="pendenteTraining{{cI + cIdi}}">Revisar</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio"
                                                                   id="aprovarTraining{{cI + cIdi}}"
                                                                   name="radioTraining{{cI + cIdi}}"
                                                                   [(ngModel)]="d.status"
                                                                   (change)="trainingBlockComment()"
                                                                   [disabled]="model.status.toLowerCase() !== 'recebida' || (d.currentStatus === 'approved' || d.currentStatus === 'disapproved')"
                                                                   [value]="'approved'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="aprovarTraining{{cI + cIdi}}">Aprovado</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio"
                                                                   id="reprovarTraining{{cI + cIdi}}"
                                                                   name="radioTraining{{cI + cIdi}}"
                                                                   [(ngModel)]="d.status"
                                                                   (change)="trainingBlockComment()"
                                                                   [disabled]="model.status.toLowerCase() !== 'recebida' || (d.currentStatus === 'approved' || d.currentStatus === 'disapproved')"
                                                                   [value]="'disapproved'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="reprovarTraining{{cI + cIdi}}">Reprovado</label>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="row w-100">
                                    <div class="col-12">
                                        <h3>Comentários</h3>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="chat_body">
                                        <ng-container *ngFor="let item of this.model.training?.chat">
                                            <div class="row mx-n2 align-items-end my-3" *ngIf="item.userAdmin">
                                                <div class="col-auto px-2">
                                                    <img class="chat_photo" src="assets/images/logo-chat.png"
                                                         alt="logo-chat">
                                                </div>
                                                <div class="col-md-8 px-2">
                                                    <div class="chat_bubbly chat_bubbly-user">
                                                        <p class="chat_bubbly_message">{{item.message}}</p>
                                                        <p class="chat_bubbly_date mb-0">{{item.createdAt | date: 'dd/MM/yyyy hh:mm'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mx-n2 align-items-end my-3 flex-row-reverse"
                                                 *ngIf="item.userId">
                                                <div class="col-auto px-2">
                                                    <img class="chat_photo border-secondary"
                                                         [src]="this.getImageUrlUser(item.userImagePath)"
                                                         alt="logo-chat">
                                                </div>
                                                <div class="col-md-8 px-2">
                                                    <div class="chat_bubbly chat_bubbly-user anwser">
                                                        <p class="chat_bubbly_message">{{item.message}}</p>
                                                        <p class="chat_bubbly_date mb-0">{{item.createdAt | date: 'dd/MM/yyyy hh:mm'}}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="chat_footer">
                                        <div class="position-relative">
                                            <ng-container
                                                    *ngFor="let c of modelTechVisit.technicalVisitType; let cI = index;">
                                                <div class="chat_input" *ngIf="c.type === 'training'">
                                                    <textarea name="trainingComment" id="trainingComment"
                                                              class="comment-field"
                                                              [(ngModel)]="c.message"
                                                              [disabled]="trainingCommentDisabled"
                                                              rows="1"></textarea>
                                                </div>
                                            </ng-container>
                                            <!--<div contenteditable="true" data-text="Digite sua mensagem" class="chat_input"></div>-->
                                            <svg-icon class="chat_send" src="assets/svg/send.svg"></svg-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Tab 3-->
                        <div class="tab-pane fade " id="tab-seguranca" role="tabpanel"
                             aria-labelledby="contact-tab">
                            <div class="card-body">
                                <div class="row">

                                    <ng-container *ngFor="let c of modelTechVisit.technicalVisitType; let cI = index;">
                                        <ng-container *ngIf="c.type === 'installationSafety'">
                                            <ng-container *ngFor="let d of c.items; let cIdi = index;">
                                                <div class="col-6">
                                                    <div class="cardPhoto" *ngIf="isNullOrUndefined(d.path)">
                                                        <div class="d-flex justify-content-center align-items-center flex-column">
                                                            <img src="assets/images/nophoto.png" alt=""
                                                                 style="height: 80px"/>
                                                        </div>
                                                    </div>
                                                    <a href="{{baseFiles + d.path}}" target="_blank" class="cardPhoto"
                                                       *ngIf="!isNullOrUndefined(d.path)">
                                                        <span class="d-flex justify-content-center align-items-center flex-column">
                                                            <img src="{{baseFiles + d.path}}" alt=""
                                                                 style="height: 80px"
                                                                 *ngIf="d.path.toLowerCase().indexOf('.j') >= 0 || d.path.toLowerCase().indexOf('.png') >= 0"/>
                                                            <svg-icon class="svg-icon-primary"
                                                                      src="assets/svg/image.svg"
                                                                      *ngIf="d.path.toLowerCase().indexOf('.j') < 0 && d.path.toLowerCase().indexOf('.png') < 0"></svg-icon>
                                                            <!--<img src="assets/images/nophoto.png" alt="" style="height: 80px" />-->
                                                            <!--<svg-icon class="svg-icon-primary" src="assets/svg/image.svg"></svg-icon>-->
                                                        </span>
                                                    </a>
                                                    <div class="col-12">
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio"
                                                                   id="pendente3{{cI + cIdi}}"
                                                                   name="radioSafety{{cI + cIdi}}"
                                                                   [(ngModel)]="d.status"
                                                                   (change)="installationSafetyBlockComment()"
                                                                   [disabled]="model.status.toLowerCase() !== 'recebida' || (d.currentStatus === 'approved' || d.currentStatus === 'disapproved')"
                                                                   [value]="'pending'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="pendente3{{cI + cIdi}}">Revisar</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio"
                                                                   id="aprovar3{{cI + cIdi}}"
                                                                   name="radioSafety{{cI + cIdi}}"
                                                                   [(ngModel)]="d.status"
                                                                   (change)="installationSafetyBlockComment()"
                                                                   [disabled]="model.status.toLowerCase() !== 'recebida' || (d.currentStatus === 'approved' || d.currentStatus === 'disapproved')"
                                                                   [value]="'approved'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="aprovar3{{cI + cIdi}}">Aprovado</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio"
                                                                   id="reprovar3{{cI + cIdi}}"
                                                                   name="radioSafety{{cI + cIdi}}"
                                                                   [(ngModel)]="d.status"
                                                                   (change)="installationSafetyBlockComment()"
                                                                   [disabled]="model.status.toLowerCase() !== 'recebida' || (d.currentStatus === 'approved' || d.currentStatus === 'disapproved')"
                                                                   [value]="'disapproved'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="reprovar3{{cI + cIdi}}">Reprovado</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>

                                <div class="row w-100">
                                    <div class="col-12">
                                        <h3>Comentários</h3>
                                    </div>

                                </div>
                                <div class="col-12">
                                    <div class="chat_body">
                                        <ng-container *ngFor="let item of this.model.installationSafety?.chat">
                                            <div class="row mx-n2 align-items-end my-3" *ngIf="item.userAdmin">
                                                <div class="col-auto px-2">
                                                    <img class="chat_photo" src="assets/images/logo-chat.png"
                                                         alt="logo-chat">
                                                </div>
                                                <div class="col-md-8 px-2">
                                                    <div class="chat_bubbly chat_bubbly-user">
                                                        <p class="chat_bubbly_message">{{item.message}}</p>
                                                        <p class="chat_bubbly_date mb-0">{{item.createdAt | date: 'dd/MM/yyyy hh:mm'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mx-n2 align-items-end my-3 flex-row-reverse"
                                                 *ngIf="item.userId">
                                                <div class="col-auto px-2">
                                                    <img class="chat_photo border-secondary"
                                                         [src]="this.getImageUrlUser(item.userImagePath)"
                                                         alt="logo-chat">
                                                </div>
                                                <div class="col-md-8 px-2">
                                                    <div class="chat_bubbly chat_bubbly-user anwser">
                                                        <p class="chat_bubbly_message">{{item.message}}</p>
                                                        <p class="chat_bubbly_date mb-0">{{item.createdAt | date: 'dd/MM/yyyy hh:mm'}}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="chat_footer">
                                        <div class="position-relative">
                                            <ng-container
                                                    *ngFor="let c of modelTechVisit.technicalVisitType; let cI = index;">
                                                <div class="chat_input" *ngIf="c.type === 'installationSafety'">
                                                    <textarea name="installationSafetyComment"
                                                              id="installationSafetyComment" class="comment-field"
                                                              [(ngModel)]="c.message"
                                                              [disabled]="installationSafetyCommentDisabled"
                                                              rows="1"></textarea>
                                                </div>
                                            </ng-container>
                                            <!--<div contenteditable="true" data-text="Digite sua mensagem" class="chat_input"></div>-->
                                            <svg-icon class="chat_send" src="assets/svg/send.svg"></svg-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Tab 4-->
                        <div class="tab-pane fade " id="tab-padronizacao" role="tabpanel"
                             [ngClass]="{'show active' : flagStandardization}"
                             aria-labelledby="tab-padronizacao">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card-grey">
                                            <div class="row">
                                                <div class="col-12"><h3>Revenda Matriz</h3></div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <p class="m-0 text-tertiary">Código Pai</p>
                                                            <p>{{model.fatherCode}}</p>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="m-0 text-tertiary">Razão Social</p>
                                                            <p>{{model.companyName}}</p>
                                                        </div>
                                                        <!--<div class="col-md-4">
                                                            <p class="m-0 text-tertiary">CNPJ</p>
                                                            <p></p>
                                                        </div>-->
                                                    </div>
                                                </div>

                                                <div class="col-md-3"
                                                     *ngFor="let x of standardization?.resaleFacade?.items; let xI = index;">
                                                    <div class="cardPhoto" *ngIf="isNullOrUndefined(x.path)">
                                                        <div class="d-flex justify-content-center align-items-center flex-column">
                                                            <img src="assets/images/nophoto.png" alt=""
                                                                 style="height: 80px"/>
                                                        </div>
                                                        <b class="text-tertiary">{{enumStandardization(x.name)}}</b>
                                                    </div>
                                                    <a href="javascript:void('');" class="cardPhoto"
                                                       *ngIf="!isNullOrUndefined(x.path)">
                                                        <span class="d-flex justify-content-center align-items-center flex-column">
                                                            <img src="{{baseFiles + x.path}}" alt="" (click)="open(0, baseFiles + x.path)"
                                                                 style="height: 80px"
                                                                 *ngIf="x.path.toLowerCase().indexOf('.j') >= 0 || x.path.toLowerCase().indexOf('.png') >= 0"/>
                                                            <svg-icon class="svg-icon-primary"
                                                                      src="assets/svg/image.svg"
                                                                      *ngIf="x.path.toLowerCase().indexOf('.j') < 0 && x.path.toLowerCase().indexOf('.png') < 0"></svg-icon>
                                                            <!--<img src="assets/images/nophoto.png" alt="" style="height: 80px" />-->
                                                            <!--<svg-icon class="svg-icon-primary" src="assets/svg/image.svg"></svg-icon>-->
                                                        </span>
                                                        <b class="text-tertiary">{{enumStandardization(x.name)}}</b>
                                                    </a>
                                                    <div class="custom-control custom-radio custom-control-inline ">
                                                        <input type="radio" id="pendenteMatriz{{xI}}"
                                                               name="radioMatriz{{xI}}"
                                                               [(ngModel)]="x.status"
                                                               (change)="standardizationBlockComment()"
                                                               [disabled]="(model.status.toLowerCase() !== 'recebida' && model.statusStandardization.toLowerCase() !== 'recebida') || (x.currentStatus === 'approved' || x.currentStatus === 'disapproved')"
                                                               [value]="'pending'"
                                                               class="custom-control-input">
                                                        <label class="custom-control-label text-VTgray text-radial"
                                                               for="pendenteMatriz{{xI}}">Revisar</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline ">
                                                        <input type="radio" id="aprovarMatriz{{xI}}"
                                                               name="radioMatriz{{xI}}"
                                                               [(ngModel)]="x.status"
                                                               (change)="standardizationBlockComment()"
                                                               [disabled]="(model.status.toLowerCase() !== 'recebida' && model.statusStandardization.toLowerCase() !== 'recebida') || (x.currentStatus === 'approved' || x.currentStatus === 'disapproved')"
                                                               [value]="'approved'"
                                                               class="custom-control-input">
                                                        <label class="custom-control-label text-VTgray text-radial"
                                                               for="aprovarMatriz{{xI}}">Aprovado</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline ">
                                                        <input type="radio" id="reprovarMatriz{{xI}}"
                                                               name="radioMatriz{{xI}}"
                                                               [(ngModel)]="x.status"
                                                               (change)="standardizationBlockComment()"
                                                               [disabled]="(model.status.toLowerCase() !== 'recebida' && model.statusStandardization.toLowerCase() !== 'recebida') || (x.currentStatus === 'approved' || x.currentStatus === 'disapproved')"
                                                               [value]="'disapproved'"
                                                               class="custom-control-input">
                                                        <label class="custom-control-label text-VTgray text-radial"
                                                               for="reprovarMatriz{{xI}}">Reprovado</label>
                                                    </div>
                                                </div>


                                                <ng-container *ngIf="standardization?.resaleFacade?.isFleet">
                                                    <div class="col-12">
                                                        <h3>Frota</h3>
                                                    </div>
                                                    <div class="col-md-3"
                                                         *ngFor="let x of standardization?.resaleFleet?.items; let xI = index;">
                                                        <div>
                                                            <div class="cardPhoto" *ngIf="isNullOrUndefined(x.path)">
                                                                <div class="d-flex justify-content-center align-items-center flex-column">
                                                                    <img src="assets/images/nophoto.png" alt=""
                                                                         style="height: 80px"/>
                                                                </div>
                                                                <b class="text-tertiary">{{enumStandardization(x.name)}}</b>
                                                            </div>
                                                            <a href="javascript:void('');"
                                                               class="cardPhoto" *ngIf="!isNullOrUndefined(x.path)">
                                                                <span class="d-flex justify-content-center align-items-center flex-column">
                                                                    <img src="{{baseFiles + x.path}}" alt="" (click)="open(0, baseFiles + x.path)"
                                                                         style="height: 80px"
                                                                         *ngIf="x.path.toLowerCase().indexOf('.j') >= 0 || x.path.toLowerCase().indexOf('.png') >= 0"/>
                                                                    <svg-icon class="svg-icon-primary"
                                                                              src="assets/svg/image.svg"
                                                                              *ngIf="x.path.toLowerCase().indexOf('.j') < 0 && x.path.toLowerCase().indexOf('.png') < 0"></svg-icon>
                                                                </span>
                                                                <b class="text-tertiary">{{enumStandardization(x.name)}}</b>
                                                            </a>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio" id="pendenteMatriz_{{xI}}"
                                                                   name="radioMatriz_{{xI}}"
                                                                   [(ngModel)]="x.status"
                                                                   (change)="standardizationBlockComment()"
                                                                   [disabled]="(model.status.toLowerCase() !== 'recebida' && model.statusStandardization.toLowerCase() !== 'recebida') || (x.currentStatus === 'approved' || x.currentStatus === 'disapproved')"
                                                                   [value]="'pending'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="pendenteMatriz_{{xI}}">Revisar</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio" id="aprovarMatriz_{{xI}}"
                                                                   name="radioMatriz_{{xI}}"
                                                                   [(ngModel)]="x.status"
                                                                   (change)="standardizationBlockComment()"
                                                                   [disabled]="(model.status.toLowerCase() !== 'recebida' && model.statusStandardization.toLowerCase() !== 'recebida')|| (x.currentStatus === 'approved' || x.currentStatus === 'disapproved')"
                                                                   [value]="'approved'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="aprovarMatriz_{{xI}}">Aprovado</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio" id="reprovarMatriz_{{xI}}"
                                                                   name="radioMatriz_{{xI}}"
                                                                   [(ngModel)]="x.status"
                                                                   (change)="standardizationBlockComment()"
                                                                   [disabled]="(model.status.toLowerCase() !== 'recebida' && model.statusStandardization.toLowerCase() !== 'recebida')|| (x.currentStatus === 'approved' || x.currentStatus === 'disapproved')"
                                                                   [value]="'disapproved'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="reprovarMatriz_{{xI}}">Reprovado</label>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <!--Filiais-->
                                    <div class="col-12" *ngIf="standardization!.companyBranch?.length > 0">
                                        <div class="row">
                                            <div class="col-12 my-4">
                                                <h2>Filiais</h2>
                                            </div>
                                            <ng-container
                                                    *ngFor="let f of standardization!.companyBranch; let i = index">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <p class="m-0 text-tertiary">A revenda é um posto de combustível? </p>
                                                            <p>{{!f.gasStation ? 'Não' : 'Sim'}}</p>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <p class="m-0 text-tertiary">Razão Social</p>
                                                            <p>{{f.sonCompanyName}}</p>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <p class="m-0 text-tertiary">CNPJ</p>
                                                            <p>{{f.sonCNPJ}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <h3>Fachada</h3>
                                                </div>
                                                <div class="col-md-3"
                                                     *ngFor="let fc of f.resaleFacade?.items; let xI = index;">
                                                    <div class="cardPhoto" *ngIf="isNullOrUndefined(fc.path)">
                                                        <div class="d-flex justify-content-center align-items-center flex-column">
                                                            <img src="assets/images/nophoto.png" alt=""
                                                                 style="height: 80px"/>
                                                        </div>
                                                        <b class="text-tertiary">{{enumStandardization(fc.name)}}</b>
                                                    </div>
                                                    <a href="javascript:void('');" class="cardPhoto"
                                                       *ngIf="!isNullOrUndefined(fc.path)">
                                                        <span class="d-flex justify-content-center align-items-center flex-column">
                                                            <img src="{{baseFiles + fc.path}}" alt="" (click)="open(0, baseFiles + fc.path)"
                                                                 style="height: 80px"
                                                                 *ngIf="fc.path.toLowerCase().indexOf('.j') >= 0 || fc.path.toLowerCase().indexOf('.png') >= 0"/>
                                                            <svg-icon class="svg-icon-primary"
                                                                      src="assets/svg/image.svg"
                                                                      *ngIf="fc.path.toLowerCase().indexOf('.j') < 0 && fc.path.toLowerCase().indexOf('.png') < 0"></svg-icon>
                                                        </span>
                                                        <b class="text-tertiary">{{enumStandardization(fc.name)}}</b>
                                                    </a>
                                                    <div>
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio" id="pendenteFilial{{i}}_{{xI}}"
                                                                   name="radioFilial{{i}}_{{xI}}"
                                                                   [(ngModel)]="fc.status"
                                                                   (change)="standardizationBlockComment()"
                                                                   [disabled]="(model.status.toLowerCase() !== 'recebida' && model.statusStandardization.toLowerCase() !== 'recebida') || (fc.currentStatus === 'approved' || fc.currentStatus === 'disapproved')"
                                                                   [value]="'pending'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="pendenteFilial{{i}}_{{xI}}">Revisar</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio" id="aprovarFilial{{i}}_{{xI}}"
                                                                   name="radioFilial{{i}}_{{xI}}"
                                                                   [(ngModel)]="fc.status"
                                                                   (change)="standardizationBlockComment()"
                                                                   [disabled]="(model.status.toLowerCase() !== 'recebida' && model.statusStandardization.toLowerCase() !== 'recebida') || (fc.currentStatus === 'approved' || fc.currentStatus === 'disapproved')"
                                                                   [value]="'approved'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="aprovarFilial{{i}}_{{xI}}">Aprovado</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline ">
                                                            <input type="radio" id="reprovarFilial{{i}}_{{xI}}"
                                                                   name="radioFilial{{i}}_{{xI}}"
                                                                   [(ngModel)]="fc.status"
                                                                   (change)="standardizationBlockComment()"
                                                                   [disabled]="(model.status.toLowerCase() !== 'recebida' && model.statusStandardization.toLowerCase() !== 'recebida') || (fc.currentStatus === 'approved' || fc.currentStatus === 'disapproved')"
                                                                   [value]="'disapproved'"
                                                                   class="custom-control-input">
                                                            <label class="custom-control-label text-VTgray text-radial"
                                                                   for="reprovarFilial{{i}}_{{xI}}">Reprovado</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ng-container *ngIf="f.resaleFacade?.isFleet">
                                                    <div class="col-12">
                                                        <h3>Frota</h3>
                                                    </div>
                                                    <div class="col-md-3"
                                                         *ngFor="let fc of f.resaleFleet?.items; let xI = index;">
                                                        <div class="cardPhoto" *ngIf="isNullOrUndefined(fc.path)">
                                                            <div class="d-flex justify-content-center align-items-center flex-column">
                                                                <img src="assets/images/nophoto.png" alt=""
                                                                     style="height: 80px"/>
                                                            </div>
                                                            <b class="text-tertiary">{{enumStandardization(fc.name)}}</b>
                                                        </div>
                                                        <a href="javascript:void('');" class="cardPhoto"
                                                           *ngIf="!isNullOrUndefined(fc.path)">
                                                            <span class="d-flex justify-content-center align-items-center flex-column">
                                                                <img src="{{baseFiles + fc.path}}" alt="" (click)="open(0, baseFiles + fc.path)"
                                                                     style="height: 80px"
                                                                     *ngIf="fc.path.toLowerCase().indexOf('.j') >= 0 || fc.path.toLowerCase().indexOf('.png') >= 0"/>
                                                                <svg-icon class="svg-icon-primary"
                                                                          src="assets/svg/image.svg"
                                                                          *ngIf="fc.path.toLowerCase().indexOf('.j') < 0 && fc.path.toLowerCase().indexOf('.png') < 0"></svg-icon>
                                                            </span>
                                                            <b class="text-tertiary">{{enumStandardization(fc.name)}}</b>
                                                        </a>
                                                        <div class="d-block w-100">
                                                            <div class="custom-control custom-radio custom-control-inline ">
                                                                <input type="radio" id="pendenteFilial_{{i}}_{{xI}}"
                                                                       name="radioFilial_{{i}}_{{xI}}"
                                                                       [(ngModel)]="fc.status"
                                                                       (change)="standardizationBlockComment()"
                                                                       [disabled]="(model.status.toLowerCase() !== 'recebida' && model.statusStandardization.toLowerCase() !== 'recebida') || (fc.currentStatus === 'approved' || fc.currentStatus === 'disapproved')"
                                                                       [value]="'pending'"
                                                                       class="custom-control-input">
                                                                <label class="custom-control-label text-VTgray text-radial"
                                                                       for="pendenteFilial_{{i}}_{{xI}}">Revisar</label>
                                                            </div>
                                                            <div class="custom-control custom-radio custom-control-inline ">
                                                                <input type="radio" id="aprovarFilial_{{i}}_{{xI}}"
                                                                       name="radioFilial_{{i}}_{{xI}}"
                                                                       [(ngModel)]="fc.status"
                                                                       (change)="standardizationBlockComment()"
                                                                       [disabled]="(model.status.toLowerCase() !== 'recebida' && model.statusStandardization.toLowerCase() !== 'recebida') || (fc.currentStatus === 'approved' || fc.currentStatus === 'disapproved')"
                                                                       [value]="'approved'"
                                                                       class="custom-control-input">
                                                                <label class="custom-control-label text-VTgray text-radial"
                                                                       for="aprovarFilial_{{i}}_{{xI}}">Aprovado</label>
                                                            </div>
                                                            <div class="custom-control custom-radio custom-control-inline ">
                                                                <input type="radio" id="reprovarFilial_{{i}}_{{xI}}"
                                                                       name="radioFilial_{{i}}_{{xI}}"
                                                                       [(ngModel)]="fc.status"
                                                                       (change)="standardizationBlockComment()"
                                                                       [disabled]="(model.status.toLowerCase() !== 'recebida' && model.statusStandardization.toLowerCase() !== 'recebida') || (fc.currentStatus === 'approved' || fc.currentStatus === 'disapproved')"
                                                                       [value]="'disapproved'"
                                                                       class="custom-control-input">
                                                                <label class="custom-control-label text-VTgray text-radial"
                                                                       for="reprovarFilial_{{i}}_{{xI}}">Reprovado</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <div class="d-block w-100 my-5">
                                                    <hr/>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                </div>

                                <div class="row w-100">
                                    <div class="col-12">
                                        <h3>Comentários</h3>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="chat_body">
                                        <ng-container *ngFor="let item of this.model.standarlization?.chat">
                                            <div class="row mx-n2 align-items-end my-3" *ngIf="item.userAdmin">
                                                <div class="col-auto px-2">
                                                    <img class="chat_photo" src="assets/images/logo-chat.png"
                                                         alt="logo-chat">
                                                </div>
                                                <div class="col-md-8 px-2">
                                                    <div class="chat_bubbly chat_bubbly-user">
                                                        <p class="chat_bubbly_message">{{item.message}}</p>
                                                        <p class="chat_bubbly_date mb-0">{{item.createdAt | date: 'dd/MM/yyyy hh:mm'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mx-n2 align-items-end my-3 flex-row-reverse"
                                                 *ngIf="item.userId">
                                                <div class="col-auto px-2">
                                                    <img class="chat_photo border-secondary"
                                                         [src]="this.getImageUrlUser(item.userImagePath)"
                                                         alt="logo-chat">
                                                </div>
                                                <div class="col-md-8 px-2">
                                                    <div class="chat_bubbly chat_bubbly-user anwser">
                                                        <p class="chat_bubbly_message ">{{item.message}}</p>
                                                        <p class="chat_bubbly_date mb-0">{{item.createdAt | date: 'dd/MM/yyyy hh:mm'}}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="chat_footer">
                                        <div class="position-relative">
                                            <div class="chat_input">
                                                <textarea name="standardizationComment" id="standardizationComment"
                                                          class="comment-field"
                                                          [disabled]="standardizationCommentDisabled"
                                                          rows="1"></textarea>
                                            </div>
                                            <!--<div contenteditable="true" data-text="Digite sua mensagem" class="chat_input"></div>-->
                                            <svg-icon class="chat_send" src="assets/svg/send.svg"></svg-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>









