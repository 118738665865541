import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends BaseComponent implements OnInit {
    modelNotifications: any = [];

    constructor(public router: Router,
                public translate: TranslateService,
                public notificationsService: UserService) {
        super(router, translate);
    }

    ngOnInit() {
        // console.log('Notification:ngOnInit');
        this.notificationsService.notification.subscribe({
            next: data => {
                this.modelNotifications = data as Notification[];
                // this.loading.hide();
                // this.toggleLoader(false);
            }, error: err => super.onError(err)
        });
        this.notificationsService.getNotification();
    }


    actionNotification(model) {
        // this.notificationsService.updateNotification(model.id).subscribe({
        //     next: data => {
        //         this.router.navigate(['/register/' + model.relationId]);
        //     }, error: err => super.onError(err)
        // });
    }
}
