import {Component, OnDestroy, OnInit, AfterContentInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {
    User,
} from '../../../generated/graphql';

import {QueryRef} from 'apollo-angular';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {UserService} from '../../service/user.service';

declare var $: any;

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements AfterContentInit, OnDestroy {
    // user = new User();
    currentPage = 1;

    labels: any = {
        // previousLabel: '<--',
        previousLabel: ' ',
        // nextLabel: '-->',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    constructor(public router: Router,
                public translate: TranslateService,
                public userService: UserService,
    ) {
        super(router, translate);
    }

    ngAfterContentInit() {
        this.userService.user.subscribe({
            next: data => {
                // this.user = data as User;
            }, error: err => super.onError(err)
        });

        this.setDatePicker('picker-period', (start, end, label) => {
            // this.modelFilter.start = start.utcOffset(0, true).format();
            // this.modelFilter.end = end.utcOffset(0, true).format();
        });


    }

    ngOnDestroy(): void {
        const elModalUser = $('#modalUser');
        if (elModalUser[0]) {
            elModalUser.remove();
        }
    }

    actionNew() {
        console.log('actionNew');
    }

    
}
