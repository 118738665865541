import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {SearchContactInput, SearchUserAdminInput, TechnicalVisitFilterAdminInput, UserSalesTeamInput, UsersResellerInput, UsersResellerTeamInput} from "src/generated/graphql";
import {ServiceBase} from "../base/service.base";

@Injectable({
    providedIn: 'root'
})
export class ReportService extends ServiceBase {
    constructor(htt: HttpClient) {
        super(htt);
    }


    getExcel(filter?: UsersResellerInput): Observable<any> {
        const url = `${this.BASE_URL}report/user-reseller/excel`;
        return this.http.post(url, filter);
    }

    getResellerTeamExcel(filter?: UsersResellerTeamInput): Observable<any> {
        const url = `${this.BASE_URL}report/user-reseller-team/excel`;
        return this.http.post(url, filter);
    }

    getSaleTeamExcel(filter?: UserSalesTeamInput): Observable<any> {
        const url = `${this.BASE_URL}report/user-sales-team/excel`;
        return this.http.post(url, filter);
    }

    getUserExcludedExcel(filter?: UserSalesTeamInput): Observable<any> {
        const url = `${this.BASE_URL}report/user-excluded/excel`;
        return this.http.post(url, filter);
    }

    getUserAccessDashboardExcel(): Observable<any> {
        const url = `${this.BASE_URL}dashboard/user-access/excel `;
        return this.http.post(url,null);
    }

    getUserAdminExcel(filter: SearchUserAdminInput): Observable<any> {
        const url = `${this.BASE_URL}user-admin/excel `;
        return this.http.post(url,filter);
    }

    getTechincalVisistExcel(filter?: TechnicalVisitFilterAdminInput): Observable<any> {
        const url = `${this.BASE_URL}technical/excel`;
        return this.http.post(url, filter);
    }

    getMechanicsExtraExcel(filter?: TechnicalVisitFilterAdminInput): Observable<any> {
        const url = `${this.BASE_URL}report/mechanics-extra/excel`;
        return this.http.post(url, filter);
    }
    
    reportVideoExcel(filter?: TechnicalVisitFilterAdminInput): Observable<any> {
        const url = `${this.BASE_URL}report/video/excel`;
        return this.http.post(url, filter);
    }

    reportQuizExcel(filter?: TechnicalVisitFilterAdminInput): Observable<any> {
        const url = `${this.BASE_URL}report/quizzes/excel`;
        return this.http.post(url, filter);
    }

    reportCouponExcel(filter?: TechnicalVisitFilterAdminInput): Observable<any> {
        const url = `${this.BASE_URL}report/coupon/excel`;
        return this.http.post(url, filter);
    }

    
    

}
