import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { BaseComponent } from "src/app/base/base.component";
import { UserService } from "src/app/service/user.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent extends BaseComponent implements OnInit {
  @Input() password: string = "";
  @Input() email: string = "";

  constructor(
    public router: Router,
    public loading: NgxSpinnerService,
    public translate: TranslateService,
    public activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
    super(router, translate);
  }

  ngOnInit(): void {}

  handlerSigIn(): void {
    const arrValidateFields = [
      { value: this.email, text: "Email <br>" },
      { value: this.password, text: "Senha" },
    ];

    const strError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(strError)) {
      this.showMessage(
        "Atenção",
        `Preencha os campos corretamente: <br><br> ${strError}`,
        "warning"
      );
      return;
    }

    if(!super.isEMailValid(this.email)){
      this.showMessage('Atenção','Email inválido','warning');
      return;
    }

    this.userService.getAuthenticate({
      email: this.email,
      password: this.password
    }).subscribe({
      next: () => {
        this.email = "";
        this.password = "";
        this.router.navigate(['/']);
      }
    });
  }
}
