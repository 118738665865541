import {Component, OnInit} from "@angular/core";
import {BaseComponent} from "../../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {DashboardService} from "../../../service/dashboard.service";
import {UserService} from "../../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {DownloadResult} from "../model/download-result";
import {
    CategoryDownload, CreateDownloadGQL, CreateDownloadInput,
    Download,
    GetCategoryDownloadGQL,
    GetCategoryDownloadQuery,
    GetCategoryDownloadQueryVariables,
    GetDownloadByIdGQL,
    GetDownloadByIdQuery,
    GetDownloadByIdQueryVariables,
    GetProfilesGQL,
    GetProfilesQuery,
    GetProfilesQueryVariables,
    Profile, RemoveDownloadGQL,
    UpdateDownloadGQL, UpdateDownloadInput, UpdateNewsInput,
} from "src/generated/graphql";
import {QueryRef} from "apollo-angular";
import {DownloadCreate} from "../model/download-create";
import {DownloadService} from "src/app/service/download.service";
import {environment} from "../../../../environments/environment";

@Component({
    selector: "app-downloads-detail",
    templateUrl: "./downloads-detail.component.html",
    styleUrls: ["./downloads-detail.component.scss"],
})
export class DownloadsDetailComponent extends BaseComponent implements OnInit {
    file: File = null;
    thumpPath: string | ArrayBuffer;
    croppedImage: any = null;
    agendar = false;
    id: string = "";

    categoryDownoadQuery: QueryRef<GetCategoryDownloadQuery,
        GetCategoryDownloadQueryVariables>;
    categoryDownload: Array<CategoryDownload> = [];

    profileQuery: QueryRef<GetProfilesQuery, GetProfilesQueryVariables>;
    profiles: Array<any> = [];

    downloadQuery: QueryRef<GetDownloadByIdQuery, GetDownloadByIdQueryVariables>;

    modelDownload: Download = new Download();

    constructor(
        public router: Router,
        public dashboardService: DashboardService,
        public userService: UserService,
        public translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private getCategoryDownloadGQL: GetCategoryDownloadGQL,
        private getProfilesGQL: GetProfilesGQL,
        private getDownloadByIdGQL: GetDownloadByIdGQL,
        private uploadDownloadGQL: UpdateDownloadGQL,
        private downloadService: DownloadService,
        private removeDownloadGQL: RemoveDownloadGQL,
        private createDownloadGQL: CreateDownloadGQL
    ) {
        super(router, translate);
        this.modelDownload.category = new CategoryDownload();
        this.modelDownload.category.id = undefined;
        this.modelDownload.path = null;
    }

    ngOnInit(): void {
        this.getCategoryDownload();
        this.activatedRoute.params.subscribe((param) => {
            this.id = param.id;
            if (this.id !== 'new') {
                this.getDownloadById(this.id);
            } else {
                this.getProfiles();
            }
        });
    }

    setCroppedImage(o: any) {
        this.croppedImage = o.thumb;
    }

    getDownloadById(downloadId: string): void {
        this.downloadQuery = this.getDownloadByIdGQL.watch({input: downloadId});

        this.downloadQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.modelDownload = data.download as Download;
                const listProfile = this.listProfile(this.modelDownload.profile);
                this.getProfiles(listProfile);
            },
        });
    }

    getCategoryDownload(): void {
        this.categoryDownoadQuery = this.getCategoryDownloadGQL.watch();
        this.categoryDownoadQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.categoryDownload = data.categoryDownload as Array<CategoryDownload>;

            },
        });
    }

    getProfiles(profile?: string[]): void {
        this.profiles = [];
        this.profileQuery = this.getProfilesGQL.watch();
        this.profileQuery.valueChanges.subscribe(({data}) => {
            const profiles = data.profiles as Array<Profile>;
            profiles.map((item) => {
                const c = !profile ? false : profile.filter((i) => i == item.id).length > 0;
                this.profiles.push({
                    id: item.id,
                    name: item.name,
                    checked: this.isNullOrUndefined(this.modelDownload.id) && item.id === 'MA' ? true : c,
                });
            });
            console.log(this.profiles);
        });
    }

    listProfile(profile: string): string[] {
        return profile.split('|');
    }

    getImageUrl(image: string): string {
        return `${environment.base_url_image}download/archive/${image}`;
    }

    handleFileInput(files: FileList) {
        const extension = files.item(0).name.split('.')[1].toLocaleUpperCase();
        this.file = files.item(0);
        this.thumpPath = files.item(0).name;
        if (extension !== 'PDF' && extension !== 'MP4') {
            this.showMessage('Atenção', 'Arquivo inválido, adicione arquivo pdf ou mp4.', 'warning');
            this.file = null;
            this.thumpPath = null;
        }
    }

    handlerSave(): void {
        const arrValidateFields = [
            {value: this.modelDownload.name, text: 'Titulo <br>'},
            {value: this.modelDownload.category?.id, text: 'Categoria <br>'},
        ];

        if (!this.downloadChecked()) {
            arrValidateFields.push({value: '', text: 'Perfil <br>'});
        }
        const strError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(strError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${strError}`, 'warning');
            return;
        }

        let profileId = '';
        this.profiles
            .filter((f) => f.checked)
            .map((i) => {
                profileId += `${i.id}|`;
            });
        profileId = profileId.slice(0, -1);

        let paramExtension = '';
        if (this.file != null) {
            paramExtension = this.file.name.split('.')[1];
        }


        if (this.id !== 'new') {
            const a: UpdateDownloadInput = {
                id: this.id,
                categoryId: this.modelDownload.category?.id,
                name: this.modelDownload.name,
                path: this.modelDownload.path,
                profile: profileId,
                extension: paramExtension,
            };
            this.actionUpdateData(a);
        } else {

            const a: CreateDownloadInput = {
                categoryId: this.modelDownload.category?.id,
                name: this.modelDownload.name,
                path: '',
                profile: profileId,
                extension: paramExtension,
            };
            this.actionCreateData(a);
        }

        /*this.toggleLoader(true);
        this.uploadDownloadGQL
            .mutate({
                input: {
                    id: this.id,
                    categoryId: this.modelDownload.category?.id,
                    name: this.modelDownload.name,
                    path: this.modelDownload.path,
                    profile: profileId,
                    extension: paramExtension,
                },
            })
            .subscribe({
                next: ({data}) => {
                    if (this.file) {
                        const downloadId = data.updateDownload.id;
                        this.downloadService.upload(this.file, downloadId).subscribe({
                            next: () => {
                                this.message();
                                this.toggleLoader(false);
                            },
                            error: (err) => {
                                const message = JSON.parse(err).message;
                                super.showMessage('Atenção', message, 'error');
                                this.toggleLoader(false);
                            },
                        });
                    } else {
                        this.message();
                    }
                },
                error: (err) => {
                    this.toggleLoader(false);
                },
            });*/
    }

    actionCreateData(a: any) {
        this.createDownloadGQL
            .mutate({
                input: a,
            })
            .subscribe({
                next: ({data}) => {
                    if (this.file) {
                        const downloadId = data.createDownload.id;
                        this.downloadService.upload(this.file, downloadId).subscribe({
                            next: () => {
                                this.message();
                                setTimeout(() => {
                                    this.router.navigate(['/downloads']);
                                }, 100);
                            },
                            error: (err) => {
                                const message = JSON.parse(err).message;
                                super.showMessage('Atenção', message, 'error');
                                this.toggleLoader(false);
                                this.removeDownloadGQL
                                    .mutate({
                                        id: downloadId,
                                    })
                                    .subscribe({
                                        next: () => {
                                        },
                                    });
                                setTimeout(() => {
                                    this.router.navigate(['/downloads']);
                                }, 100);
                            },
                        });
                    } else {
                        this.message();
                        setTimeout(() => {
                            this.router.navigate(['/downloads']);
                        }, 100);
                    }
                },
                error: (err) => {
                    this.toggleLoader(false);
                },
            });
    }

    actionUpdateData(a: any) {
        this.uploadDownloadGQL
            .mutate({
                input: a,
            })
            .subscribe({
                next: ({data}) => {
                    if (this.file) {
                        const downloadId = data.updateDownload.id;
                        this.downloadService.upload(this.file, downloadId).subscribe({
                            next: () => {
                                this.message();
                                this.toggleLoader(false);
                                this.router.navigate(['/downloads']);
                            },
                            error: (err) => {
                                const message = JSON.parse(err).message;
                                super.showMessage('Atenção', message, 'error');
                                this.toggleLoader(false);
                                this.router.navigate(['/downloads']);
                            },
                        });
                    } else {
                        this.message();
                        this.router.navigate(['/downloads']);
                    }
                },
                error: (err) => {
                    this.toggleLoader(false);
                },
            });
    }

    downloadChecked(): boolean {
        return this.profiles.find((i) => i.checked);
    }

    message(): void {
        this.showMessage('Sucesso', 'Download atualizado com sucesso!', 'success');
    }
}
