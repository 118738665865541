import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {SearchContactInput} from "src/generated/graphql";
import {ServiceBase} from "../base/service.base";

@Injectable({
    providedIn: 'root'
})
export class ContactService extends ServiceBase {
    constructor(htt: HttpClient) {
        super(htt);
    }


    getExcel(filter?: SearchContactInput): Observable<any> {
        const url = `${this.BASE_URL}contact/excel`;
        return this.http.post(url, filter);
    }

}
